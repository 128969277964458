import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import Notifications from '../Notifications';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import LoadingPage from '../LoadingPage';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import UserService from '../../services/UserService';

// images
import blueX from '../../images/blue-x.svg';
import plus from '../../images/plus.svg';

function UserDealersPage(props) {

    const navigate = useNavigate();
    const { id } = useParams();
    const [successful, setSuccessful] = useState('');

    const [selectedUser, setSelectedUser] = useState({});
    const [assignedDealers, setAssignedDealers] = useState([]);
    const [allDealers, setAllDealers] = useState([]);

    const [removedDealer, setRemovedDealer] = useState({});
    const [removeConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState(false)

    let availableDealers = allDealers.filter(d => {
        return !assignedDealers.some(ad => ad['id'] === d['id'])
    })

    
    // DEALERS NOT RETURNED FROM GET ALL USERS API CALL, SHOULD HAVE EUGENE ADD THIS SO DONT NEED TO CALL EVERY TIME
    useEffect(() => {
        // on refresh page, users global state needs to be re-populated
        UserService.getUser(id).then(response => {
            setSuccessful(true)
            setSelectedUser(response)
            setAssignedDealers(response.dealers)
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })

        DealerService.getAllDealers()
            .then(response => {
                setSuccessful(true)

                setAllDealers(response.data)
            },
            error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            });

    }, [id, navigate])

    const handleAddLink = (dealer) => {
        const userPayload = {
            userId: selectedUser.id,
            dealerIds: [dealer.id]
        }
        UserService.linkDealerToUser(userPayload)
            .then(response => {
                setAssignedDealers(prev => ([
                    ...prev,
                    dealer
                ]))
                Notifications("success", "Dealer has been added.")
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
    };

    const handleAddAll = () => {
        const dealerIds = assignedDealers.map(dealer => dealer.id)

        const restDealers = availableDealers.map(dealer => dealer.id)

        const userPayload = {
            userId: selectedUser.id,
            dealerIds: [
                ...dealerIds,
                ...restDealers
            ]
        }

        UserService.linkDealerToUser(userPayload)
            .then(response => {
                setAssignedDealers(allDealers)
                Notifications('success', 'All dealers have been added.')
            }).catch(e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            })
    };

    const toggleRemoveConfirmationModal = () => {
        setRemoveConfirmationModalOpen(current => {
            return !current
        })
    };

    const handleDeleteConfirmation = (removedDealer) => {
        setRemovedDealer(removedDealer)
        setRemoveConfirmationModalOpen(true)
    };

    const onSubmitRemoveConfirmationModal = (message) => {

        if (message === 'yes') {
            const newDealers = assignedDealers.filter(dealer => removedDealer !== dealer)

            const userPayload = {
                userId: selectedUser.id,
                dealerIds: [removedDealer.id]
            }

            UserService.unlinkDealerFromUser(userPayload)
                .then(response => {
                    setAssignedDealers(newDealers)
                    toggleRemoveConfirmationModal()
                    Notifications("success", "Dealer has been removed.")
                }).catch(err => {
                    if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", err['message'])
                })
        } else {
            toggleRemoveConfirmationModal()
        }
    };

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className="details-page__form" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <h2>Appointment of Dealers</h2>
                        <button
                            disabled={availableDealers.length === 0}
                            onClick={() => handleAddAll()}
                            className="form-button add-all__button"
                        >
                            Add All
                        </button>
                    </div>
                   
                    <div className="assigned-dealers__div">
                        {
                            assignedDealers.sort((a, b) => a['name'].localeCompare(b['name'])).map(dealer => {
                                return (
                                    <button
                                        onClick={() => handleDeleteConfirmation(dealer)}
                                        className="form-button assigned-dealers__button"
                                        key={dealer.id}
                                    >   
                                        <span>
                                            {dealer.name}
                                        </span>
                                        <div>
                                            <img src={blueX} alt="Remove"/>
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div>
                    <hr/>
                    <div className="available-dealers__div">
                        {
                            availableDealers.sort((a, b) => a['name'].localeCompare(b['name'])).map(dealer => {
                                return (
                                    <button
                                        key={dealer.id}
                                        className="form-button available-dealers__button"
                                        onClick={() => handleAddLink(dealer)}
                                    >
                                        <div>
                                            <img src={plus} alt="Add"/>
                                        </div>
                                        <span>
                                            {dealer.name}
                                        </span>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={removeConfirmationModalOpen}
                toggleModal={toggleRemoveConfirmationModal}
                onSubmitDeleteConfirmationModal={onSubmitRemoveConfirmationModal}
                message={"Confirm remove dealer?"}
            />
        </React.Fragment>
    );
};

export default UserDealersPage;