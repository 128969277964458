import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SuperAdminRoute from './SuperAdminRoute';
import AdminRoute from './AdminRoute';
import NonDealershipRoute from './NonDealershipRoute';
// services


// Components
import LoginPage from '../components/Login/LoginPage';
import ForgotPasswordPage from '../components/Login/ForgotPasswordPage';
import ResetPasswordPage from '../components/Login/ResetPasswordPage';
// import DashboardPage from '../components/Dashboard/DashboardPage';
import DealersPage from '../components/Dealers/DealersPage';
import DealerInfoPage from '../components/Dealers/DealerInfoPage';
import AddDealerPage from '../components/Dealers/AddDealerPage';
import UsersPage from '../components/Users/UsersPage';
import UserInfoPage from '../components/Users/UserInfoPage';
import AddUserPage from '../components/Users/AddUserPage';
import ContractsPage from '../components/Contracts/ContractsPage';
import AddContractPage from '../components/Contracts/AddContractPage';
import ContractInfoPage from '../components/Contracts/ContractInfoPage';
import ProductsPage from '../components/Products/ProductsPage';
import ProductInfoPage from '../components/Products/ProductInfoPage';
import AddProductPage from '../components/Products/AddProductPage';
import SettingsPage from '../components/Settings/SettingsPage';


function AppRouter() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME ? process.env.REACT_APP_BASE_NAME : '/'}>
            <Routes>
                <Route 
                    path='/login' 
                    element={
                        <PublicRoute>
                            <LoginPage />
                        </PublicRoute>
                        
                    } 
                />
                <Route 
                    path='/forgot' 
                    element={
                        <PublicRoute>
                            <ForgotPasswordPage />
                        </PublicRoute>
                        
                    } 
                />
                <Route 
                    path='/resetpassword' 
                    element={
                        <PublicRoute logout={true}>
                            <ResetPasswordPage />
                        </PublicRoute>
                    } 
                />
                <Route
                    path='/'
                    element={
                        <Navigate to='/contracts' replace/>
                    }
                />
                <Route
                    path='*'
                    element={
                        <Navigate to='contracts' />
                    }
                />
                <Route 
                    path='/dealers' 
                    element={
                        <PrivateRoute>
                            <DealersPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id?tab=info' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id?tab=products' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id?tab=pricing' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id?tab=users' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/dealers/:id?tab=history' 
                    element={
                        <PrivateRoute>
                            <DealerInfoPage />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/adddealer' 
                    element={
                        <PrivateRoute>
                            <AdminRoute>
                                <AddDealerPage />
                            </AdminRoute> 
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/users' 
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <UsersPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/users/:id'
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <UserInfoPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/users/:id?tab=info'
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <UserInfoPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/users/:id?tab=dealers'
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <UserInfoPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/users/:id?tab=history'
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <UserInfoPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/adduser' 
                    element={
                        <PrivateRoute>
                            <NonDealershipRoute>
                                <AddUserPage />
                            </NonDealershipRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/contracts' 
                    element={
                        <PrivateRoute>
                            <ContractsPage />
                        </PrivateRoute>
                    } 
                />
                <Route
                    path='/contracts/approveall'
                    element={
                        <PrivateRoute>
                            <ContractsPage />
                        </PrivateRoute>
                    }
                />
                <Route 
                    path='/addcontract' 
                    element={
                        <PrivateRoute>
                            <AddContractPage />
                        </PrivateRoute>
                    } 
                />
                <Route
                    path='/contracts/:id'
                    element={
                        <PrivateRoute>
                            <ContractInfoPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/contracts/approve'
                    element={
                        <PrivateRoute>
                            <ContractsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/contracts/cancel'
                    element={
                        <PrivateRoute>
                            <ContractsPage />
                        </PrivateRoute>
                    }
                />
                <Route 
                    path='/products' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <ProductsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/products/:id' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <ProductInfoPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/products/:id?tab=info' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <ProductInfoPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/products/:id?tab=history' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <ProductInfoPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/addproduct' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <AddProductPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings?tab=dealer-groups' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings?tab=territories' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings?tab=product-types' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings?tab=additional-items' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path='/settings?tab=contract-templates' 
                    element={
                        <PrivateRoute>
                            <SuperAdminRoute>
                                <SettingsPage />
                            </SuperAdminRoute>
                        </PrivateRoute>
                    } 
                />

            </Routes>
        </BrowserRouter>  
    );
};

export default AppRouter;