import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Sidebar
import {
    Sidebar,
    Menu,
    MenuItem,
    useProSidebar
} from 'react-pro-sidebar';

// logos
import wmsLogo from '../images/wms-logo.svg';
// import dashboardLogo from '../images/dashboard.svg';
import dealersLogo from '../images/dealers.svg';
import usersLogo from '../images/users.svg';
import contractsLogo from '../images/contracts.svg';
import productsLogo from '../images/products.svg';
import settingsLogo from '../images/settings.svg';

// css
const menuItemStyles = {
    button: ({ active }) => {
        return {
            '&:hover': {
                backgroundColor: '#6B9FED'
            }
        }
    }
}

function SideMenu(props) {
    const { collapseSidebar, collapsed } = useProSidebar()

    const pageTitleMapping = {
        'dashboard': 'Dashboard',
        'dealers': 'Dealers',
        'adddealer': 'Dealers',
        'users': 'Users',
        'adduser': 'Users',
        'contracts': 'Contracts',
        'addcontract': 'Contracts',
        'products': 'Products',
        'addproduct': 'Products',
    }

    const currentPage = pageTitleMapping[useLocation().pathname.split('/')[1]]

    return (
        <React.Fragment>
            <div className='sidebar'>
                <Sidebar
                    backgroundColor='#1D3150'
                    rootStyles={{
                        color: '#ffffff',
                        height: '100%',
                    }}
                    defaultCollapsed={true}
                    collapsedWidth='8rem'
                    onMouseEnter={() => {
                        collapseSidebar()
                    }}
                    onMouseLeave={() => !collapsed && collapseSidebar()}
                >
                    <div className="sidebar-menu">
                        <Menu menuItemStyles={menuItemStyles}>
                            <MenuItem 
                                component={<Link to='/dealers' />} 
                                icon={<img src={wmsLogo} alt="Logo" />}
                                onClick={() => !collapsed && collapseSidebar()}
                            >
                                WARP
                            </MenuItem>
                        </Menu>
                        <Menu menuItemStyles={menuItemStyles}>
                            <MenuItem 
                                active={currentPage === 'Dealers'}
                                component={<Link to='/dealers' />}
                                icon={<img src={dealersLogo} alt="Dealers"/>}
                                onClick={() => !collapsed && collapseSidebar()}
                            >
                                Dealers
                            </MenuItem>
                            {
                                (props.role !== 'DEALERSHIP_FI' && props.role !== 'DEALERSHIP_ACCOUNTING') &&
                                    <MenuItem 
                                        active={currentPage === 'Users'}
                                        component={<Link to='/users' />}
                                        icon={<img src={usersLogo} alt="User"/>}
                                        onClick={() => !collapsed && collapseSidebar()}
                                    >
                                        Users
                                    </MenuItem>
                            }
                            {
                                props.role === 'SUPERADMIN' &&
                                    <MenuItem 
                                        active={currentPage === 'Products'}
                                        component={<Link to='/products' />}
                                        icon={<img src={productsLogo} alt="Products"/>}
                                        onClick={() => !collapsed && collapseSidebar()}
                                    >
                                        Products
                                    </MenuItem>
                            }
                             <MenuItem 
                                active={currentPage === 'Contracts'}
                                component={<Link to='/contracts' />}
                                icon={<img src={contractsLogo} alt="Contracts"/>}
                                onClick={() => !collapsed && collapseSidebar()}
                            >
                                Contracts
                            </MenuItem>
                        </Menu>
                        <Menu menuItemStyles={menuItemStyles}>
                            {
                                props.role === 'SUPERADMIN' &&
                                    <MenuItem 
                                        active={currentPage === 'Settings'}
                                        component={<Link to='/settings?tab=dealer-groups' />}
                                        icon={<img src={settingsLogo} alt="Settings"/>}
                                        onClick={() => !collapsed && collapseSidebar()}
                                    >
                                        Settings
                                    </MenuItem>
                            }
                        </Menu>
                    </div>
                </Sidebar>
            </div>
        </React.Fragment>
    );
};

export default SideMenu;