
export const set_product_name = (name = '') => ({
    type: 'SET_PRODUCT_NAME',
    name
});
export const set_product_contract_letter = (contractLetter = '') => ({
    type: 'SET_PRODUCT_CONTRACT_LETTER',
    contractLetter
});

export const set_product_page = (page = 1) => ({
    type: 'SET_PRODUCT_PAGE',
    page
});