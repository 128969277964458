import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// components
import ProductsTable from './ProductsTable';
import ProductsPagination from './ProductsPagination';
import ProductFiltersModal from './ProductFiltersModal';
// images
import add from '../../images/add.svg';
import save from '../../images/save-dark.svg';
// actions
import { set_header } from '../../actions/header';
import { set_product_name, set_product_contract_letter } from '../../actions/productsFilters';


function ProductsPage({ set_header, set_product_name, set_product_contract_letter, productsFilters }) {
    const navigate = useNavigate();
    const [initialRender, setInitialRender] = useState(true)
    const [csvData, setCSVData] = useState([]);
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalProducts: 0
    })
    const [filters, setFilters] = useState({
        nameFilter: productsFilters.name,
        contractLetterFilter: productsFilters.contractLetter
	})
    const [filtersModalOpen, setFiltersModalOpen] = useState(false)
    const [applyResetTable, setApplyResetTable] = useState(false)
    const [currentPage, setCurrentPage] = useState(productsFilters.page);


    useEffect(() => {
        set_header('Products', null)
        setInitialRender(false)
    }, [navigate, set_header]);

    useEffect(() => {
        if (!initialRender) {
            setFilters({
                nameFilter: '',
                contractLetterFilter: ''
            })
            set_product_name('')
            set_product_contract_letter('')
        }
    }, [applyResetTable])

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true)
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false)
    };   

    const handleSubmitFilters = (filters)  => {
        setFilters({
            nameFilter: filters.nameFilter,
            contractLetterFilter: filters.contractLetterFilter
        });
        set_product_name(filters.nameFilter)
        set_product_contract_letter(filters.contractLetterFilter)
        setFiltersModalOpen(false)
    };


    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const handleCSVData = (data) => {

        setCSVData(data.map(product => ({
            id: product.id,
            product: product.name,
            contract: product.contractLetter,
            used: product.condition.toString(),
            usedCriteria: product.year,
            productType: product.productType ? product.productType.name : '',
            terms: product.terms.toString(),
            mileage: product.mileage,
            createdAt: product.createdAt,
            updatedAt: product.updatedAt,
            removed: product.removed ? 'true' : 'false',
            active: product.active ? 'true' : 'false'
        })))
        
    };

    

    return (
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='filters'>
                        <div className='contracts-table__header'>
                            <div>
                                <button
                                    onClick={() => handleFiltersModalOpen()}
                                    className='header-reset'
                                >
                                    Filters
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setApplyResetTable(prev => !prev)}
                                    className='filter-buttons active'
                                >
                                    Reset Filters
                                </button>
                            </div>
                            <div className="contract-table__header-group">
                                <div className='header-filters'>
                                    {
                                        filters.nameFilter && filters.nameFilter !== '' &&
                                            <div className="vertical-align-div">
                                                Name: {filters.nameFilter}
                                            </div>
                                    }
                                    {
                                        filters.contractLetterFilter && filters.contractLetterFilter !== '' &&
                                            <div className='vertical-align-div'>
                                                Contract Letter: {filters.contractLetterFilter}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='filter-actions'>
                            <CSVLink 
                                data={csvData}
                                className='link__form-add'
                                filename={"products.csv"}
                            >
                                <span className="hide-for-mobile">
                                    Download
                                </span>
                                <img src={save} alt="Save"/>
                            </CSVLink>
                            <Link 
                                to='/addproduct'
                                className='link__form-add'
                            > 
                                <span className="hide-for-mobile">
                                    Add
                                </span>
                                <img src={add} alt="Add"/>
                            </Link>
                        </div>
                    </div>
                </div>

                <ProductsTable
                    currentPage={currentPage}
                    handlePageData={setPageData}
                    handleCSVData={handleCSVData}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />
   
                <ProductsPagination
                    totalPages={pageData.totalPages}
                    handlePageChange={handlePageChange}
                    loading={pageData.loading}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />

            </div>

            <ProductFiltersModal
                filtersModalOpen={filtersModalOpen}
                handleFiltersModalClose={handleFiltersModalClose}
                handleSubmitFilters={handleSubmitFilters}
                applyResetTable={applyResetTable}
                filters={filters}
            />

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        productsFilters: state.productsFilters
   };
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub)),
        set_product_name: (name) => dispatch(set_product_name(name)),
        set_product_contract_letter: (contractLetter) => dispatch(set_product_contract_letter(contractLetter)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
