import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// images
import save from '../../images/save-dark.svg';
import add from '../../images/add.svg';
// actions
import { set_header } from '../../actions/header';
import { set_user_role, set_user_email } from '../../actions/usersFilters';
// components
import UsersTable from './UsersTable';
import UsersPagination from './UsersPagination';
import UserFiltersModal from './UserFiltersModal';


function UsersPage({ set_header, set_user_role, set_user_email, usersFilters }) {

	const navigate = useNavigate();

    const [csvData, setCSVData] = useState([]);
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalUsers: 0
    })
    const [initialRender, setInitialRender] = useState(true)
    const [currentPage, setCurrentPage] = useState(usersFilters.page);
    const [filters, setFilters] = useState({
        roleTypeFilter: usersFilters.role,
        emailFilter: usersFilters.email
    })
    const [filtersModalOpen, setFiltersModalOpen] = useState(false)
    const [applyResetTable, setApplyResetTable] = useState(false)


    useEffect(() => {
        set_header('Users', null)
        setInitialRender(false)
    }, [navigate, set_header]);

    useEffect(() => {
        if (!initialRender) {
            setFilters({
                roleTypeFilter: null,
                emailFilter: '',
            })
            set_user_role(null)
            set_user_email('')
        }
    }, [applyResetTable])  


    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const handleRoleTypeChange = (filter) => {
        setFilters(prev => ({
            ...prev,
            roleTypeFilter: filter
        }))
        set_user_role(filter)
    };    


    const handleCSVData = (data) => {
        setCSVData(data.map(user => ({
            id: user.id,
            title: user.title,
            name: user.name + ' ' + user.surname,
            role: user.role,
            phone: user.phone,
            ext: user.ext,
            active: user.active ? 'true' : 'false',
            email: user.email,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            territory: user.territory,
            state: user.state,
            zip: user.zip,
            city: user.city,
            address: user.address,
            fax: user.fax,
            removed: user.removed ? 'true' : 'false'
        })))
    }

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true)
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false)
    };    

    const handleSubmitFilters = (filters) => {
        setFilters({
            roleTypeFilter: filters.roleTypeFilter,
            emailFilter: filters.emailFilter
        });
        set_user_role(filters.roleTypeFilter)
        set_user_email(filters.emailFilter)
        setFiltersModalOpen(false)
    };


    
    
    return (
        <>
            <div className="content">
                <div className="content-row">
                    <div className='filters'>
                        <div className='contracts-table__header'>
                            <div>
                                <button
                                    onClick={() => handleFiltersModalOpen()}
                                    className='header-reset'
                                >
                                    Filters
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setApplyResetTable(prev => !prev)}
                                    className='filter-buttons active'
                                >
                                    Reset Filters
                                </button>
                            </div>
                            <div className="contract-table__header-group">
                                <div className='header-filters'>
                                    {
                                        filters.roleTypeFilter &&
                                            <div className="vertical-align-div">
                                                Role: {filters.roleTypeFilter.label}
                                            </div>
                                    }
                                    {
                                        filters.emailFilter && filters.emailFilter !== '' &&
                                            <div className='vertical-align-div'>
                                                Email: {filters.emailFilter}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='filter-actions'>
                            <CSVLink 
                                data={csvData}
                                className='link__form-add'
                                filename={"users.csv"}
                            >
                                <span className="hide-for-mobile">
                                    Download
                                </span>
                                <img src={save} alt="Save"/>
                            </CSVLink>
                            <Link 
                                to='/adduser'
                                className='link__form-add'
                            >
                                <span className="hide-for-mobile">
                                    Add
                                </span>
                                <img src={add} alt="Add"/>
                            </Link>
                        </div>
                    </div>
                </div>


                <UsersTable
                    currentPage={currentPage}
                    handlePageData={setPageData}
                    handleCSVData={handleCSVData}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />
                <UsersPagination
                    totalPages={pageData.totalPages}
                    handlePageChange={handlePageChange}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />

            </div>

            <UserFiltersModal
                filtersModalOpen={filtersModalOpen}
                handleFiltersModalClose={handleFiltersModalClose}
                handleSubmitFilters={handleSubmitFilters}
                applyResetTable={applyResetTable}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        usersFilters: state.usersFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub)),
        set_user_role: (role) => dispatch(set_user_role(role)),
        set_user_email: (email) => dispatch(set_user_email(email))
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
