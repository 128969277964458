import React from 'react';
import { useNavigate } from 'react-router-dom';

// components
import ResetPasswordForm from './ResetPasswordForm';

// assets
import wmsTitle from '../../images/wms-title.svg';

function ResetPasswordPage(props) {
    const navigate = useNavigate()

    const onSubmit = () => {
        navigate('/login', { state: 'password' });
    };

    return (
        <React.Fragment>
            <div className='content-container'>
                <div className='login-content'>
                    <div className='login__title'>
                        <img src={wmsTitle} width='200rem' alt="Logo"/>
                    </div>
                    <h1>Create New Password</h1>
                    <p className='login__subtitle'>Please create your new password.</p>
                    <ResetPasswordForm onSubmit={onSubmit}/>
                </div>
                <div className="login-background hide-for-mobile">
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default ResetPasswordPage;