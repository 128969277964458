import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    useTable, 
    usePagination, 
    useFilters,
    useExpanded,
    useRowSelect,
    useSortBy 
} from 'react-table';
import Numeral from 'numeral';

// table filters
import { Filter } from '../TableFilters';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import PricingService from '../../services/PricingService';
import UserService from '../../services/UserService';

// images
import checkboxEmpty from '../../images/checkbox-empty.svg';
import checkboxFull from '../../images/checkbox-full.svg';
import add from '../../images/add.svg';
import edit from '../../images/edit.svg';
import gear from '../../images/gear.svg';
import search from '../../images/search.svg';
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';

// components
import AddPricingPositionModal from './AddPricingPositionModal';
import EditPricingPositionModal from './EditPricingPositionModal';
import LoadingPage from '../LoadingPage';
import Notifications from '../Notifications';

function DealerPricingPage(props) {

	const navigate = useNavigate();

    const defaultRowHeight = 50;
    const { id } = useParams();

    const tableRef = useRef(null)

    const [successful, setSuccessful] = useState('');
    const [calcPageSize, setCalcPageSize] = useState(10);
    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [editMenuOpen, setEditMenuOpen] = useState(false);

    const [selectedDealer, setSelectedDealer] = useState({});
    const [assignedProducts, setAssignedProducts] = useState([]);
    const [selectedPricingPosition, setSelectedPricingPosition] = useState({});
    const [selectedPricingPositionItems, setSelectedPricingPositionItems] = useState([]);
    const [data, setData] = useState([])
    const [allSelected, setAllSelected] = useState(false);

    const userRole = UserService.getUserRole();

    useEffect(() => {
        DealerService.getDealer(id).then(response => {
            setSuccessful(true)
            setSelectedDealer(response.data)
            setAssignedProducts(response.data.products)

            let prices = []
            response.data.products.forEach((product) => {
                return (
                    product.pricingPosition.forEach((price) => {
						if (price.dealerId === id) prices.push(price)
                    })
                )
            })

            setData(prices.map(pricing => (
                {
                    id: pricing.id,
                    selected: false,
                    title: pricing.title,
                    bvProductCode: pricing.bvcode,
                    configID: pricing.id,
                    contract: pricing.contractLetter,
                    valid: 'Yes',
                    price: pricing.price,
                    term: pricing.term,
                    edit: <button 
                            onClick={(e) => handleEditPricingPosition(e, pricing)}
                            className='button-no-background'
                        >
                            <img src={edit} alt="Edit"/>
                        </button>
                }
            )))

            if (tableRef.current) {
                setCalcPageSize(Math.floor(tableRef.current.clientHeight / defaultRowHeight) - 1)
            } else {
                setCalcPageSize(Math.floor((window.innerHeight * 0.8) / defaultRowHeight))
            }
        }, e => {
			if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
				AuthService.logout()
				navigate('/login')
			}
            setSuccessful(false)
            Notifications("error", e.message)
        })
    }, [id, navigate])


    const toggleAddMenu = () => {
        setAddMenuOpen(!addMenuOpen)
    };

    const toggleEditMenu = (newPricingPosition) => {
        if (editMenuOpen) {
            setData(prev => prev.map(pricing => {
                if (pricing.id === newPricingPosition.id) {
                    return {
                        ...pricing,
                        bvProductCode: newPricingPosition.bvcode,
                        price: newPricingPosition.price
                    }
                } else {
                    return pricing
                }
            }))
        }

        setEditMenuOpen(prev => !prev)
    };

    const handleEditPricingPosition = (e, pricing) => {
        e.stopPropagation()

        PricingService.getPricingPosition(pricing.id)
            .then(response => {
                setSelectedPricingPosition(response.data)
                setSelectedPricingPositionItems(response.data.additionalItems)
                setEditMenuOpen(true)
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e.message)
            })
    };

    const onAddSubmit = (pricingPosition) => {
        const pricingPayload = {
            productId: pricingPosition.product.id,
            dealerId: selectedDealer.id,
            title: pricingPosition.product.name,
            bvcode: pricingPosition.productCode,
            valid: true,
            removed: false,
            term: pricingPosition.term.value,
            price: pricingPosition.price,
            contractLetter: pricingPosition.product.contractLetter
        }
        
        PricingService.createPricingPosition(pricingPayload)
            .then(response => {
                const items = []
                pricingPosition.additionalItems.forEach((item) => {
                    items.push({
                        pricingPositionId: response.data.id,
                        additionalItemCodeId: item.additionalItemCodeId,
                        fee: item.fee,
                        removed: false
                    })
                })

                PricingService.createPricingPositionItem(items)
                    .then(r => {
                        let updatedPrice = response.data.price;

                        r.data.forEach(additionalItem => {
                            updatedPrice[1] += additionalItem['fee']
                            updatedPrice[2] += additionalItem['fee']
                        })

                        setData(prev => [
                            ...prev,
                            {
                                id: response.data.id,
                                selected: false,
                                title: response.data.title,
                                bvProductCode: response.data.bvcode,
                                configID: response.data.id,
                                contract: response.data.contractLetter,
                                valid: 'Yes',
                                price: updatedPrice,
                                term: response.data.term,
                                edit: <button 
                                        onClick={(e) => handleEditPricingPosition(e, response.data)}
                                        className='button-no-background'
                                    >
                                        <img src={edit} alt="Edit"/>
                                    </button>
                            }
                        ])

                        Notifications("success", "Pricing position has been created.")
                        setAddMenuOpen(false)
                    }, e => {
                        Notifications("error", e.message)
                    }
                )
            }, e => {
				if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
					AuthService.logout()
					navigate('/login')
				} else {
                    Notifications("error", e.message)
                }
            })
    };
    
    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'Select',
            //     accessor: 'selected',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     Cell: ({ row, cell }) => {
            //         const [value, setValue] = useState(cell.value)

            //         return (
            //             <div className="table-cell__div" onClick={() => onSelectRow(row)}>
            //                 {
            //                     value ?
            //                         <img src={checkboxFull}/>
            //                         :
            //                         <img src={checkboxEmpty}/>
            //                 }
            //             </div>
            //         );
            //     }
            // },
            {
                Header: 'Product Name',
                accessor: 'title',
                disableFilters: true,
                // Filter: InputColumnFilter,
                // ref: titleFilterRef,
                // filter: 'equals',
                // id: 'title',
                // name: 'title',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Accounting Item Code',
                accessor: 'bvProductCode',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Term',
                accessor: 'term',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let tempValue = value;

                    if (value === 'life') {
                        tempValue = 'Life'
                    } else {
                        tempValue = `${value} year${parseInt(value) > 1 ? 's' : ''}`
                    }
        
                    return (
                        <div className="table-cell__div">
                            {tempValue}
                        </div>
                    );
                }
            },
            {
                Header: (userRole !== 'SUPERADMIN' && userRole !== 'METROTECH_ADMIN') ? 'Total Price' : 'Internal Price / Additional Items Price / Total Price',
                accessor: 'price',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;


                    let price = value[0];
                    let override = value[1];
                    let total = value[2];

                    return (
                        <React.Fragment>
                            {
                                (userRole !== 'SUPERADMIN' && userRole !== 'METROTECH_ADMIN') ? (
                                    <div className='table-cell__div'>
                                        <div className="badge badge__terms">
                                            {Numeral(total).format("$0,0.00")}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='table-cell__div'>
                                        <div className="badge badge__terms">
                                            {Numeral(price).format("$0,0.00")}
                                        </div>
                                        <div className="badge badge__terms">
                                            {Numeral(override).format("$0,0.00")}
                                        </div>
                                        <div className="badge badge__terms">
                                            {Numeral(total).format("$0,0.00")}
                                        </div>
                                    </div>
                                )
                                
                            }
                        </React.Fragment>
                    
                    );

                }
            },
            {
                Header: <img src={gear} alt="Gear"/>,
                accessor: 'edit',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ],[userRole]
    )
    
    // const onSelectRow = (row) => {
    //     // setSkipPageReset(true)
    //     setData(prev => (
    //         prev.map(d => {
    //             return {
    //                 ...d,
    //                 selected: d['id'] === row.original.id ? !d['selected'] : d['selected']
    //             }
    //         })
    //     ))
    // }
    
    
    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            pageCount,
            gotoPage,
            state: { pageIndex },
        } = useTable(
            {
                columns,
                data,
                initialState: { 
                    defaultPageSize: 10,
                    pageSize: calcPageSize,
                    sortBy: [
                        {
                            id: 'title',
                            desc: false
                        }
                    ],
                    ...userRole!=='SUPERADMIN' && {hiddenColumns: ['edit']},
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
            },
            useFilters,
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect,
        )
        
        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} alt="Sort"/>;
            } else {
                return null
            }
        };
    
        const generateSearchIndicator = (column) => {
            if (column.canFilter) {
                return <img src={search} alt="Search"/>
            } else {
                return null
            }
        }
    
        const generateVerticalLine = (column) => {
            if (column.canFilter && column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        const onAllSelectRowClick = () => {
            setData(prev => (
                prev.map(d => {
                    return {
                        ...d,
                        selected: !allSelected
                    }
                })
            ))
            setAllSelected(prev => !prev)
        }

        // Render the UI for your table
        return (
            <React.Fragment>
                <div ref={tableRef} className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={`table-header ${column.canFilter && 'filterable'} ${(userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') && 'flex-end'}`} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {
                                                            column.id === 'selected' ?
                                                                <img src={allSelected ? checkboxFull : checkboxEmpty} onClick={onAllSelectRowClick} style={{ cursor: 'pointer' }} alt="Select"/>
                                                                :
                                                                (column.canFilter && !column.searchHidden) ? 
                                                                    <Filter column={column} hidden={column.searchHidden}/>
                                                                    :
                                                                    column.render('Header')
                                                        }
                                                        <div className="table-header__icons">
                                                            {generateSearchIndicator(column)}
                                                            {generateVerticalLine(column)}
                                                            {generateSortingIndicator(column)}
                                                        </div>
                                                    </div>
                                                    {
                                                        column.canFilter && <Filter column={column} />
                                                    }
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className={`${(userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') && "flex-end"}`}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            <div className="pagination">
                { 
                    <div className="pagination__table">
                        {
                            [...Array(pageCount).keys()].map(i => {
                                return (
                                    <div className="vertical-align-div" key={`products-page-index-${i}`}>
                                        <button 
                                            className={pageIndex === i ? 'pagination__page-button active' : 'pagination__page-button'}
                                            onClick={() => gotoPage(i)}
                                        >
                                            {i+1}
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
          </React.Fragment>
        )
      }

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    }
        

    return (
        <React.Fragment>
            <div className="content-row">
                <div className='filters'>
                    <span>
                        Pricing Position
                    </span>
                    {
                        userRole === 'SUPERADMIN' &&
                        <button 
                            className='link__form-add'
                            onClick={() => toggleAddMenu()}
                        >
                            <span className="hide-for-mobile">
                                Add
                            </span>
                            <div className='vertical-align-div'>
                                <img src={add} alt="Add"/>
                            </div>
                        </button>
                    }
                    
                </div>
            </div>
            <div className='table'>
                <Table columns={columns} data={data} />
            </div>
            {
                userRole === 'SUPERADMIN' &&
                    <AddPricingPositionModal
                        isOpen={addMenuOpen}
                        toggleMenu={toggleAddMenu}
                        assignedProducts={assignedProducts}
                        onAddSubmit={onAddSubmit}
                    />
            }
            {
                userRole === 'SUPERADMIN' &&
                    <EditPricingPositionModal
                        isOpen={editMenuOpen}
                        toggleMenu={toggleEditMenu}
                        pricingPosition={selectedPricingPosition}
                        items={selectedPricingPositionItems}
                    />
            }
        </React.Fragment>
    );
};

export default DealerPricingPage;