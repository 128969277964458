import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { set_header } from '../../actions/header';
import { set_contract_status, set_contract_vin, set_contract_dealer, set_contract_start_date, set_contract_end_date, set_contract_page } from '../../actions/contractsFilters';
// components
import ContractPagination from './ContractPagination';
import ContractsTable from './ContractsTable';
import ContractFiltersModal from './ContractFiltersModal';
// other
import moment from 'moment';
import { CSVLink } from 'react-csv';
// icons
import save from '../../images/save-dark.svg';
import add from '../../images/add.svg';

function ContractsPage({ 
    contractsFilters, set_header, set_contract_status, set_contract_vin, set_contract_dealer, set_contract_start_date, set_contract_end_date
}) {

    const [initialRender, setInitialRender] = useState(true)
    const navigate = useNavigate();
    const [csvData, setCSVData] = useState([]);
    const [applyResetTable, setApplyResetTable] = useState(false)
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalContracts: 0
    })
	const [filters, setFilters] = useState({
        contractTypeFilter: contractsFilters.status,
		vinSearchFilter: contractsFilters.vin,
		dealerSearchFilter: contractsFilters.dealer,
		startDateDay: moment(contractsFilters.startDate),
		endDateDay: moment(contractsFilters.endDate),
	})
    const [currentPage, setCurrentPage] = useState(contractsFilters.page);
    const [filtersModalOpen, setFiltersModalOpen] = useState(false);

    // set header
    useEffect(() => {
        set_header('Contracts', null)
        setInitialRender(false)
    }, [navigate, set_header]);

    useEffect(() => {
        if (!initialRender) {
            setFilters({
                contractTypeFilter: null,
                vinSearchFilter: '',
                dealerSearchFilter: null,
                startDateDay: moment().subtract(30, 'days'),
                endDateDay: moment()
            })
            set_contract_status(null)
            set_contract_vin('')
            set_contract_dealer(null)
            set_contract_start_date(moment().subtract(30, 'days').toISOString(true))
            set_contract_end_date(moment().toISOString(true))
        }
        
    }, [applyResetTable])    
 
    const handleContractTypeChange = (filter) => {
        setFilters(prev => ({
            ...prev,
            contractTypeFilter: filter
        }))
        set_contract_status(filter)
    };    

    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true)
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false)
    };

    const handleSubmitFilters = (filters) => {
        setFilters(prev => ({
            ...prev,
            vinSearchFilter: filters.vinSearchFilter,
            dealerSearchFilter: filters.dealerSearchFilter,
            startDateDay: moment(filters.startDateDay),
            endDateDay: moment(filters.endDateDay),
        }));
        set_contract_vin(filters.vinSearchFilter)
        set_contract_dealer(filters.dealerSearchFilter)
        set_contract_start_date(filters.startDateDay)
        set_contract_end_date(filters.endDateDay)
        setFiltersModalOpen(false)
    };

    const handlePageChange = (page) => {
        setCurrentPage(page)
    };


    
    const handleCSVData = (data) => {

        setCSVData(data.map(contract => {
            const ownerInfo = JSON.parse(contract.owner)
            return ({
                id: contract.id,
                dealer: contract.dealer.name,
                product: contract.pricingPositions[0]['title'],
                term: contract.pricingPositions[0]['term'],
                activationDate: moment(contract.activationDate).utc().format('YYYY-MM-DD'),
                vin: contract.vin,
                name: `${ownerInfo.firstName} ${ownerInfo.lastName}`,
                email: ownerInfo.email,
                totalPrice: contract.pricingPositions.reduce((sum, price) => sum + parseFloat(price.price.slice(-1)), 0),
                sellingPrice: contract.sellingPrice,
                status: contract.status.replace('_', ' '),
                theftId: contract.theftId,
                mileage: contract.mileage,
                vehicleYear: contract.vehicleYear,
                make: contract.make,
                model: contract.model,
                color: contract.color,
                stock: contract.stock,
                purchasePrice: contract.purchasePrice,
                removed: contract.removed ? 'true' : 'false',
                dealerId: contract.dealerId,
                userId: contract.userId,
                createdAt: contract.createdAt,
                updatedAt: contract.updatedAt,
                ownerType: ownerInfo.ownerType,
                firstName: ownerInfo.firstName,
                lastName: ownerInfo.lastName,
                phone: ownerInfo.phone,
                address: ownerInfo.address,
                zip: ownerInfo.zip,
                city: ownerInfo.city,
                state: ownerInfo.state,
                lienholderName: ownerInfo.lineholderName,
                lienholderAddress: ownerInfo.lineholderAddress
            })
        }))
    };


    return (
        <React.Fragment>
            <div className='content'>
                <div className="content-row">
                    <div className='filters'>
                        <div className='filter-roles'>
                            <button 
                                onClick={() => handleContractTypeChange(null)}
                                className={filters.contractTypeFilter === null ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                All
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('PENDING')}
                                className={filters.contractTypeFilter === 'PENDING' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Pending
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('NON_FINALIZED')}
                                className={filters.contractTypeFilter === 'NON_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Non Finalized
                            </button>

                            <button 
                                onClick={() => handleContractTypeChange('MANUAL_FINALIZED')}
                                className={filters.contractTypeFilter === 'MANUAL_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Manual Finalized
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('AUTO_FINALIZED')}
                                className={filters.contractTypeFilter === 'AUTO_FINALIZED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Auto Finalized
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('CANCELLED')}
                                className={filters.contractTypeFilter === 'CANCELLED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Cancelled
                            </button>
                            <button 
                                onClick={() => handleContractTypeChange('TRANSFERRED')}
                                className={filters.contractTypeFilter === 'TRANSFERRED' ? 'filter-buttons active' : 'filter-buttons'}
                            >
                                Transferred
                            </button>
                        </div>
                        <div className='filter-actions'>
                            <CSVLink 
                                data={csvData}
                                className='link__form-add'
                                filename={"contracts.csv"}
                            >
                                <span className="hide-for-mobile">
                                    Download
                                </span>
                                <img src={save} alt="Save"/>
                            </CSVLink>
                            <Link 
                                to='/addcontract'
                                className='link__form-add'
                            > 
                                <span className="hide-for-mobile">
                                    Add
                                </span>
                                <img src={add} alt="Add"/>
                            </Link>
                        </div>
                    </div>
                </div>


 
                <div className="content-row">
                    <div className='contracts-table__header'>
                        <div className="contract-table__header-group">
                            <div>
                                <button
                                    onClick={() => handleFiltersModalOpen()}
                                    className='header-reset'
                                >
                                    Filters
                                </button>
                                
                            </div>
                            <button
                                onClick={() => setApplyResetTable(prev => !prev)}
                                className='filter-buttons active'
                            >
                                Reset Filters
                            </button>
                            <div className='header-filters'>
                                {
                                    filters.vinSearchFilter && filters.vinSearchFilter !== '' &&
                                        <div className="vertical-align-div">
                                            VIN: {filters.vinSearchFilter}
                                        </div>
                                }
                                {
                                    filters.dealerSearchFilter && filters.vinSearchFilter === '' &&
                                        <div className="vertical-align-div">
                                            Dealer: {filters.dealerSearchFilter.name}
                                        </div>
                                }
                                {
                                    <div className="vertical-align-div">
                                        {moment(filters.startDateDay).format('MMMM D')} - {moment(filters.endDateDay).format('MMMM D')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ContractsTable
                    currentPage={currentPage}
                    handlePageData={setPageData}
                    handleCSVData={handleCSVData}
                    applyResetTable={applyResetTable}
                    filters={filters}
                />
                <ContractPagination
                    totalPages={pageData.totalPages}
                    handlePageChange={handlePageChange}
                    loading={pageData.loading}
                    applyResetTable={applyResetTable}
                    filters={filters}
                    
                />
            </div>

			<ContractFiltersModal
				filtersModalOpen={filtersModalOpen}
				handleFiltersModalClose={handleFiltersModalClose}
                handleSubmitFilters={handleSubmitFilters}
				filters={filters}
				applyResetTable={applyResetTable}
			/>
            
            
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        contractsFilters: state.contractsFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub)),
        set_contract_status: (status) => dispatch(set_contract_status(status)),
        set_contract_vin: (vin) => dispatch(set_contract_vin(vin)),
        set_contract_dealer: (dealer) => dispatch(set_contract_dealer(dealer)),
        set_contract_start_date: (startDate) => dispatch(set_contract_start_date(startDate)),
        set_contract_end_date: (endDate) => dispatch(set_contract_end_date(endDate)),
        set_contract_page: (page) => dispatch(set_contract_page(page))
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsPage);