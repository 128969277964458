import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import moment from 'moment';

// Components
import LoadingPage from '../LoadingPage';

// services
import AuthService from '../../services/AuthService';
import HistoryService from '../../services/HistoryService';
import ProductService from '../../services/ProductService';

// images
import history from '../../images/history-log.svg';

function DealerHistoryPage(props) {

    const { id } = useParams();
    const navigate = useNavigate();

    const [historyLog, setHistoryLog] = useState([])
    const [successful, setSuccessful] = useState(false)

    useEffect(() => {
        ProductService.getAllProducts()
            .then(response => {
                const products = response.data
                HistoryService.getHistory('dealer', id)
                    .then(response => {
                        let logEntries = []

                        response.data.reverse().forEach((entry) => {
                            let tempLogs = []
                        
                            if (entry.action === 'dealerCreate') {
                                tempLogs.push('Dealer created')
                            } else if (entry.action === 'dealerUpdate') {
                                tempLogs.push('Dealer info updated')
                            } else if (entry.action === 'linkDealerToUsers') {
                                JSON.parse(entry.description).users.forEach((user) => {
                                    tempLogs.push(`Assigned to ${user.email}`)
                                })
                            } else if (entry.action === 'linkUsers') {
                                console.log(entry)
                                console.log(JSON.parse(entry.description))
                                tempLogs.push('User linked')
                            } else if (entry.action === 'unlinkUsers') {
                                console.log(entry)
                                console.log(JSON.parse(entry.description))
                                tempLogs.push('User unlinked')
                            } else if (entry.action === 'pricingPositionCreate') {
                                tempLogs.push('Pricing position created')
                            } else if (entry.action === 'pricingPositionUpdate') {
                                tempLogs.push('Pricing position updated')
                            } else if (entry.action === 'linkProducts') {
                                JSON.parse(entry.description).products.forEach((product) => {
                                    for (let i=0; i < products.length; i++){
                                        if (products[i]['id'] === product['id']){
                                            tempLogs.push(`Product "${products[i]['name']}" added`)
                                        }
                                    }
                                })
                            } else if (entry.action === 'unlinkProducts') {
                                JSON.parse(entry.description).products.forEach((product) => {
                                    for (let i=0; i < products.length; i++) {
                                        if (products[i]['id'] === product){
                                            tempLogs.push(`Product "${products[i]['name']}" removed`)
                                            break;
                                        }
                                    }
                                })
                            }

                            tempLogs.forEach((log) => {
                                logEntries.push({
                                    title: log,
                                    author: entry.author.split(":")[0],
                                    date: moment(entry.createdAt).format('MM/DD/yyyy'),
                                    time: moment(entry.createdAt).format('LT')
                                })
                            })
                        })
                        
                        setHistoryLog(logEntries)
                        setSuccessful(true)

                    }, e => {
                        if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                            AuthService.logout()
                            navigate('/login')
                        }
                        setSuccessful(false)
                    })
            },
            error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            });
    }, [id, navigate])

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className="details-page__form" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    {
                        historyLog.map(log => (
                            <div className='history-record' key={`${log['title']}-${log['author']}-${log['date']}-${log['time']}`}>
                                <div className='history-details'>
                                    <button
                                        className='history-icon'
                                        disabled={true}
                                    >
                                        <img src={history} alt="history"/>
                                    </button>
                                    <div className='history-text'>
                                        <p className='history-text__title'>{log.title}</p>
                                        <p className='history-text__subtitles'>By: {log.author}</p>
                                        <div className='history-text__date'>
                                            <p>{log.date}</p>
                                            •
                                            <p>{log.time}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="history-expand">
                                    <button
                                        className='history-expand-button'
                                        disabled={true}
                                    >
                                        See Details >
                                    </button>
                                </div>*/}
                            </div>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default DealerHistoryPage;