
const dealersFiltersReducerDefaultState = {
    dealer: null,
    dealerGroup: null,
    dealerTerritory: null,
    page: 1
};

export default (state = dealersFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_DEALER':
            return {
                ...state,
                dealer: action.dealer
            }
        case 'SET_DEALER_GROUP':
            return {
                ...state,
                dealerGroup: action.dealerGroup
            }
        case 'SET_DEALER_TERRITORY':
            return {
                ...state,
                dealerTerritory: action.dealerTerritory
            }
        case 'SET_DEALER_PAGE':
            return {
                ...state,
                page: action.page
            }
        default: {
            return state
        };
    };
};