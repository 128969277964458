import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Navbar
} from 'reactstrap';

import AuthService from '../services/AuthService';

function Header({ header }) {
    const navigate = useNavigate();
    const onLogout = () => {
        AuthService.logout();
        navigate('/login')
    };

    return (
        <Navbar
            expand="lg"
            className="navbar-absolute fixed-top"
        >
            <Container fluid>
                <div className='header__content'>
                    <div className="header__title">
                        {
                            (header['main'] && header['sub']) ?
                                <div>
                                    <div className="vertical-align-div">
                                        <span className="sub">
                                            {header['main']}
                                        </span>
                                    </div>
                                    <div className="vertical-align-div">
                                        &bull;
                                    </div>
                                    <span>
                                        {header['sub']}
                                    </span>
                                </div>
                                :
                                <span>
                                    {header['main']}
                                </span>
                        }
                    </div>
                    <Button onClick={onLogout} className='hide-for-mobile'>Logout</Button>
                </div>
            </Container>
        </Navbar>
    );
};

const mapStateToProps = (state) => {
    return {
        header: state.header
    };
};

export default connect(mapStateToProps)(Header);;