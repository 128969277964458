import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// Components
import SideMenu from '../components/SideMenu';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
// services
import UserService from '../services/UserService';

const PrivateRoute = ({ children }) => {
    const [userRole, setUserRole] = useState('');

    function hasJWT() {
        let isAuthenticated = false
        localStorage.getItem('accessToken') ? isAuthenticated=true : isAuthenticated=false
        return isAuthenticated
    };

    useEffect(() => {
        const role = UserService.getUserRole()
        setUserRole(role)
    }, [])

    return hasJWT() ? (
        <React.Fragment>
            <div className='content-container'>
                <SideMenu 
                    component={children}
                    role={userRole}
                />
                <div className='content-page'>
                    <Header />
                    {children}
                </div>
                <FooterMenu/>
            </div>
            </React.Fragment>
    ) : (
        <Navigate to='/login' />
    )
};

export default PrivateRoute;