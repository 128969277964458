import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// components
import Notifications from '../Notifications';
// calendar libraries
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// services
import UserService from '../../services/DealerService';
import TerritoryService from '../../services/TerritoryService';
// other
import moment from 'moment';
import Select from 'react-select';
// icons
import greyX from '../../images/grey-x.svg';

function UserFiltersModal({ 
    usersFilters, filtersModalOpen, handleFiltersModalClose, handleSubmitFilters, applyResetTable 
}) {

    const [initialRender, setInitialRender] = useState(true)
    const [role, setRole] = useState(usersFilters.role)
    const [roleLabels, setRoleLabels] = useState([
        {
            value: 'SUPERADMIN',
            label: 'Superadmin'
        },
        {
            value: 'METROTECH_ADMIN',
            label: 'Admin'
        },
        {
            value: 'METROTECH_MANAGER',
            label: 'MetroTech Manager'
        },
        {
            value: 'METROTECH_TERRITORY_MANAGER',
            label: 'MetroTech Territory Manager'
        },
        {
            value: 'DEALERSHIP_FI',
            label: 'Dealership F&I'
        },
        {
            value: 'DEALERSHIP_ACCOUNTING',
            label: 'Dealership Accounting'
        },
        {
            value: 'DEALERSHIP_ADMIN',
            label: 'Dealership Admin'
        }
    ])
    const [email, setEmail] = useState(usersFilters.email)


    useEffect(() => {
        setInitialRender(false)
    }, [])

    useEffect(() => {
        if (!initialRender) {
            setRole(null)
            setEmail('')
        }
    }, [applyResetTable])

    const handleRoleChange = (role) => {
        setRole(role)
    };

    const onSubmit = () => {
        const filters = {
            roleTypeFilter: role,
            emailFilter: email
        }
        handleSubmitFilters(filters)

    }; 

    return (
        <Modal
            isOpen={filtersModalOpen}
            onRequestClose={handleFiltersModalClose}
            closeTimeoutMS={200}
            ariaHideApp={false}
            className='modal-contract__action'
        >
            <div className="modal-header">
                <h2>Filters</h2>
                <button className="close" onClick={handleFiltersModalClose}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>

            <div>
                <span>Role:</span>
                <Select 
                    options={roleLabels}
                    value={role}
                    onChange={handleRoleChange}
                />
            </div>

            <div>
                Email: 
                <input 
                    type='text'
                    placeholder='Enter Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='input input__form'
                />
            </div>

            <div className='modal-footer'>
                <button
                    onClick={() => handleFiltersModalClose()}
                    className='footer__cancel-button'
                >
                    <div className="vertical-align-div">
                        <span>Cancel</span>
                    </div>
                    
                </button>
                <button
                    onClick={() => onSubmit()}
                    className='footer__save-button'
                >
                    <div className='vertical-align-div'>
                        <span>Apply Filters</span>
                    </div>
                </button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        usersFilters: state.usersFilters
    };
};

export default connect(mapStateToProps)(UserFiltersModal);