import api from './api';

const getAllProducts = (page, name, contractLetter) => {
    return api
        .get('/products', { 
            params: { 
                includeDealers: true, 
                includePricingPosition: true ,
                page,
                name,
                contractLetter
            }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getProduct = (id) => {
    return api
        .get('/products/' + id, { params: { includeDealers: true, includePricingPosition: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getProductsFromDealer = (id) => {
    return api
        .get('/products/dealer/' + id, { params: { includePricingPosition: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        })
};

const createProduct = (productPayload) => {
    return api
        .post('/products/create', productPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateProduct = (productPayload) => {
    return api
        .post('/products/update', productPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const removeProduct = () => {

};

const getAllContractTemplates = () => {
    return api
        .get('/products/all/contractTemplate', { params: { includeDealer: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};



const ProductService = {
    getAllProducts,
    getProduct,
    getProductsFromDealer,
    createProduct,
    updateProduct,
    removeProduct,
    getAllContractTemplates
};

export default ProductService;
