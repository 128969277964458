import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// components
import LoadingPage from '../LoadingPage';
import Notifications from '../Notifications';
// table
import { useTable, useSortBy } from "react-table";
// services
import DealerService from '../../services/DealerService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
// icons
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import gear from '../../images/gear.svg';
import edit from '../../images/edit.svg';



function DealersTable({ 
    manualPagination = false, currentPage, handlePageData, handleCSVData, filters, applyResetTable}) {

    const [initialRender, setInitialRender] = useState(true)
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalDealers: 0
    })


    useEffect(() => {

        if (initialRender) {
            setInitialRender(false)
        }
        setPageData((prev) => ({
            ...prev,
            rowData: [],
            loading: true
        }))
        

        DealerService.getAllDealers(
            currentPage, 
            filters.dealerFilter !== null ? filters.dealerFilter.name : null, 
            filters.dealerTerritoryFilter !== null ? filters.dealerTerritoryFilter.value : null,
            filters.dealerGroupFilter !== null ?  filters.dealerGroupFilter.value : null)
            .then(response => {
                filterDealers(response)

                DealerService.getAllDealers(
                    null, 
                    filters.dealerFilter !== null ? filters.dealerFilter.name : null, 
                    filters.dealerTerritoryFilter !== null ? filters.dealerTerritoryFilter.value : null,
                    filters.dealerGroupFilter !== null ?  filters.dealerGroupFilter.value : null
                ).then(r => {
                    handleCSVData(r.data)
                }, e => {
                    Notifications("error", e['message'])
                })

            }, error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", error['message'])
            });




        
    }, [currentPage, filters])

    // filter table on reset button
    useEffect(() => {


        if (!initialRender) {
            setPageData(prev => ({
                ...prev,
                loading: true
            }));
            

            DealerService.getAllDealers(1)
                .then(response => {
                    filterDealers(response)
                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                })
        }
        
    }, [applyResetTable])


    const filterDealers = (response) => {
        const { data, meta } = response
        const tableData = data.map(dealer => ({
            id: dealer.id, 
            selected: false,
            territory: dealer.territory ? dealer.territory.code : '',
            title: dealer.title ? dealer.title : '',
            name: dealer.name ? dealer.name : '',
            phone: dealer.phone ? dealer.phone : '',
            address: dealer.address ? dealer.address : '',
            ext: dealer.ext ? dealer.ext : '',
            status: 'Active',
            edit: <Link to={`/dealers/${dealer.id}?tab=info`}><img src={edit} alt="Edit"/></Link>,
            dealerGroup: dealer.dealerGroup ? dealer.dealerGroup.name : ''
        }))

        const dealersData = {
            rowData: tableData,
            loading: false,
            totalPages: meta.totalPages,
            totalDealers: meta.totalItems
        }
        setPageData(dealersData)
        handlePageData(dealersData)
    };





    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Dealer Group',
                accessor: 'dealerGroup',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Territory',
                accessor: 'territory',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Address',
                accessor: 'address',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Gear"/>,
                accessor: 'edit',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], [] 
    )

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
                manualPagination,
                initialState: {
                    sortBy: [
                        {
                            id: 'activationDate',
                            desc: true
                        }
                    ]
                }
            },
            useSortBy
        );

        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} className="table-header__icon-img" alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} className="table-header__icon-img" alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} className="table-header__icon-img" alt="Sort"/>;
            } else {
                return null
            }
        };

        const generateVerticalLine = (column) => {
            if (column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        

        return (
            <React.Fragment>
                <div className='table-div'>
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => (
                                                <th className='table-header'{...column.getHeaderProps()}>
                                                    <div className='table-header__div'>
                                                        {column.render("Header")}
                                                        <div className='table-header__icons'>
                                                            <div>
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </th>

                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className="flex-end">{cell.render("Cell")}</td>;
                                        })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>  
            </React.Fragment>
        )
    }





    return (
        <React.Fragment>
            {
                pageData.loading ? (
                    <LoadingPage />
                ) : (
                    <div className='table'>
                        <Table columns={columns} data={pageData.rowData}/>
                        {
                            manualPagination && (
                                <div>
                                    {}
                                </div>
                            )
                        }
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default DealersTable;