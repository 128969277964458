import React from 'react';
import { Navigate } from 'react-router-dom';
// services
import UserService from '../services/UserService';

const NonDealershipRoute = ({ children }) => {
    function isNotDealership() {
        const userRole = UserService.getUserRole();
        return userRole!=='DEALERSHIP_FI' && userRole!=='DEALERSHIP_ACCOUNTING'
    };

    return isNotDealership() ? (
        <React.Fragment>
            {children}
        </React.Fragment>
        
    ) : (
        <Navigate to='/contracts' />
    )
};

export default NonDealershipRoute;