import React from 'react';
import Modal from 'react-modal';

// images
import blueX from '../../images/blue-x.svg';
import greyX from '../../images/grey-x.svg';
import plus from '../../images/plus.svg';

function ProductModal({ isOpen, toggleMenu, handleAddLink, handleDeleteConfirmation, assignedProducts, availableProducts }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleMenu}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-product'
        >   
            <div className="modal-header">
                <h2>Appointment of Products</h2>
                <button className="close" onClick={toggleMenu}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <div style={{ display: 'flex', overflow: 'auto', minHeight: '50px'}}>
                <div className='assigned-products__div'>
                    {
                        assignedProducts.sort((a, b) => a['name'].localeCompare(b['name'])).map(product => {
                            return (
                                <button
                                    onClick={(e) => handleDeleteConfirmation(e, product)}
                                    className="assigned-products__button"
                                    key={product.id}
                                >   
                                    <span>
                                        {product.name}
                                    </span>
                                    <div>
                                        <img src={blueX} alt="Remove"/>
                                    </div>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
            <hr/>
            <div style={{ display: 'flex', overflow: 'auto'}}>
                <div className='available-products__div'>
                    {
                        availableProducts.sort((a, b) => a['name'].localeCompare(b['name'])).map(product => {
                            return (
                                <button
                                    onClick={() => handleAddLink(product)}
                                    className='available-products__button'
                                    key={product.id}
                                >
                                    <div>
                                        <img src={plus} alt="Add"/>
                                    </div>
                                    <span>{product.name}</span>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </Modal>
    );
};

export default ProductModal;