import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
    Button
} from 'reactstrap';

// Components
import UserForm from './UserForm';
import Notifications from '../Notifications';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// services
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

// actions
import { set_header } from '../../actions/header';

function AddUserPage({ set_header }) {
    const navigate = useNavigate();

    const formValidation = (user) => {
        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        
        if (user['email'] === "") {
            return false;
        } else if (!user['email'].match(validEmailRegex)) {
            return false;
        } else if (user['name'] === "") {
            return false;
        } else if (user['surname'] === "") {
            return false;
        } else if (user['role'] === null || user['role'] === undefined) {
            return false;
        }

        return true;
    }

    const onSubmit = (user) => {
        if (formValidation(user)) {
            UserService.createUser(
                user
            ).then(response => {
                Notifications("success", "Setup email has been sent to the user.")
                navigate('/users')
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
        }
    };

    useEffect(() => {
        set_header('Users', 'NEW')
    }, [set_header]);

    return (
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/users'>
                                <Button>
                                    <img src={leftChevron} alt="Back"/>
                                </Button>
                            </Link>
                        </div>
                        <div className='details-page__menu'>
                            <button 
                                className='filter-buttons active'
                            >
                                Info
                            </button>
                        </div>
                    </div>
                </div>
                <div className="content-row flex-grow">
                    <UserForm 
                        onSubmit={onSubmit}
                        adding={true}
                    />
                </div>
                <div className="content-row footer">
                    <Link to='/users'>
                        <Button
                            className="footer__cancel-button"
                        >
                            <div className="vertical-align-div">
                                <span>Cancel</span>
                            </div>
                        </Button>
                    </Link>
                    <Button
                        className="footer__save-button"
                        form='user-form'
                        type='submit'
                    >
                        <div className="vertical-align-div">
                            <span>Save Info</span>
                        </div>
                        <div className="vertical-align-div">
                            <img src={save} alt="Save"/>
                        </div>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(AddUserPage);