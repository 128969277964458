import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Services
import AuthService from '../../services/AuthService';

// Fonts
import { FaEnvelope } from 'react-icons/fa';

function ForgotPasswordForm(props) {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const onEmailChange = (e) => {
        const val = e.target.value;
        setEmail(val)
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || !emailRegEx.test(email)) {
            setError('Please enter a valid email address')
        } else {
            AuthService.reset(email).then(() => {
                setError('');
                props.onSubmit();
            },
            error => {
                setError('Unable to find email')
            })
        };
    };
    return (
        <React.Fragment>
            <form onSubmit={onSubmit} className='form'>
                <label style={{ marginBottom: '5rem'}}>
                    <div>
                        Email
                    </div>
                    <div className='input-container'>
                        <FaEnvelope className='icon-input'/>
                        <input 
                            type='text'
                            placeholder='example@system.com'
                            autoFocus
                            value={email}
                            onChange={onEmailChange}
                            className='input'
                        />
                    </div>
                    {
                        error && 
                            <div className='login__error'>
                                <span>{error}</span>
                            </div>
                    }
                </label>
                <div>
                    <input 
                        type='submit' 
                        value='Get Password'
                        className='input input--submit'
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to='/login' className='link'>
                        Back
                    </Link>
                </div>
            </form>
        </React.Fragment>
    );
};

export default ForgotPasswordForm;