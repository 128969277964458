
const productsFiltersReducerDefaultState = {
    name: '',
    contractLetter: '',
    page: 1
};

export default (state = productsFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_NAME':
            return {
                ...state,
                name: action.name
            }
        case 'SET_PRODUCT_CONTRACT_LETTER':
            return {
                ...state,
                contractLetter: action.contractLetter
            }
        case 'SET_PRODUCT_PAGE':
            return {
                ...state,
                page: action.page
            }
        default: {
            return state
        };
    };
};