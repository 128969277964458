import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// actions
// services
import AuthService from '../../services/AuthService'

// Fonts
import { FaEye, FaEyeSlash, FaEnvelope, FaKey } from 'react-icons/fa';

function AuthenticationForm(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberPassword, setRememberPassword] = useState(false);
    const [error, setError] = useState('');

    const fetchData = () => {
        try {
            const emailData = localStorage.getItem('email')
            if (emailData) {
                setEmail(emailData)
                setRememberPassword(true)
            }
        } catch (e) {

        }
    };

    // Initialize email and 'remember me' state by grabbing it from local storage if RememberMe was ticked at login
    useEffect(() => {
        fetchData()
    }, [])

    const onEmailChange = (e) => {
        const val = e.target.value;
        setEmail(val)
    };
    const onPasswordChange = (e) => {
        const val = e.target.value;
        setPassword(val)
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || !password || !emailRegEx.test(email)) {
            setError('Please enter a valid email and password')
        } else {
            AuthService.login(email, password).then(() => {
                setError('')
                if (!rememberPassword) {
                    localStorage.removeItem('email')
                } else {
                    localStorage.setItem('email', email)
                }
                props.onSubmit()
            },
            error => {
                setError('Your login credentials are incorrect')
                console.log(error)
            })
        };
    };
    return (
        <form onSubmit={onSubmit} className='form'>
            {
                error && <p className='login__error'>{error}</p>
            }
            <label>
                <div>
                    Email
                </div>
                <div className='input-container'>
                    <FaEnvelope className='icon-input'/>
                    <input 
                        type='text'
                        placeholder='example@system.com'
                        autoFocus
                        value={email}
                        onChange={onEmailChange}
                        className='input'
                    />
                </div>
            </label>
            <label>
                <div>
                    Password
                </div>
                <div className='input-container'>
                    <FaKey className='icon-input' color='grey'/>
                    <input 
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        value={password}
                        onChange={onPasswordChange}
                        className='input'
                    />
                    <button 
                        type='button'
                        onClick={() => setShowPassword(!showPassword)}
                        className='icon-button'
                    >
                        {
                            showPassword ? <FaEye color='grey' /> : <FaEyeSlash color='grey'/>
                        }
                    </button>
                </div>
            </label>
            <div className="form__options">
                <div className="form__remember">
                    <input 
                        type='checkbox'
                        checked={rememberPassword}
                        onChange={() => setRememberPassword(!rememberPassword)}
                    />
                    Remember me
                </div>
                <Link 
                    to='/forgot'
                    className='link'
                >
                    Forgot password?
                </Link>
            </div>
            <div>
                <input 
                    type='submit' 
                    value='Sign in'
                    className='input input--submit' 
                />
            </div>
        </form>
    );
};

export default AuthenticationForm;