import React, { useState, useEffect } from 'react';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';

import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

function ContractEditor({ html, setHtml }) {
    const [css, setCss] = useState('');
    const [js, setJs] = useState('');
    const [output, setOutput] = useState('');

    // update output screen 
    useEffect(() => {
        updateOutput()
    }, [html, css, js])

    const updateOutput = () => {
        const combinedOutput = `
            <html>
                <head>
                    <style>${css}</style>
                </head>
                <body>
                    ${html}
                    <script>${js}</script>
                </body>
            </html>
        `;
        setOutput(combinedOutput)
    };

    return (
        <React.Fragment>
            {
                html &&
                    <div className="contract-editor">
                        <Allotment>
                            <Allotment.Pane>
                                <div className="allotment-div">
                                    <CodeMirror
                                        value={html}
                                        extensions={[javascript({ jsx: true })]}
                                        onChange={(value, viewUpdate) => {
                                            setHtml(value)
                                        }}
                                    />
                                </div>
                            </Allotment.Pane>
                            <Allotment.Pane>
                                <div className="allotment-div">
                                    <iframe
                                        title='Result'
                                        srcDoc={output}
                                        style={{ border: 'none', width: '100%', height:'100%' }}
                                    />
                                </div>
                            </Allotment.Pane>
                        </Allotment>
                    </div>
            }
        </React.Fragment>
    )
};

export default ContractEditor;