import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button
} from 'reactstrap';
import Notifications from '../Notifications';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// Components
import ContractForm from './ContractForm';

// services
import AuthService from '../../services/AuthService';
import ContractService from '../../services/ContractService';

// actions
import { set_header } from '../../actions/header';

function AddContractPage({ set_header }) {
    const navigate = useNavigate();

    const [canSave, setCanSave] = useState(true)
    const [createdContracts, setCreatedContracts] = useState([])

    const formValidation = (contract, vinDetails) => {
        const ownerInfo = JSON.parse(contract.owner)

        let validSellingPrice = true;
        contract['products'].forEach(item => { 
            if (item.sellingPrice === "" || item.sellingPrice === null) {
                validSellingPrice = false
            }
        })

        if (validSellingPrice === false) {
            return false;
        }
        
        if (contract['dealerId'] === "" || contract['dealerId'] === undefined || contract['dealerId'] === null) {
            return false;
        } else if (contract['vin'] === "" || contract['vin'] === undefined) {
            return false;
        } else if (contract['isNewCondition'] === "" || contract['isNewCondition'] === null) {
            return false;
        } else if (contract['vehicleYear'] === "" || contract['vehicleYear'] === null) {
            return false;
        } else if (ownerInfo['ownerType'] === "" || ownerInfo['ownerType'] === null) {
            return false;
        } else if (ownerInfo['firstName'] === "" || ownerInfo['firstName'] === null) {
            return false;
        } else if (ownerInfo['lastName'] === "" || ownerInfo['lastName'] === null) {
            return false;
        } else if (contract['purchasePrice'] === "" || contract['purchasePrice'] === undefined || isNaN(contract['purchasePrice'])) {
            return false;
        } else if (contract['products'].length === 0) {
            return false;
        }


        if (vinDetails === null || vinDetails.Results[0].ErrorCode.split(",")[0] !== "0" || vinDetails.Results[0].ModelYear !== contract['vehicleYear']) {
            return false
        }

        return true;
    }

    async function onSubmit(contract, vinDetails) {
        if (formValidation(contract, vinDetails)) {
            for (const product of contract.products) {
                const singleContract = {
                    vin: contract.vin,
                    dealerId: contract.dealerId,
                    userId: contract.userId,
                    theftId: contract.theftId,
                    mileage: contract.mileage,
                    isNewCondition: contract.isNewCondition, 
                    vehicleYear: contract.vehicleYear,
                    make: contract.make,
                    model: contract.model,
                    color: contract.color,
                    stock: contract.stock,
                    purchasePrice: contract.purchasePrice,
                    activationDate: contract.activationDate,
                    owner: contract.owner,
                    productId: product.productId,
                    pricingPositionIds: [product.id],
                    sellingPrice: parseFloat(product.sellingPrice)
                }
                try {
                    // const newContract = ContractService.asyncCreateContract(singleContract)
                    // await newContract
                    let newContract = await ContractService.asyncCreateContract(singleContract)
                    
                    const createdContractInfo = {
                        // contractId: newContract.data.data.contract.id,
                        contractId: newContract.data.data.id,
                        pricingPositionId: singleContract.pricingPositionIds,
                        productId: singleContract.productId,
                        status: newContract.data.data.status
                    }
                    setCreatedContracts(prev => [
                        ...prev,
                        createdContractInfo
                    ])
                } catch (e) {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                }
            }

            if (contract.products.length > 1) {
                Notifications('success', 'Contracts have been created.')
            } else {
                Notifications('success',' Contract has been created.')
            }

            setCanSave(false)
        }
    };

    useEffect(() => {
        set_header('Contracts', 'NEW')
    }, [set_header])

    return (
        <div className="content">
            <div className="content-row">
                <div className='details-page__header'>
                    <div className='vertical-align-div'>
                        <Link to='/contracts'>
                            <Button>
                                <img src={leftChevron} alt="Back"/>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="content-row flex-grow">
                <ContractForm 
                    createdContracts={createdContracts}
                    onSubmit={onSubmit}
                />
            </div>
            <div className="content-row footer">
                <Link to='/contracts'>
                    <Button
                        className="footer__cancel-button"
                    >
                        <div className="vertical-align-div">
                            <span>Cancel</span>
                        </div>
                    </Button>
                </Link>
                <Button
                    className="footer__save-button"
                    form='contract-form'
                    type='submit'
                    disabled={!canSave}
                >
                    <div className="vertical-align-div">
                        <span>Save Changes</span>
                    </div>
                    <div className="vertical-align-div">
                        <img src={save} alt="Save"/>
                    </div>
                </Button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(AddContractPage);