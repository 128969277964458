import moment from 'moment';

const contractsFiltersReducerDefaultState = {
    status: null,
    vin: '',
    dealer: null,
    startDate: moment().subtract(30, 'days').toISOString(),
    endDate: moment().toISOString(),
    page: 1
};

export default (state = contractsFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_CONTRACT_STATUS':
            return {
                ...state,
                status: action.status
            };
        case 'SET_CONTRACT_VIN':
            return {
                ...state,
                vin: action.vin
            }
        case 'SET_CONTRACT_DEALER':
            return {
                ...state,
                dealer: action.dealer
            }
        case 'SET_CONTRACT_START_DATE':
            return {
                ...state,
                startDate: action.startDate
            }
        case 'SET_CONTRACT_END_DATE':
            return {
                ...state,
                endDate: action.endDate
            }
        case 'SET_CONTRACT_PAGE':
            return {
                ...state,
                page: action.page
            }
        default: {
            return state
        };
    };
};
