import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// Components
import DealersTable from './DealersTable';
import DealersPagination from './DealersPagination';
import DealerFiltersModal from './DealerFiltersModal';
// images
import save from '../../images/save-dark.svg';
import add from '../../images/add.svg';
// actions
import { set_header } from '../../actions/header';
import { set_dealer, set_dealer_group, set_dealer_territory } from '../../actions/dealersFilters'
// services
import UserService from '../../services/UserService';


function DealersPage({ dealersFilters, set_header, set_dealer, set_dealer_group, set_dealer_territory }) {
    
    const navigate = useNavigate();
    const userRole = UserService.getUserRole();
    const [initialRender, setInitialRender] = useState(true)
    const [csvData, setCSVData] = useState([])
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalDealers: 0
    })
    const [currentPage, setCurrentPage] = useState(dealersFilters.page);
    const [filters, setFilters] = useState({
        dealerFilter: dealersFilters.dealer,
        dealerGroupFilter: dealersFilters.dealerGroup,
        dealerTerritoryFilter: dealersFilters.dealerTerritory
	})
    const [filtersModalOpen, setFiltersModalOpen] = useState(false)
    const [applyResetTable, setApplyResetTable] = useState(false)

    useEffect(() => {
        set_header('Dealers', null)
        setInitialRender(false)
    }, [set_header, navigate])

    useEffect(() => {
        if (!initialRender) {
            setFilters({
                dealerFilter: null,
                dealerGroupFilter: null,
                dealerTerritoryFilter: null
            })
            set_dealer(null)
            set_dealer_group(null)
            set_dealer_territory(null)
        }
        
    }, [applyResetTable])  



    const handleFiltersModalOpen = () => {
        setFiltersModalOpen(true)
    };

    const handleFiltersModalClose = () => {
        setFiltersModalOpen(false)
    };    

    const handleSubmitFilters = (filters) => {
        setFilters({
            dealerFilter: filters.dealerFilter,
            dealerGroupFilter: filters.dealerGroupFilter,
            dealerTerritoryFilter: filters.dealerTerritoryFilter
            
        });
        set_dealer(filters.dealerFilter)
        set_dealer_group(filters.dealerGroupFilter)
        set_dealer_territory(filters.dealerTerritoryFilter)
        setFiltersModalOpen(false)
    };


    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const handleCSVData = (data) => {

        setCSVData(data.map(dealer => ({
            id: dealer.id,
            territory: dealer.territory ? dealer.territory.code : '',
            title: dealer.title ? dealer.title : '',
            name: dealer.name ? dealer.name : '',
            phone: dealer.phone ? dealer.phone : '',
            ext: dealer.ext ? dealer.ext : '',
            dealerNumber: dealer.dealerNumber ? dealer.dealerNumber : '',
            dealerGroup: dealer.dealerGroup ? dealer.dealerGroup.name : '',
            state: dealer.state ? dealer.state : '',
            zip: dealer.zip ? dealer.zip : '',
            city: dealer.city ? dealer.city : '',
            address: dealer.address ? dealer.address : '',
            address2: dealer.address2 ? dealer.address2 : '',
            createdAt: dealer.createdAt ? dealer.createdAt : '',
            updatedAt: dealer.updatedAt ? dealer.updatedAt : '',
            removed: dealer.removed ? 'true' : 'false',
            active: dealer.active ? 'true' : 'false',
            autoFinalize: dealer.autoFinalize ? dealer.autoFinalize : '',
        })))
        
    };

    
    return (
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='filters'>
                        <div className='contracts-table__header'>
                            <div>
                                <button
                                    onClick={() => handleFiltersModalOpen()}
                                    className='header-reset'
                                >
                                    Filters
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setApplyResetTable(prev => !prev)}
                                    className='filter-buttons active'
                                >
                                    Reset Filters
                                </button>
                            </div>
                            <div className="contract-table__header-group">
                                <div className='header-filters'>
                                    {
                                        filters.dealerFilter &&
                                            <div className="vertical-align-div">
                                                Dealer: {filters.dealerFilter.name}
                                            </div>
                                    }
                                    {
                                        filters.dealerGroupFilter &&
                                            <div className='vertical-align-div'>
                                                Dealer Group: {filters.dealerGroupFilter.label}
                                            </div>
                                    }
                                    {
                                        filters.dealerTerritoryFilter &&
                                            <div className='vertical-align-div'>
                                                Territory: {filters.dealerTerritoryFilter.label}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        
                        {
                            (userRole!=='SUPERADMIN' && userRole!=='METROTECH_ADMIN') ? (
                                <div className='filter-actions'>
                                    <CSVLink 
                                        data={csvData}
                                        className='link__form-add'
                                        filename={"dealers.csv"}
                                    >
                                        <span className="hide-for-mobile">
                                            Download
                                        </span>
                                        <img src={save} alt="Save"/>
                                    </CSVLink>
                                </div>
                            ) : (
                                <div className='filter-actions'>
                                    <CSVLink 
                                        data={csvData}
                                        className='link__form-add'
                                        filename={"dealers.csv"}
                                    >
                                        <span className="hide-for-mobile">
                                            Download
                                        </span>
                                        <img src={save} alt="Save"/>
                                    </CSVLink>
                                    <Link 
                                        to='/adddealer'
                                        className='link__form-add'
                                    >
                                        <span className="hide-for-mobile">
                                            Add
                                        </span>
                                        <img src={add} alt="Add"/>
                                    </Link>
                                </div>
                            )
                        }
                
                    </div>
                </div>

                <DealersTable
                    currentPage={currentPage}
                    handlePageData={setPageData}
                    handleCSVData={handleCSVData}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />
                <DealersPagination
                    totalPages={pageData.totalPages}
                    handlePageChange={handlePageChange}
                    loading={pageData.loading}
                    filters={filters}
                    applyResetTable={applyResetTable}
                />

            </div>

            <DealerFiltersModal
                filtersModalOpen={filtersModalOpen}
                handleFiltersModalClose={handleFiltersModalClose}
                handleSubmitFilters={handleSubmitFilters}
                applyResetTable={applyResetTable}
            />

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        dealersFilters: state.dealersFilters
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub)),
        set_dealer: (dealer) => dispatch(set_dealer(dealer)),
        set_dealer_group: (dealerGroup) => dispatch(set_dealer_group(dealerGroup)),
        set_dealer_territory: (dealerTerritory) => dispatch(set_dealer_territory(dealerTerritory)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealersPage);