import api from './api';

const getHistory= (entity, id) => {
    return api
        .get('/history/find', { params: { entity: entity, id: id }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const HistoryService = {
    getHistory
};



export default HistoryService;