import React from 'react';
import Modal from 'react-modal';
// images
import greyX from '../../images/grey-x.svg';

function BulkApproveContractsModal({ isOpen, toggleBulkApproveContractsModal, onSubmitBulkApproveContractsModal }) {
    const onSubmit = (message) => {
        onSubmitBulkApproveContractsModal(message)
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleBulkApproveContractsModal}
            closeTimeoutMS={200}
            ariaHideApp={false}
            className='modal-contract__action'
        >
            <div className='modal-header'>
                <h2><b>Do you want to approve all pending contracts?</b></h2>
                <button className='close' onClick={toggleBulkApproveContractsModal}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <div className="modal-footer">
                <button
                    onClick={() => onSubmit('success')}
                    className='link__form-add'
                >
                    Yes
                </button>
                <button
                    onClick={() => onSubmit('error')}
                    className='link__form-add'
                >
                    No
                </button>
            </div>
        </Modal>
    );
};

export default BulkApproveContractsModal;