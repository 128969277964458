import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Redux
import store from './store/configureStore';
import { Provider } from 'react-redux';

// Sidebar
import { ProSidebarProvider } from 'react-pro-sidebar';

import {
    NotificationContainer
} from 'react-notifications';

// Components
import LoginPage from './components/Login/LoginPage';
import AppRouter from './routers/AppRouter';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss'
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <NotificationContainer/>
    <ProSidebarProvider>
      <AppRouter />
      </ProSidebarProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
