import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Notifications from '../Notifications';

// password requirement
import PasswordChecklist from 'react-password-checklist';

// Fonts
import { FaKey } from 'react-icons/fa';

// Services
import UserService from '../../services/UserService';
import TokenService from '../../services/TokenService';


function ResetPasswordForm(props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams(); 
    const token = searchParams.get('token');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    
    const onSubmit = (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            setError('Please enter a password.')
        } else if (!validPassword) {
            setError('The password requirements have not been met.');
        } else {
            TokenService.setLocalAccessToken(token);
            
            UserService.updatePassword(password).then(() => {
                setError('');
                props.onSubmit();
            }, 
            error => {
                console.log(error)
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    TokenService.removeLocalAccessToken();
                    navigate('/forgot')
                    Notifications('longError', 'Your link has expired. Please enter your email to get a new link.')
                } else {
                    setError('Unable to change password')
                }
            });
        };
    };

    return (
        <div>
            <form onSubmit={onSubmit} className='form'>
                {
                    error && <p className='login__error'>{error}</p>
                }
                <label>
                    <div>
                        New Password
                    </div>
                    <div className='input-container'>
                        <FaKey className='icon-input' color='grey'/>
                        <input 
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='input'
                        />
                    </div>
                </label>
                <label>
                    <div>
                        Confirm New Password
                    </div>
                    <div className='input-container'>
                        <FaKey className='icon-input' color='grey'/>
                        <input 
                            type='password'
                            placeholder='Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className='input'
                        />
                    </div>
                </label>
                <PasswordChecklist
                    rules={['minLength', 'specialChar', 'capital', 'match']}
                    minLength={10}
                    value={password}
                    valueAgain={confirmPassword}
                    onChange={(isValid) => setValidPassword(isValid)}
                />
                <div>
                    <input 
                        type='submit' 
                        value='Create Password'
                        className='input input--submit'
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to='/login' className='link' onClick={() => TokenService.removeLocalAccessToken()}>
                        Back
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default ResetPasswordForm;