import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import Modal from 'react-modal';
import {
    Button
} from 'reactstrap';

Modal.defaultStyles.overlay.backgroundColor = '#6c757dc4';

function FooterSettingsModal({ isOpen, toggleOpen }) {
    const navigate = useNavigate();

    const onLogout = () => {
        AuthService.logout();
        navigate('/login')
    };

    return (
        <Modal
            isOpen={isOpen}
            className='footer-menu-modal'
            onRequestClose={toggleOpen}
            ariaHideApp={false}
        >   
            <div className="footer-menu-modal__section">
                <div className='footer-menu-modal__buttons'>
                    <Button onClick={onLogout}>Logout</Button>
                </div>
            </div>
        </Modal>
    );
};

export default FooterSettingsModal;