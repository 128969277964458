import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button
} from 'reactstrap';

// components
import DealerForm from './DealerForm.js';
// import DealersDetailsSummary from './DealersDetailsSummary';
import DealerProductsPage from './DealerProductsPage.js';
import DealerPricingPage from './DealerPricingPage.js';
import DealerUsersPage from './DealerUsersPage.js';
import DealerHistoryPage from './DealerHistoryPage.js';
import Notifications from '../Notifications.js';
import LoadingPage from '../LoadingPage.js';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// services
import AuthService from '../../services/AuthService.js';
import DealerService from '../../services/DealerService.js';
import UserService from '../../services/UserService.js';

// actions
import { set_header } from '../../actions/header.js';

function DealerInfoPage({ set_header }) {

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab'));
    const [successful, setSuccessful] = useState(false)
    const [selectedDealer, setSelectedDealer] = useState({});
    const navigate = useNavigate();
    const userRole = UserService.getUserRole();



    useEffect(() => {

        if (tab === null) {
            setTab('info')
            navigate(`/dealers/${id}?tab=info`, { replace: true })
        }


        DealerService.getDealer(id).then(response => {

            if (response.data === null) {
                navigate('/dealers')
                return
            }
            setSelectedDealer({
                ...response.data,
                territory: response.data.territory ? {
                    value: response.data.territory.id,
                    label: response.data.territory.code
                } : {

                },
                dealerGroup: response.data.dealerGroup ? {
                    value: response.data.dealerGroup.id,
                    label: response.data.dealerGroup.name
                } : {

                }
            })
            setSuccessful(true)
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })
    }, [id, navigate]);

    useEffect(() => {
        if (selectedDealer.name) {
            set_header('Dealers', selectedDealer.name)
        }
    }, [selectedDealer, set_header])

    const formValidation = (dealer) => {
        if (dealer['name'] === "" || dealer['address'] === '' || dealer['zip'] === '' || dealer['city'] === '' || 
            dealer['state'] === '' || dealer['state'] === null || dealer['territory'] === {} || 
            isNaN(Number(dealer['netSuiteId']))) {
            return false;
        }
        return true;
    }

    const handleTabSwitch = (nextTab) => {
        setTab(nextTab)
        navigate(`/dealers/${id}?tab=${nextTab}`)
    };

    const onSubmit = (updatedDealer) => {
        
        let dealerWithoutFinalize = updatedDealer
        
        if (id !== '' && userRole === 'METROTECH_ADMIN'){ // METROTECH ADMINS CANNOT EDIT AUTOFINALIZE FIELD
            delete dealerWithoutFinalize.autoFinalize
        }

        if (formValidation(updatedDealer)) {
            DealerService.updateDealer(
                {
                    ...dealerWithoutFinalize,
                    territoryId: updatedDealer.territory ? updatedDealer.territory.value : '',
                    groupId: updatedDealer.dealerGroup ? updatedDealer.dealerGroup.value : '',
                    id: selectedDealer.id
                }
            ).then(response => {
                setSelectedDealer(prev => ({
                    ...prev,
                    ...(userRole === 'SUPERADMIN') && {autoFinalize: updatedDealer['autoFinalize']}, // only superadmin can edit autoFinalize
                    address: updatedDealer['address'],
                    address2: updatedDealer['address2'],
                    city: updatedDealer['city'],
                    dealerNumber: updatedDealer['dealerNumber'],
                    ext: updatedDealer['ext'],
                    name: updatedDealer['name'],
                    phone: updatedDealer['phone'],
                    state: updatedDealer['state'],
                    territory: updatedDealer['territory'],
                    title: updatedDealer['title'],
                    zip: updatedDealer['zip'],
                    dealerGroup: updatedDealer['dealerGroup'],
                    netSuiteId: updatedDealer.netSuiteId
                }))
                Notifications('success', 'Dealer info has been updated.')
            }, 
            error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
            });
        }
    };

    // api call unsuccessful
    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    }

    return (        
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/dealers'>
                                <Button>
                                    <img src={leftChevron} alt="Left Chevron"/>
                                </Button>
                            </Link>
                        </div>
                        <div className='details-page__menu'>
                            <button 
                                className={tab === 'info' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('info')}
                            >
                                Info
                            </button>
                            <button 
                                className={tab === 'products' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('products')}
                            >
                                Products
                            </button>
                            <button 
                                className={tab === 'pricing' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('pricing')}
                            >
                                Pricing Position
                            </button>
                            {
                                (userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN' || userRole === 'DEALERSHIP_ADMIN') &&
                                    <button 
                                        className={tab === 'users' ? 'filter-buttons active' : 'filter-buttons'}
                                        onClick={() => handleTabSwitch('users')}
                                    >
                                        Users
                                    </button>
                            }
                            {
                                userRole === 'SUPERADMIN' &&
                                <button 
                                    className={tab === 'history' ? 'filter-buttons active' : 'filter-buttons'}
                                    onClick={() => handleTabSwitch('history')}
                                >
                                    History
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {
                    tab === 'info' &&
                        <React.Fragment>
                            <div className="content-row flex-grow">
                                <Row>
                                    {/*<Col xs={12} md={12} lg={4}>
                                        <DealersDetailsSummary 
                                            dealer={selectedDealer}
                                        />
                                    </Col>*/}
                                    <Col xs={12} md={12} lg={12}>
                                        <DealerForm
                                            dealer={selectedDealer}
                                            editing={id !== ''}
                                            onSubmit={onSubmit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {
                                (userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') ? (
                                    <div className="content-row footer">
                                        <Link to='/dealers'>
                                            <Button
                                                className="footer__cancel-button"
                                            >
                                                <div className="vertical-align-div">
                                                    <span>Cancel</span>
                                                </div>
                                            </Button>
                                        </Link>
                                        <Button
                                            className="footer__save-button"
                                            form='dealer-form'
                                            type='submit'
                                        >
                                            <div className="vertical-align-div">
                                                <span>Save Info</span>
                                            </div>
                                            <div className="vertical-align-div">
                                                <img src={save} alt="Save"/>
                                            </div>
                                        </Button>
                                    </div>
                                ) : (
                                    <div className='content-row footer' />
                                )
                            }
                        </React.Fragment>
                }
                {
                    tab === 'products' &&
                        <DealerProductsPage />
                }
                {
                    tab === 'users' &&
                        <DealerUsersPage />
                }
                {
                    tab === 'pricing' &&
                        <DealerPricingPage />
                }
                {
                    tab === 'history' &&
                        <DealerHistoryPage />
                }
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(DealerInfoPage);