import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    ButtonGroup
} from 'reactstrap';
import Select from 'react-select';
import { PatternFormat } from 'react-number-format';
import { States } from 'countries-states-cities-service';

// services
import TerritoryService from '../../services/TerritoryService';
import UserService from '../../services/UserService';
import DealerService from '../../services/DealerService';

// components
import Notifications from '../Notifications';

function DealerForm(props) {

    const userRole = UserService.getUserRole();
    const [readOnly, setReadOnly] = useState(false);
    
    const [title, setTitle] = useState('');
    const [dealerId, setDealerId] = useState('');
    const [dealerNumber, setDealerNumber] = useState('');
    const [name, setName] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [firstAddress, setFirstAddress] = useState('');
    const [secondAddress, setSecondAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [ext, setExt] = useState('');
    const [state, setState] = useState(null);
    const [stateLabels, setStateLabels] = useState(States.getStates({ filters: { country_code: 'US' } }).map((state) => {
        return {
            value: state.state_code,
            label: state.name
        }
    }))
    const [autoFinalize, setAutoFinalize] = useState(false)
    const [netSuiteId, setNetSuiteId] = useState("");

    const [territory, setTerritory] = useState(null);
    const [territoryLabels, setTerritoryLabels] = useState([]);

    const [dealerGroup, setDealerGroup] = useState(null);
    const [dealerGroupLabels, setDealerGroupLabels] = useState([]);

    // populate fields if editing existing dealer
    useEffect(() => {
        TerritoryService.getTerritory()
            .then(response => {
                setTerritoryLabels(response.data.map((territory) => {
                    return {
                        value: territory.id,
                        label: territory.code
                    }
                }).sort((a, b) => a['label'].localeCompare(b['label'])))
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch territories list.')
            })
        
        // setStateLabels()

        //  dealer groups
        if (userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') {
            DealerService.getAllDealerGroups()
                .then(response => {
                    setDealerGroupLabels(response.data.map((group) => {
                        return {
                            value: group.id,
                            label: group.name
                        }
                    }))
                }, e => {
                    console.log(e)
                    Notifications('error', 'Unable to fetch dealer groups list.')
                })
        }
        
        if (props.dealer) {
            setDealerId(props.dealer.id ? props.dealer.id : '')
            setTitle(props.dealer.title ? props.dealer.title : '')
            setDealerNumber(props.dealer.dealerNumber ? props.dealer.dealerNumber : '')
            setName(props.dealer.name ? props.dealer.name : '')
            setZip(props.dealer.zip ? props.dealer.zip : '')
            setCity(props.dealer.city ? props.dealer.city : '')
            setFirstAddress(props.dealer.address ? props.dealer.address : '')
            setSecondAddress(props.dealer.address2 ? props.dealer.address2 : '')
            setPhone(props.dealer.phone ? props.dealer.phone : '')
            setExt(props.dealer.ext ? props.dealer.ext : '')
            setAutoFinalize(props.dealer.autoFinalize)
            setNetSuiteId(props.dealer.netSuiteId ? props.dealer.netSuiteId : '')

            const selectedState = States.getStates({
                filters: {
                    country_code: 'US',
                    state_code: props.dealer.state
                }
            })[0]

            if (selectedState) {
                setState({
                    value: props.dealer.state,
                    label: selectedState.name
                })
            }

            if (props.dealer.territory) {
                setTerritory(props.dealer.territory)
            } else {
                setTerritory({})
            }

            if (props.dealer.dealerGroup) {
                setDealerGroup(props.dealer.dealerGroup)
            } else {
                setDealerGroup({})
            }
        }

        if (props.editing && userRole !== 'SUPERADMIN' && userRole !== 'METROTECH_ADMIN') {
            setReadOnly(true)
        }

    }, [props.dealer, userRole, props.editing])

    const handleStateChange = (stateLabel) => {
        setState(stateLabel)
    }

    const handleTerritoryChange = (territoryLabel) => {
        setTerritory(territoryLabel)
    }

    const handleDealerGroupChange = (dealerGroupLabel) => {
        setDealerGroup(dealerGroupLabel)
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (name === "") {
            Notifications("error", "Name is required.")
        }
        if (firstAddress === "") {
            Notifications("error", "Address is required.")
        }
        if (zip === "") {
            Notifications("error", "ZIP is required.")
        }
        if (city === "") {
            Notifications("error", "City is required.")
        }
        if (state === "" || state === null) {
            Notifications("error", "State is required.")
        }
        if (territory === {}) {
            Notifications("error", "Territory is required.")
        }
        if (isNaN(Number(netSuiteId))) {
            Notifications('error', 'Please enter a numerical value for NetSuite ID.')
        }

        const dealer = {
            name,
            title,
            territory: territory,
            state: state ? state.value : null,
            zip,
            ext,
            city,
            address: firstAddress,
            address2: secondAddress,
            phone,
            dealerNumber,
            autoFinalize,
            netSuiteId: netSuiteId ? parseInt(netSuiteId) : null,
            dealerGroup: dealerGroup
        };
        
        props.onSubmit(dealer);
    };

    return (
        <React.Fragment>
            <div className='details-page__form'>
                <form id='dealer-form' onSubmit={onSubmit}>
                    <Row>
                        <Col md={4} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Dealer GUID</div>
                                    <input
                                        type='text'
                                        placeholder='Dealer GUID'
                                        value={dealerId}
                                        className='input input__form'
                                        disabled={true}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Legacy Customer ID</div>
                                    <input
                                        type='text'
                                        placeholder='Enter legacy customer ID'
                                        value={dealerNumber}
                                        onChange={(e) => setDealerNumber(e.target.value)}
                                        maxLength={6}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>NetSuite Internal ID</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter NetSuite ID'
                                        value={netSuiteId}
                                        onChange={(e) => setNetSuiteId(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Name*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Address 1*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter First Address'
                                        value={firstAddress}
                                        onChange={(e) => setFirstAddress(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Address 2</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Second Address'
                                        value={secondAddress}
                                        onChange={(e) => setSecondAddress(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Territory</div>
                                    <Select
                                        options={territoryLabels}
                                        value={territory}
                                        onChange={handleTerritoryChange}
                                        id='selectTerritories'
                                        isDisabled={readOnly}
                                        menuPortalTarget={document.body}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        State*
                                    </div>
                                    <Select 
                                        options={stateLabels}
                                        value={state}
                                        onChange={handleStateChange}
                                        id='selectStates'
                                        isDisabled={readOnly}
                                        menuPortalTarget={document.body}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>ZIP*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter ZIP'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>City*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Phone</div>
                                    <PatternFormat
                                        type='tel'
                                        format='(###) ###-####'
                                        mask='_'
                                        value={phone}
                                        onValueChange={value => setPhone(value.formattedValue)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Ext</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Ext'
                                        value={ext}
                                        onChange={(e) => setExt(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Dealer Group</div>
                                    <Select 
                                        options={dealerGroupLabels}
                                        value={dealerGroup}
                                        onChange={handleDealerGroupChange}
                                        id='selectDealerGroup'
                                        menuPlacement="top"
                                        isDisabled={readOnly}
                                        menuPortalTarget={document.body}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Auto-Finalize Contracts</div>
                                    <ButtonGroup>
                                        <Button 
                                            onClick={() => setAutoFinalize(true)}
                                            className="form-button"
                                            active={autoFinalize===true}
                                            disabled={userRole !== 'SUPERADMIN'}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            onClick={() => setAutoFinalize(false)}
                                            className="form-button"
                                            active={autoFinalize===false}
                                            disabled={userRole !== 'SUPERADMIN'}
                                        >
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </label>
                            </div>
                        </Col>
                        
                    </Row>
                </form>
            </div>
            
        </React.Fragment>
    );
};

export default DealerForm;