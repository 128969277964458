import React, { useState, useEffect } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { States } from 'countries-states-cities-service';
import { PatternFormat } from 'react-number-format';
import Select from 'react-select';

// services
import UserService from '../../services/UserService';

// components
import Notifications from '../Notifications';

function UserForm(props) {

    const userRole = UserService.getUserRole();

    const [readOnly, setReadOnly] = useState(false);
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [territory, setTerritory] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [ext, setExt] = useState('');
    const [fax, setFax] = useState('');
    const [role, setRole] = useState([]);
    const [state, setState] = useState('');

    const stateLabels = States.getStates({ filters: { country_code: 'US' } }).map((state) => {
        return {
            value: state.state_code,
            label: state.name
        }
    })

    const availableRoles = React.useMemo(
        () => {
            switch (userRole) {
                case 'SUPERADMIN':
                    return [
                        { value: "SUPERADMIN", label: "Super Admin" },
                        { value: "METROTECH_ADMIN", label: "MetroTech Admin" },
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                        { value: "DEALERSHIP_ACCOUNTING", label: "Dealership Accounting" },
                        { value: "DEALERSHIP_ADMIN", label: "Dealership Admin" }
                    ];
                case 'METROTECH_ADMIN':
                    return [
                        { value: "METROTECH_ADMIN", label: "MetroTech Admin" },
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                        { value: "DEALERSHIP_ACCOUNTING", label: "Dealership Accounting" },
                        { value: "DEALERSHIP_ADMIN", label: "Dealership Admin" }
                    ]
                case 'METROTECH_MANAGER':
                    return [
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                    ]
                case 'METROTECH_TERRITORY_MANAGER':
                    return [
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                    ]
                case 'DEALERSHIP_ADMIN':
                    return [
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" }
                    ]
                default: 
                    return []
            }
        }
    )

    // populate fields if editing existing user
    useEffect(() => {
        if (props.user) {
            setTitle(props.user.title ? props.user.title : '')
            setName(props.user.name ? props.user.name : '')
            setSurname(props.user.surname ? props.user.surname : '')
            setTerritory(props.user.territory ? props.user.territory : '')
            setZip(props.user.zip ? props.user.zip : '')
            setCity(props.user.city ? props.user.city : '')
            setAddress(props.user.address ? props.user.address : '')
            setEmail(props.user.email ? props.user.email : '')
            setPhone(props.user.phone ? props.user.phone : '')
            setExt(props.user.ext ? props.user.ext : '')
            setFax(props.user.fax ? props.user.fax : '')

            const userRole = availableRoles.find(r => r['value'] === props.user.role);

            if (userRole !== undefined) {
                setRole(userRole)
            } else {
                setRole(props.user.role)
            }

            if (props.user.state) {
                const selectedState = States.getStates({
                    filters: {
                        country_code: 'US',
                        state_code: props.user.state
                    }
                })[0]

                if (selectedState) {
                    setState({
                        value: props.user.state,
                        label: selectedState.name
                    })
                }
            }

            // Check if the user can edit selected user
            if (!availableRoles.find(r => r['value'] === props.user.role)) {
                setReadOnly('readonly')
            }
        }
    }, [props.adding, props.editing, props.user, userRole])

    const handleStateChange = (stateLabel) => {
        setState(stateLabel)
    }

    const handleRoleChange = (e) => {
        setRole(e)
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (email === "") {
            Notifications("error", "Email is required.")
        }
        if (!!email && !email.match(validEmailRegex)) {
            Notifications("error", "Email is not valid.")
        }
        if (name === "") {
            Notifications("error", "First name is required.")
        }
        if (surname === "") {
            Notifications("error", "Last name is required.")
        }
        if (role['value'] === null || role['value'] === undefined) {
            Notifications("error", "User role is required.")
        }

        const user = {
            name,
            surname,
            title,
            territory,
            state: state ? state.value : null,
            zip,
            ext,
            city,
            address,
            email,
            phone,
            fax,
            role: role['value'],
        };
        props.onSubmit(user);
    };

    return (
        <React.Fragment>
            <div className='details-page__form'>
                <form id='user-form' onSubmit={onSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>First Name*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter First Name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Last Name*</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Last Name'
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Territory</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Territory'
                                        value={territory}
                                        onChange={(e) => setTerritory(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>State</div>
                                    <Select 
                                        options={stateLabels}
                                        value={state}
                                        onChange={handleStateChange}
                                        id='selectStates'
                                        isDisabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>ZIP</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter ZIP'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>City</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Address</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Phone</div>
                                    <PatternFormat
                                        type='tel'
                                        format='(###) ###-####'
                                        mask='_'
                                        value={phone}
                                        onValueChange={value => setPhone(value.formattedValue)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} >
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Fax</div>
                                    <PatternFormat
                                        type='tel'
                                        format='(###) ###-####'
                                        mask='_'
                                        value={fax}
                                        onValueChange={value => setFax(value.formattedValue)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Ext</div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Ext'
                                        value={ext}
                                        onChange={(e) => setExt(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} >
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Email*</div>
                                    <input
                                        type='text'
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>

                    </Row>    
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Title</div>
                                    <input
                                        type='text'
                                        placeholder='Enter Title'
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        User Role*
                                    </div>
                                    {
                                        readOnly ?
                                            <input
                                                type='text'
                                                placeholder='User role'
                                                value={role}
                                                className='input input__form'
                                                disabled={readOnly}
                                            />
                                            :
                                            <Select
                                                options={availableRoles}
                                                value={role}
                                                onChange={handleRoleChange}
                                                menuPlacement="top"
                                                className="input-select-container"
                                                id='selectRole'
                                                isDisabled={readOnly}
                                            />
                                    }
                                    
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </React.Fragment>
    );
};

export default UserForm;


