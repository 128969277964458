
const usersFiltersReducerDefaultState = {
    role: null,
    email: '',
    page: 1
};

export default (state = usersFiltersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_USER_ROLE':
            return {
                ...state,
                role: action.role
            }
        case 'SET_USER_EMAIL':
            return {
                ...state,
                email: action.email
            }
        case 'SET_USER_PAGE':
            return {
                ...state,
                page: action.page
            }
        default: {
            return state
        };
    };
};