import moment from 'moment';

export const set_contract_status = (status= null) => ({
    type: 'SET_CONTRACT_STATUS',
    status
});

export const set_contract_vin = (vin = '') => ({
    type: 'SET_CONTRACT_VIN',
    vin
});
export const set_contract_dealer = (dealer = {}) => ({
    type: 'SET_CONTRACT_DEALER',
    dealer
});
export const set_contract_start_date = (startDate = moment()) => ({
    type: 'SET_CONTRACT_START_DATE',
    startDate
});
export const set_contract_end_date = (endDate = moment()) => ({
    type: 'SET_CONTRACT_END_DATE',
    endDate
});
export const set_contract_page = (page = 1) => ({
    type: 'SET_CONTRACT_PAGE',
    page
});