import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    useTable, 
    usePagination, 
    useFilters,
    useExpanded,
    useRowSelect,
    useSortBy 
  } from 'react-table';

// table filters
import { Filter, InputColumnFilter } from '../TableFilters';

// components
import Notifications from '../Notifications';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import LoadingPage from '../LoadingPage';
import ProductModal from './ProductModal';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';

// images
import checkboxEmpty from '../../images/checkbox-empty.svg';
import checkboxFull from '../../images/checkbox-full.svg';
import greyX from '../../images/grey-x.svg';
import add from '../../images/add.svg';
import gear from '../../images/gear.svg';
import search from '../../images/search.svg';
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import trash from '../../images/trash.svg';

// actions

const defaultRowHeight = 50

function DealerProductsPage(props) {

    const navigate = useNavigate();

    const { id } = useParams();

    const tableRef = useRef(null)

    const [successful, setSuccessful] = useState('');
    const [calcPageSize, setCalcPageSize] = useState(10)
    const [menuOpen, setMenuOpen] = useState(false)

    const [assignedProducts, setAssignedProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [data, setData] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    const [deletedProduct, setDeletedProduct] = useState({});
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);

    const userRole = UserService.getUserRole();

    useEffect(() => {
        // on refresh page, dealers global state needs to be re-populated
        DealerService.getDealer(id).then(response => {
            setSuccessful(true)
            setAssignedProducts(response.data.products)
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })

        if (userRole === 'SUPERADMIN') {
            ProductService.getAllProducts()
                .then(response => {
                    setSuccessful(true)

                    setAllProducts(response.data)

                    if (tableRef.current) {
                        setCalcPageSize(Math.floor(tableRef.current.clientHeight / defaultRowHeight) - 1)
                    } else {
                        setCalcPageSize(Math.floor((window.innerHeight * 0.8) / defaultRowHeight))
                    }
                },
                error => {
                    if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    setSuccessful(false)
                });
        }
        
    }, [id, navigate, userRole])

    useEffect(() => {
        setData(assignedProducts.map((product) => ({
            id: product.id,
            selected: false,
            product: product.name,
            contract: product.contractLetter,
            used: product.condition.toString(),
            usedCriteria: product.year,
            terms: product.terms.toString(),
            productType: product.productType ? product.productType.name : '',
            edit: <button 
                    onClick={(e) => handleDeleteConfirmation(e, product)}
                    className='button-no-background'
                >
                    <img src={trash} alt="Delete"/>
                </button>
        })));
    }, [assignedProducts])

    const handleAddLink = (product) => {
        const dealerPayload = {
            dealerId: id,
            productIds: [product.id]
        }

        DealerService.linkProductToDealer(dealerPayload).then(response => {
            setAssignedProducts(prevState => [
                ...prevState,
                product
            ])

            Notifications('success', 'Product has been added.');
        }).catch(err => {
            if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            Notifications("error", err.message)
        })
    };

    const toggleDeleteConfirmationModal = () => {
        setDeleteConfirmationModalOpen(current => {
            return !current
        })
    };

    const handleDeleteConfirmation = (e, removedProduct) => {
        e.stopPropagation()

        setDeletedProduct(removedProduct)
        setDeleteConfirmationModalOpen(true)
    };

    const onSubmitDeleteConfirmationModal = (message) => {
        if (message === 'yes') {
            const newProducts = assignedProducts.filter(product => deletedProduct !== product)

            const dealerPayload = {
                dealerId: id,
                productIds: [deletedProduct.id]
            }
            DealerService.unlinkProductFromDealer(dealerPayload).then(response => {
                setAssignedProducts(newProducts)
                toggleDeleteConfirmationModal()
                Notifications('success', 'Product has been removed.');
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err.message)
            })
        } else {
            toggleDeleteConfirmationModal()
        }
    }

    let availableProducts = allProducts.filter(p => {
        return !assignedProducts.some(ap => ap['id'] === p['id'])
    })

    const productFilterRef = useRef();
    const contractLetterFilterRef = useRef();
    const productTypeFilterRef = useRef();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };
    
    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'Select',
            //     accessor: 'selected',
            //     disableSortBy: true,
            //     disableFilters: true,
            //     Cell: ({ row, cell }) => {
            //         const [value, setValue] = useState(cell.value)

            //         return (
            //             <div className="table-cell__div" onClick={() => onSelectRow(row)}>
            //                 {
            //                     value ?
            //                         <img src={checkboxFull}/>
            //                         :
            //                         <img src={checkboxEmpty}/>
            //                 }
            //             </div>
            //         );
            //     }
            // },
            {
                Header: 'Product',
                accessor: 'product',
                Filter: InputColumnFilter,
                ref: productFilterRef,
                filter: 'equals',
                id: 'product',
                name: 'product',
                searchHidden: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Type',
                accessor: 'productType',
                Filter: InputColumnFilter,
                ref: productTypeFilterRef,
                disableSortBy: true,
                filter: 'equals',
                id: 'productType',
                name: 'productType',
                searchHidden: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract Letter(s)',
                accessor: 'contract',
                Filter: InputColumnFilter,
                ref: contractLetterFilterRef,
                disableSortBy: true,
                filter: 'equals',
                id: 'contractLetter',
                name: 'contractLetter',
                searchHidden: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'New/Used',
                accessor: 'used',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    let tempValue = value.split(',')
        
                    return (
                        <div className="table-cell__div">
                            {
                                tempValue.includes('0') && 
                                    <div className="badge badge__new">
                                        New
                                    </div>
                            }
                            {
                                tempValue.includes('1') && 
                                    <div className="badge badge__used">
                                        Used
                                    </div>
                            }
                        </div>
                    );
                }
            },
            {
                Header: 'New/Used Criteria',
                accessor: 'usedCriteria',
                disableFilters: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Default Available Terms',
                accessor: 'terms',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    if (value !== '') {
                        let tempValue = value.split(',')
                        tempValue = tempValue.map(v => isNaN(v) ? v.charAt(0).toUpperCase() + v.slice(1) : `${v} year${parseInt(v) > 1 ? 's' : ''}`)
                        tempValue.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        
                        return (
                            <div className="table-cell__div">
                                {
                                    tempValue.map(v => {
                                        return (
                                            <div key={v} className="badge badge__terms">
                                                {v}
                                            </div>
                                        )
                                    })
                                }
                          </div>
                        );
                    }
                }
            },
            {
                Header: <img src={gear} alt="Gear"/>,
                accessor: 'edit',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], []
    )

    // const onSelectRow = (row) => {
    //     // setSkipPageReset(true)
    //     setData(prev => (
    //         prev.map(d => {
    //             return {
    //                 ...d,
    //                 selected: d['id'] === row.original.id ? !d['selected'] : d['selected']
    //             }
    //         })
    //     ))
    // }
    
    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            pageCount,
            gotoPage,
            state: { pageIndex },
        } = useTable(
            {
                columns,
                data,
                initialState: { 
                    defaultPageSize: 10,
                    pageSize: calcPageSize,
                    sortBy: [
                        {
                            id: 'product',
                            desc: false
                        }
                    ],
                    ...userRole!=='SUPERADMIN' && {hiddenColumns: ['edit']},
                    
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
            },
            useFilters,
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect
        )
    
        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} className="table-header__icon-img" alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} className="table-header__icon-img" alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} className="table-header__icon-img" alt="Sort"/>;
            } else {
                return null
            }
        };

        const onSearchClick = (column) => {
            column.searchHidden = !column.searchHidden

            column.setFilter(undefined)
        }
    
        const generateSearchIndicator = (column) => {
            if (column.canFilter && !column.searchHidden) {
                return <img className="table-header__icon-img" src={greyX} onClick={() => onSearchClick(column)} alt="Close"/>
            } else if (column.canFilter) {
                return <img className="table-header__icon-img" src={search} onClick={() => onSearchClick(column)} alt="Search"/>
            } else {
                return null
            }
        }
    
        const generateVerticalLine = (column) => {
            if (column.canFilter && column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        const onAllSelectRowClick = () => {
            setData(prev => (
                prev.map(d => {
                    return {
                        ...d,
                        selected: !allSelected
                    }
                })
            ))
            setAllSelected(prev => !prev)
        }
      
        // Render the UI for your table
        return (
            <React.Fragment>
                <div ref={tableRef} className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={`table-header ${column.canFilter && 'filterable'} ${(userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') && 'flex-end'}`} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {
                                                            column.id === 'selected' ?
                                                                <img src={allSelected ? checkboxFull : checkboxEmpty} onClick={onAllSelectRowClick} style={{ cursor: 'pointer' }} alt="Select"/>
                                                                :
                                                                (column.canFilter && !column.searchHidden) ? 
                                                                    <Filter column={column} hidden={column.searchHidden}/>
                                                                    :
                                                                    column.render('Header')
                                                        }
                                                        <div className="table-header__icons">
                                                            <div>
                                                                {generateSearchIndicator(column)}
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()} className={`${(userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN') && "flex-end"}`}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* 
                  Pagination can be built however you'd like. 
                  This is just a very basic UI implementation:
                */}
                <div className="pagination">
                    { 
                        <div className="pagination__table">
                            {
                                [...Array(pageCount).keys()].map(i => {
                                    return (
                                        <div className="vertical-align-div" key={`products-page-index-${i}`}>
                                            <button 
                                                className={pageIndex === i ? 'pagination__page-button active' : 'pagination__page-button'}
                                                onClick={() => gotoPage(i)}
                                            >
                                                {i+1}
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="content-row">
                <div className='filters'>
                    <span>
                        Products
                    </span>
                    {
                        userRole === 'SUPERADMIN' &&
                            <button 
                                className='link__form-add'
                                onClick={() => toggleMenu()}
                            >
                                <span className="hide-for-mobile">
                                    Add
                                </span>
                                <div className='vertical-align-div'>
                                    <img src={add} alt="Add"/>
                                </div>
                            </button>
                    }
                    
                </div>
            </div>
            <div className='table'>
                <Table columns={columns} data={data} />
            </div>

            <ProductModal 
                isOpen={menuOpen}
                toggleMenu={toggleMenu}
                handleAddLink={handleAddLink}
                handleDeleteConfirmation={handleDeleteConfirmation}
                assignedProducts={assignedProducts}
                availableProducts={availableProducts}
            />
            <DeleteConfirmationModal
                isOpen={deleteConfirmationModalOpen}
                message={"Confirm remove product?"}
                toggleModal={toggleDeleteConfirmationModal}
                onSubmitDeleteConfirmationModal={onSubmitDeleteConfirmationModal}
            />
        </React.Fragment>
    );
}; 

export default DealerProductsPage;