import api from './api';

const getAllDealers = (page, name = null, territory = null, dealerGroup = null) => {
    return api
        .get('/dealers', { 
            params: { 
                includeUsers: true, 
                includeProducts: true, 
                includePricingPositions: true,
                page,
                name,
                territory,
                dealerGroup
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getDealer = (id) => {
    return api
        .get('/dealers/' + id, { params: { includeUsers: true, includeProducts: true, includePricingPositions: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createDealer = (dealerPayload) => {
    return api
        .post('/dealers/create', dealerPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateDealer = (dealerPayload) => {
    return api
        .post('/dealers/update', dealerPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const linkProductToDealer = (dealerPayload) => {
    return api
        .post('/dealers/linkProducts', dealerPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};

const unlinkProductFromDealer = (dealerPayload) => {
    return api
        .post('/dealers/unlinkProducts', dealerPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};


const linkUserToDealer = (dealerPayload) => {
    return api
        .post('/dealers/linkUsers', dealerPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};

const unlinkUserFromDealer = (dealerPayload) => {
    return api
        .post('/dealers/unlinkUsers', dealerPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};

const getAllDealerGroups = () => {
    return api
        .get('/groups')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createDealerGroup = (name) => {
    return api
        .post('/groups/create', name)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateDealerGroup = (dealerGroupPayload) => {
    return api
        .post('/groups/update', dealerGroupPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const deleteDealerGroup = (dealerGroupPayload) => {
    return api
        .post('/groups/delete', dealerGroupPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const DealerService = {
    getAllDealers,
    getDealer,
    createDealer,
    updateDealer,
    linkProductToDealer,
    unlinkProductFromDealer,
    linkUserToDealer,
    unlinkUserFromDealer,
    getAllDealerGroups,
    createDealerGroup,
    updateDealerGroup,
    deleteDealerGroup
};

export default DealerService;