import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { States } from 'countries-states-cities-service';
import {
    Row,
    Col,
    Button,
    ButtonGroup
} from 'reactstrap';
import Select from 'react-select';
import { PatternFormat } from 'react-number-format';

// images
import save from '../../images/save.svg';
import greyX from '../../images/grey-x.svg';

// components
import Notifications from '../Notifications';


function TransferContractOwnerModal({ isOpen, toggleModal, onSubmitTransfer}) {

    const [ownerType, setOwnerType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState(null);
    const [stateLabels, setStateLabels] = useState([])
    const [lineholderName, setLineholderName] = useState('');
    const [lineholderAddress, setLineholderAddress] = useState('');

    useEffect(() => {
        setStateLabels(States.getStates({ filters: { country_code: 'US' } }).map((state) => {
            return {
                value: state.state_code,
                label: state.name
            }
        }))
    }, [])

    useEffect(() => {
        setOwnerType('')
        setFirstName('')
        setLastName('')
        setPhone('')
        setEmail('')
        setAddress('')
        setZip('')
        setCity('')
        setState(null)
        setLineholderName('')
        setLineholderAddress('')
    }, [isOpen])

    const handleStateChange = (stateLabel) => {
        setState(stateLabel)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
        if (ownerType === "") {
            Notifications("error", "Owner type is required.")
        }
        if (firstName === "") {
            Notifications("error", "First name is required.")
        }
        if (lastName === "") {
            Notifications("error", "Last name is required.")
        }

        const newOwner = JSON.stringify({
            ownerType: ownerType,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            address: address,
            zip: zip,
            city: city,
            state: state ? state.value : null,
            lienholderName: lineholderName,
            lienholderAddress: lineholderAddress,
        })

        onSubmitTransfer(newOwner)
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-transfer-owner'
        >   
            <div className='modal-body'>
                <div className="modal-header">
                    <h2>Transfer Owner</h2>
                    <button className='close' onClick={toggleModal}>
                        <img src={greyX} alt="Close"/>
                    </button>
                </div>
                <form id='transfer-owner' onSubmit={onSubmit}>
                    <Row>
                        <Col xs={12} md={3}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Owner Type*
                                    </div>
                                    <ButtonGroup>
                                        <Button 
                                            onClick={() => setOwnerType('Individual')}
                                            className="form-button"
                                            active={ownerType==='Individual'}
                                        >
                                            Individual
                                        </Button>
                                        <Button
                                            onClick={() => setOwnerType('Business')}
                                            className="form-button"
                                            active={ownerType==='Business'}
                                        >
                                            Business
                                        </Button>
                                    </ButtonGroup>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        First Name*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter First Name'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Last Name*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Last Name'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Phone
                                    </div>
                                    <PatternFormat
                                        type='tel'
                                        format='(###) ###-####'
                                        mask='_'
                                        value={phone}
                                        onValueChange={value => setPhone(value.formattedValue)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Email
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Address
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        ZIP
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter ZIP'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        City
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        State
                                    </div>
                                    <Select 
                                        options={stateLabels}
                                        value={state}
                                        onChange={handleStateChange}
                                        id='selectStates'

                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Lienholder Name
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Lienholder Name'
                                        value={lineholderName}
                                        onChange={(e) => setLineholderName(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Lienholder Address
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Lienholder Address'
                                        value={lineholderAddress}
                                        onChange={(e) => setLineholderAddress(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
            <div className='modal-footer'>
                <Button
                    className="footer__cancel-button"
                    onClick={() => toggleModal()}
                >
                    <div className="vertical-align-div">
                        <span>Cancel</span>
                    </div>
                </Button>

                <Button
                    className="footer__save-button"
                    form='transfer-owner'
                    type='submit'
                >
                    <div className="vertical-align-div">
                        <span>Save Changes</span>
                    </div>
                    <div className="vertical-align-div">
                        <img src={save} alt="Save"/>
                    </div>
                </Button>
            </div>
        </Modal>
    );
};

export default TransferContractOwnerModal;