import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// assets
import wmsTitle from '../../images/wms-title.svg';
// services
import TokenService from '../../services/TokenService';
// Components
import AuthenticationForm from './AuthenticationForm';
import UserService from '../../services/UserService';
import Notifications from '../Notifications';

function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = () => {
        const user_id = jwt_decode(TokenService.getLocalAccessToken()).userId
        UserService.getUser(user_id).then(response => {
            localStorage.setItem('userId', response.id)
            localStorage.setItem('role', response.role)
            navigate('/contracts')
        }, error => {
            console.log(error)
        })
        
    };

    // clear location.state on refresh to check for notifications
    window.history.replaceState({}, document.title)

    // if user received an email or got a password reset, send them a notification
    useEffect(() => {
        if (location.state === 'email') {
            Notifications('success', 'Password reset instructions have been sent to your email.');
        } else if (location.state === 'password') {
            Notifications('success', 'Your password has been changed.')
        }
    }, [location.state])

    return (
        <React.Fragment>
            <div className='content-container'>
                <div className='login-content'>
                    <div className='login__title'>
                        <img src={wmsTitle} width='200rem' alt="Logo"/>
                    </div>
                    <h1>Sign in to get started!</h1>
                    <AuthenticationForm onSubmit={onSubmit}/>
                </div>
                <div className="login-background hide-for-mobile hide-for-tablet">
                    
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginPage;