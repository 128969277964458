
export const set_user_role = (role = null) => ({
    type: 'SET_USER_ROLE',
    role
});

export const set_user_email = (email = '') => ({
    type: 'SET_USER_EMAIL',
    email
});

export const set_user_page = (page = 1) => ({
    type: 'SET_USER_PAGE',
    page
});

