import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button
} from 'reactstrap';

// services
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// actions
import { set_header } from '../../actions/header';

// components
// import UsersDetailsSummary from './UsersDetailsSummary';
import UserHistoryPage from './UserHistoryPage';
import UserForm from './UserForm';
import UserDealersPage from './UserDealersPage';
import Notifications from '../Notifications';
import LoadingPage from '../LoadingPage';

function UserInfoPage({ set_header }) {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab'))
    const [successful, setSuccessful] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const navigate = useNavigate();
    const userRole = UserService.getUserRole();

    const availableRoles = React.useMemo(
        () => {
            switch (userRole) {
                case 'SUPERADMIN':
                    return [
                        { value: "SUPERADMIN", label: "Super Admin" },
                        { value: "METROTECH_ADMIN", label: "MetroTech Admin" },
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                        { value: "DEALERSHIP_ACCOUNTING", label: "Dealership Accounting" },
                        { value: "DEALERSHIP_ADMIN", label: "Dealership Admin" }
                    ];
                case 'METROTECH_ADMIN':
                    return [
                        { value: "METROTECH_ADMIN", label: "MetroTech Admin" },
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                        { value: "DEALERSHIP_ACCOUNTING", label: "Dealership Accounting" },
                        { value: "DEALERSHIP_ADMIN", label: "Dealership Admin" }
                    ]
                case 'METROTECH_MANAGER':
                    return [
                        { value: "METROTECH_MANAGER", label: "MetroTech Manager"},
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                    ]
                case 'METROTECH_TERRITORY_MANAGER':
                    return [
                        { value: "METROTECH_TERRITORY_MANAGER", label: "MetroTech Territory Manager"},
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" },
                    ]
                case 'DEALERSHIP_ADMIN':
                    return [
                        { value: "DEALERSHIP_FI", label: "Dealership F&I" }
                    ]
                default: 
                    return []
            }
        }
    )

    useEffect(() => {

        if (tab === null) {
            setTab('info')
            navigate(`/users/${id}?tab=info`, { replace: true })
        }
        

        UserService.getUser(id).then(response => {

            if (response === '') {
                navigate('/users')
                return
            }

            setSelectedUser(response)
            setSuccessful(true)

            if (availableRoles.find(r => r['value'] === response.role)) {
                setCanEdit(true)
            }
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })
    }, [id, navigate]);

    useEffect(() => {
        if (selectedUser.name && selectedUser.surname) {
            set_header('Users', `${selectedUser.name} ${selectedUser.surname}`)
        }
    }, [selectedUser, set_header])

    const formValidation = (user) => {
        const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        
        if (user['email'] === "") {
            return false;
        } else if (!user['email'].match(validEmailRegex)) {
            return false;
        } else if (user['name'] === "") {
            return false;
        } else if (user['surname'] === "") {
            return false;
        } else if (user['role'] === null || user['role'] === undefined) {
            return false;
        }

        return true;
    }

    const handleTabSwitch = (nextTab) => {
        setTab(nextTab)
        navigate(`/users/${id}?tab=${nextTab}`)
    };

    const onSubmit = (updatedUser) => {
        if (formValidation(updatedUser)) {
            UserService.updateUser(
                updatedUser
            ).then(response => {
                Notifications('success', 'User info has been updated.')
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            });
        }
    };

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/users'>
                                <Button>
                                    <img src={leftChevron} alt="Back"/>
                                </Button>
                            </Link>
                        </div>
                        <div className='details-page__menu'>
                            <button 
                                className={tab==='info' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('info')}
                            >
                                Info
                            </button>
                            {
                                (userRole !== 'DEALERSHIP_FI' || userRole !== 'DEALERSHIP_ACCOUNTING') && (
                                    <button 
                                        className={tab==='dealers' ? 'filter-buttons active' : 'filter-buttons'}
                                        onClick={() => handleTabSwitch('dealers')}
                                    >
                                        Dealers
                                    </button>
                                )
                            }
                            {
                                userRole === 'SUPERADMIN' &&
                                <button 
                                    className={tab === 'history' ? 'filter-buttons active' : 'filter-buttons'}
                                    onClick={() => handleTabSwitch('history')}
                                >
                                    History
                                </button>
                            }
                        </div>
                    </div>
                </div>
                
                {
                    tab === 'info' && 
                    <React.Fragment>
                        <div className="content-row flex-grow">
                            <Row>
                               {/* <Col xs={12} md={12} lg={4}>
                                    <UsersDetailsSummary 
                                        user={selectedUser}
                                    />
                                </Col>*/}
                                <Col xs={12} md={12} lg={12}>
                                    <UserForm
                                        user={selectedUser}
                                        onSubmit={onSubmit}
                                        editing={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="content-row footer">
                            <Link to='/users'>
                                <Button
                                    className="footer__cancel-button"
                                >
                                    <div className="vertical-align-div">
                                        <span>Cancel</span>
                                    </div>
                                </Button>
                            </Link>

                            <Button
                                className="footer__save-button"
                                form='user-form'
                                type='submit'
                                disabled={!canEdit}
                            >
                                <div className="vertical-align-div">
                                    <span>Save Info</span>
                                </div>
                                <div className="vertical-align-div">
                                    <img src={save} alt="Save"/>
                                </div>
                            </Button>
                        </div>

                    </React.Fragment>
                }
                
                {
                    tab === 'dealers' &&
                        <UserDealersPage 
                        />
                }
                {
                    tab === 'history' &&
                        <UserHistoryPage />
                }
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(UserInfoPage);