import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import Notifications from '../Notifications';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import LoadingPage from '../LoadingPage';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import UserService from '../../services/UserService';

// images
import blueX from '../../images/blue-x.svg';
import plus from '../../images/plus.svg';

function DealerUsersPage(props) {

    const navigate = useNavigate();
    const { id } = useParams();
    const [successful, setSuccessful] = useState('');

    const [selectedDealer, setSelectedDealer] = useState({});
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [removedUser, setRemovedUser] = useState({});
    const [removeConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState(false)

    let availableUsers = allUsers.filter(u => {
        return !assignedUsers.some(au => au['id'] === u['id'])
    })

    
    // DEALERS NOT RETURNED FROM GET ALL USERS API CALL, SHOULD HAVE EUGENE ADD THIS SO DONT NEED TO CALL EVERY TIME
    useEffect(() => {
        // on refresh page, users global state needs to be re-populated
        DealerService.getDealer(id).then(response => {
            setSuccessful(true)
            setSelectedDealer(response.data)
            setAssignedUsers(response.data.users)
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })

        UserService.getAllUsers()
            .then(response => {
                setSuccessful(true)
                setAllUsers(response.data)
            },
            error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            });

    }, [id, navigate])

    const handleAddLink = (user) => {
        const dealerPayload = {
            dealerId: selectedDealer.id,
            userIds: [user.id]
        }
        DealerService.linkUserToDealer(dealerPayload)
            .then(response => {
                setAssignedUsers(prev => ([
                    ...prev,
                    user
                ]))
                Notifications("success", "User has been added.")
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
    };

    const handleAddAll = () => {
        const userIds = assignedUsers.map(user => user.id)

        const restUsers = availableUsers.map(user => user.id)

        const dealerPayload = {
            dealerId: selectedDealer.id,
            userIds: [
                ...userIds,
                ...restUsers
            ]
        }

        DealerService.linkUserToDealer(dealerPayload)
            .then(response => {
                setAssignedUsers(allUsers)
                Notifications('success', 'All users have been added.')
            }).catch(e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            })
    };

    const toggleRemoveConfirmationModal = () => {
        setRemoveConfirmationModalOpen(current => {
            return !current
        })
    };

    const handleDeleteConfirmation = (removedUser) => {
        setRemovedUser(removedUser)
        setRemoveConfirmationModalOpen(true)
    };

    const onSubmitRemoveConfirmationModal = (message) => {

        if (message === 'yes') {
            const newUsers = assignedUsers.filter(user => removedUser !== user)

            const dealerPayload = {
                dealerId: selectedDealer.id,
                userIds: [removedUser.id]
            }

            DealerService.unlinkUserFromDealer(dealerPayload)
                .then(response => {
                    setAssignedUsers(newUsers)
                    toggleRemoveConfirmationModal()
                    Notifications("success", "User has been removed.")
                }).catch(err => {
                    if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", err['message'])
                })
        } else {
            toggleRemoveConfirmationModal()
        }
    };

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className="details-page__form" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <h2>Appointment of Users</h2>
                        <button
                            disabled={availableUsers.length === 0}
                            onClick={() => handleAddAll()}
                            className="form-button add-all__button"
                        >
                            Add All
                        </button>
                    </div>
                   
                    <div className="assigned-dealers__div">
                        {
                            assignedUsers.sort((a, b) => a['name'].localeCompare(b['name'])).map(user => {
                                return (
                                    <button
                                        onClick={() => handleDeleteConfirmation(user)}
                                        className="form-button assigned-dealers__button"
                                        key={user.id}
                                    >   
                                        <span>
                                            {user.email}
                                        </span>
                                        <div>
                                            <img src={blueX} alt="Remove"/>
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div>
                    <hr/>
                    <div className="available-dealers__div">
                        {
                            availableUsers.sort((a, b) => a['name'].localeCompare(b['name'])).map(user => {
                                return (
                                    <button
                                        key={user.id}
                                        className="form-button available-dealers__button"
                                        onClick={() => handleAddLink(user)}
                                    >
                                        <div>
                                            <img src={plus} alt="Add"/>
                                        </div>
                                        <span>
                                            {user.email}
                                        </span>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <DeleteConfirmationModal
                isOpen={removeConfirmationModalOpen}
                toggleModal={toggleRemoveConfirmationModal}
                onSubmitDeleteConfirmationModal={onSubmitRemoveConfirmationModal}
                message={"Confirm remove user?"}
            />
        </React.Fragment>
    );
};

export default DealerUsersPage;