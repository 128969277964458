import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Input
} from 'reactstrap';
import Select from 'react-select';
import { 
    useTable, 
    usePagination, 
    useExpanded,
    useRowSelect
  } from 'react-table';
import Numeral from 'numeral';

// images
import greyX from '../../images/grey-x.svg';
import gear from '../../images/gear.svg';
import trash from '../../images/trash.svg';

// services
import AuthService from '../../services/AuthService';
import PricingService from '../../services/PricingService';
import AdditionalItemsService from '../../services/AdditionalItemsService';

// Components
import Notifications from '../Notifications';

function EditPricingPositionModal({ isOpen, toggleMenu, pricingPosition, items }) {

    const navigate = useNavigate();
    const calcPageSize = 1000;
    const [fee, setFee] = useState('');

    const [selectedPricingPosition, setSelectedPricingPosition] = useState(pricingPosition);
    const [additionalItems, setAdditionalItems] = useState([]);

    const [accountingItemCode, setAccountingItemCode] = useState('')
    const [internalPrice, setInternalPrice] = useState('')

    const [pricingData, setPricingData] = useState({
        productID: '',
        title: '',
        bvProductCode: '',
        configID: '', 
        contract: '',
        valid: '',
        price: 0,
        term: '',
        dealerPrice: 0
    })

    const [deletedItems, setDeletedItems] = useState([]);
    const [addedItems, setAddedItems] = useState([]);

    const [additionalItemCode, setAdditionalItemCode] = useState(null);
    const [additionalItemCodeLabels, setAdditionalItemCodeLabels] = useState([])

    // select options
    useEffect(() => {
        AdditionalItemsService.getAdditionalItems()
            .then(response => {
                setAdditionalItemCodeLabels(response.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.description} - ${item.code}`,
                        description: item.description,
                        code: item.code
                    }
                }).sort((a, b) => a['label'].localeCompare(b['label'])))
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch additional items.')
            })
    }, [])

    useEffect(() => {
        setFee('')
        setAdditionalItemCode(null)
        setAddedItems([])
        setDeletedItems([])
        
        if (pricingPosition.id) {
            setSelectedPricingPosition(pricingPosition)

            setPricingData({
                productID: '1',
                title: pricingPosition.title,
                bvProductCode: pricingPosition.bvcode,
                configID: '1', 
                contract: pricingPosition.contractLetter,
                valid: pricingPosition.valid ? 'Yes' : 'No',
                price: pricingPosition.price,
                dealerPrice: pricingPosition.price[2],
                term: pricingPosition.term
            })

            setAccountingItemCode(pricingPosition.bvcode)
            setInternalPrice(pricingPosition.price[0])
        }
    }, [pricingPosition])

    useEffect(() => {
        setAdditionalItems(items.map(item => (
            {
                id: item.id,
                additionalItemCode: item.additionalItemCode.code,
                additionalItemDescription: item.additionalItemCode.description,
                fee: item.fee,
                edit: <button 
                    onClick={() => handleRemoveLink(item)}
                    className='button-no-background'
                  >
                    <img src={trash} alt="Delete"/>
                </button>,
                new: false
            }
        )))
    }, [items]) 

    const additionalItemData = additionalItems.map(item => (
        {
            additionalItemCode: item.additionalItemCode,
            additionalItemDescription: item.additionalItemDescription,
            fee: item.fee,
            edit: <button 
                onClick={() => handleRemoveLink(item)}
                className='button-no-background'
              >
                <img src={trash} alt="Delete"/>
            </button>
        }
    ))

    const handleAdditionalItemCodeChange = (e) => {
        setAdditionalItemCode(e)
    }

    const handleCloseModal = () => {
        toggleMenu(selectedPricingPosition)
    };

    const handleInternalPriceChange = (value) => {
        setInternalPrice(value)
        const additionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue['fee'])
        }, 0)


        setPricingData(prev => ({
            ...prev,
            price: [
                parseFloat(value),
                additionalItemsPrice,
                value === '' ? additionalItemsPrice : parseFloat(value) + additionalItemsPrice
            ]
        }))
    }

    const handleAddField = (e) => {
        e.preventDefault()

        if (additionalItemCode === {} || additionalItemCode === null) {
            Notifications("error", 'Additional Item code is required.')
        } else if (fee === '') {
            Notifications("error", 'Fee is required.')
        } else if (additionalItems.some(item => item['additionalItemCode'] === additionalItemCode['code'])) {
            Notifications("error", 'Cannot have duplicate item codes.')
        } else {
            setAddedItems(prev => ([
                ...prev,
                {
                    pricingPositionId: pricingPosition.id,
                    additionalItemCodeId: additionalItemCode.value,
                    fee: parseFloat(fee),
                    removed: false
                }
            ]))

            setAdditionalItems(prev => ([
                ...prev,
                {
                    id: additionalItemCode.value,
                    additionalItemCode: additionalItemCode.code,
                    additionalItemDescription: additionalItemCode.description,
                    fee: parseFloat(fee),
                    edit: <button
                            onClick={() => handleRemoveLink(additionalItemCode.value)}
                            className='button-no-background'
                            >
                            <img src={trash} alt="Delete"/>
                        </button>,
                    new: true
                }
            ]))

            setPricingData(prev => ({
                ...prev,
                price: [
                    prev.price[0],
                    prev.price[1] + parseFloat(fee),
                    prev.price[2] + parseFloat(fee)
                ],
            }))

            setAdditionalItemCode(null)
            setFee('')
        }
    };

    const onEditSubmit = () => {
        if (parseFloat(pricingData.dealerPrice) !== pricingData.price[2]) {
            Notifications('error', 'Total price is not equal to dealer price.')
            return
        }

        const pricingPayload = {
            id: selectedPricingPosition['id'],
            title: selectedPricingPosition['title'],
            bvcode: accountingItemCode,
            valid: selectedPricingPosition['valid'],
            removed: selectedPricingPosition['removed'],
            term: selectedPricingPosition['term'],
            price: parseFloat(internalPrice),
            contractLetter: selectedPricingPosition['contractLetter'],
        }

        // Update pricing position bv code
        PricingService.updatePricingPosition(pricingPayload)
            .then(response => {
                if (deletedItems.length > 0) {
                    const deletePricingPayload = {
                        ids: deletedItems,
                        pricingPositionId: pricingPosition['id']
                    }
                    PricingService.deletePricingPositionItem(deletePricingPayload)
                        .then(deleteResponse => {
                            if (addedItems.length > 0) {
                                PricingService.createPricingPositionItem(addedItems)
                                    .then(addResponse => {
                                        Notifications("success", "Pricing position has been updated.")

                                        const newAdditionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
                                            return accumulator + parseFloat(currentValue['fee'])
                                        }, 0)

                                        const newPricingPosition = {
                                            ...response.data,
                                            price: [
                                                parseFloat(internalPrice),
                                                newAdditionalItemsPrice,
                                                parseFloat(internalPrice) + newAdditionalItemsPrice
                                            ]
                                        }
                                        
                                        toggleMenu(newPricingPosition) 
                                    }, e => {
                                        console.log(e)
                                        if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                                            AuthService.logout()
                                            navigate('/login')
                                        } else {
                                            Notifications("error", e.message)
                                        }
                                    })
                            } else {
                                Notifications("success", "Pricing position has been updated.")

                                const newAdditionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
                                    return accumulator + parseFloat(currentValue['fee'])
                                }, 0)

                                const newPricingPosition = {
                                    ...response.data,
                                    price: [
                                        parseFloat(internalPrice),
                                        newAdditionalItemsPrice,
                                        parseFloat(internalPrice) + newAdditionalItemsPrice
                                    ]
                                }
                                
                                toggleMenu(newPricingPosition) 
                            }
                        }, e => {
                            console.log(e)
                            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                                AuthService.logout()
                                navigate('/login')
                            } else {
                                Notifications("error", e.message)
                            }
                        })
                } else {
                    if (addedItems.length > 0) {
                        PricingService.createPricingPositionItem(addedItems)
                            .then(addResponse => {
                                Notifications("success", "Pricing position has been updated.")

                                const newAdditionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
                                    return accumulator + parseFloat(currentValue['fee'])
                                }, 0)

                                const newPricingPosition = {
                                    ...response.data,
                                    price: [
                                        parseFloat(internalPrice),
                                        newAdditionalItemsPrice,
                                        parseFloat(internalPrice) + newAdditionalItemsPrice
                                    ]
                                }
                                
                                toggleMenu(newPricingPosition) 
                            }, e => {
                                console.log(e)
                                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                                    AuthService.logout()
                                    navigate('/login')
                                } else {
                                    Notifications("error", e.message)
                                }
                            })
                    } else {
                        Notifications("success", "Pricing position has been updated.")

                        const newAdditionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
                            return accumulator + parseFloat(currentValue['fee'])
                        }, 0)

                        const newPricingPosition = {
                            ...response.data,
                            price: [
                                parseFloat(internalPrice),
                                newAdditionalItemsPrice,
                                parseFloat(internalPrice) + newAdditionalItemsPrice
                            ]
                        }
                        
                        toggleMenu(newPricingPosition) 
                    }
                }
            }, e => {
                console.log(e)
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                } else {
                    Notifications("error", e.message)
                }
            })
    }

    // async function onEditSubmit() {
    //     if (parseFloat(pricingData.dealerPrice) !== pricingData.price[2]) {
    //         Notifications('error', 'Total price is not equal to dealer price.')
    //         return
    //     }

    //     const pricingPayload = {
    //         id: selectedPricingPosition['id'],
    //         title: selectedPricingPosition['title'],
    //         bvcode: accountingItemCode,
    //         valid: selectedPricingPosition['valid'],
    //         removed: selectedPricingPosition['removed'],
    //         term: selectedPricingPosition['term'],
    //         price: parseFloat(internalPrice),
    //         contractLetter: selectedPricingPosition['contractLetter'],
    //     }

    //     try {
    //         const updatedPricingPosition = PricingService.asyncUpdatePricingPosition(pricingPayload)
            
    //         let newAdditionalItemsPrice = 0

    //         const updateAllPricingPositions = () => {
    //             for (const item of additionalItems) {
    //                 if (item.new) {
    //                     const newItem = [{
    //                         pricingPositionId: pricingPosition.id,
    //                         additionalItemCodeId: item.id,
    //                         fee: item.fee.replace('$',''),
    //                         removed: false
    //                     }]

    //                     PricingService.asyncCreatePricingPositionItem(newItem)

                        

    //                     newAdditionalItemsPrice = newAdditionalItemsPrice + parseFloat(item.fee.replace('$', ''))
    //                 }
    //             }

    //         }

    //         await Promise.all([updatedPricingPosition, updateAllPricingPositions()])
    //             .then(response => {

    //                 console.log(response)

    //                 console.log(newAdditionalItemsPrice)

    //                 const newPricingPosition = {
    //                     ...response[0].data.data,
    //                     price: [
    //                         response[0].data.data.price[0],
    //                         response[0].data.data.price[1] + newAdditionalItemsPrice,
    //                         response[0].data.data.price[2] + newAdditionalItemsPrice
    //                     ]
    //                 }

    //                 console.log(newPricingPosition)
                    

    //                 toggleMenu(newPricingPosition)

    //             }, e => {
    //                 console.log(e)
    //                 if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
    //                     AuthService.logout()
    //                     navigate('/login')
    //                 } else {
    //                     Notifications("error", e.message)
    //                 }
    //             })

    //     } catch (e) {
    //         if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
    //             AuthService.logout()
    //             navigate('/login')
    //         } else {
    //             Notifications("error", e.message)
    //         }
    //         console.log(e)
    //     }
    // }

    const handleRemoveLink = (additionalItemCode) => {
        // First check if this was a newly added additional item
        if (addedItems.some(i => i['additionalItemCodeId'] === additionalItemCode['id'])) {
            // Remove from addedItems
            let tempAddedItems = addedItems.filter(i => i['additionalItemCodeId'] !== additionalItemCode['id'])

            setAddedItems(tempAddedItems)
        } else {
            // If not newly added additional item, need to remove item from pricing position
            setDeletedItems(prev => ([
                ...prev,
                additionalItemCode.id
            ]))
        }

        setAdditionalItems(prev => prev.filter(i => i['id'] !== additionalItemCode['id']))

        setPricingData(prev => ({
            ...prev,
            price: [
                prev.price[0],
                prev.price[1] - parseFloat(additionalItemCode['fee']),
                prev.price[2] - parseFloat(additionalItemCode['fee'])
            ],
        }))
    }

    const pricingColumns = React.useMemo(
        () => [
            {
                Header: 'Product Name',
                accessor: 'title',
                id: 'title',
                name: 'title',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                id: 'contract',
                name: 'contract',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Term',
                accessor: 'term',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    let tempValue = value;

                    if (value === 'life') {
                        tempValue = 'Life'
                    } else {
                        tempValue = `${value} year${parseInt(value) > 1 ? 's' : ''}`
                    }
        
                    return (
                        <div className="table-cell__div">
                            {tempValue}
                        </div>
                    );
                }
            },
            // {
            //     Header: 'Accounting Item Code',
            //     accessor: 'bvProductCode',
            //     id: 'bvProductCode',
            //     name: 'bvProductCode',
            //     Cell: ({ cell }) => {
            //         const [value, setValue] = useState(cell.value)

            //         const onChange = (e) => {
            //             setValue(e.target.value)
            //         }

            //         const onBlur = () => {
            //             updateData('bvProductCode', value)
            //         }
                        
            //         return (
            //             <div className="table-cell__div">
            //                 <Input 
            //                     type='text'
            //                     placeholder=''
            //                     value={value}
            //                     className='input input__form'
            //                     onChange={onChange}
            //                     onBlur={onBlur}
            //                 />
            //             </div>
            //         );
            //     }
            // },
            {
                Header: 'Total Price',
                accessor: 'price',
                Cell: ({ cell }) => {
                    let total = cell.value[2];

                    return (
                        <div className="table-cell__div">
                            <div className="badge badge__terms">
                                {Numeral(total).format("$0,0.00")}
                            </div>
                        </div>
                    );
                }
            },
            {
                Header: 'Dealer Price',
                accessor: 'dealerPrice',
                Cell: ({ cell }) => {

                    const [value, setValue] = useState(cell.value)

                    const onChange = (e) => {
                        setValue(e.target.value)
                    }

                    const onBlur = () => {
                        updateData('dealerPrice', value)
                    }

                    return (
                        <div className="table-cell__div">
                            <Input 
                                type='number'
                                placeholder=''
                                value={value}
                                className='input input__form'
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </div>
                    );
                }
            },
        ], []
      )

      const itemColumns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'additionalItemCode',
                id: 'additionalItemCode',
                name: 'additionalItemCode',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Description',
                accessor: 'additionalItemDescription',
                id: 'additionalItemDescription',
                name: 'additionalItemDescription',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Fee',
                accessor: 'fee',
                id: 'fee',
                name: 'fee',
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div">
                            {Numeral(value).format("$0,0.00")}
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Actions"/>,
                accessor: 'edit',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], []
    )

    const updateData = (prop, value) => {
        // setSkipPageReset(true)
        setPricingData(prev => ({
            ...prev,
            [prop]: value
        }))
    }
    
    function Table({ columns, data, updateData, flexEnd }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page
        } = useTable(
            {
                columns,
                data,
                initialState: { 
                    defaultPageSize: 1000,
                    pageSize: calcPageSize,
                    sortBy: [
                        {
                            id: 'code',
                            desc: false
                        }
                    ]
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
                updateData
            },
            useExpanded,
            usePagination,
            useRowSelect
        )
    
      
        // Render the UI for your table
        return (
            <React.Fragment>
                <div className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={`table-header ${column.canFilter && 'filterable'} ${flexEnd && 'flex-end'}`} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {column.render('Header')}
                                                    </div>
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()} className={`${flexEnd && 'flex-end'}`}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleCloseModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className='modal-pricing-position'
            >
                <div className='modal-body'>
                    <div className='modal-header'>
                        <h2><b>Pricing Position</b></h2>
                        <button className='close' onClick={handleCloseModal}>
                            <img src={greyX} alt="Close"/>
                        </button>
                    </div>
                    <div className='table mini'>
                        <Table columns={pricingColumns} data={[pricingData]} updateData={updateData} flexEnd={false}/>
                    </div>
                    <hr/>
                    <h2><b>Accounting Item</b></h2>
                    <div className="group">
                        <div className="label-group">
                            <div className="vertical-align-div">
                                <span>Item Code:</span>
                            </div>
                            <Input 
                                type='text'
                                placeholder=''
                                value={accountingItemCode}
                                className='input input__form'
                                onChange={(e) => setAccountingItemCode(e.target.value)}
                             />
                        </div>
                        <div className="label-group">
                            <div className="vertical-align-div">
                                <span>Price:</span>
                            </div>
                            <Input 
                                type='number'
                                placeholder=''
                                value={internalPrice}
                                className='input input__form'
                                onChange={(e) => handleInternalPriceChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <hr/>
                    <h2><b>Additional Items</b></h2>
            
                    <form id='add-additional-items' className="additional-items-form" onSubmit={handleAddField}>
                        <div className="group">
                            <div className="vertical-align-div">
                                <span>Item Code:</span>
                            </div>
                            <Select 
                                options={additionalItemCodeLabels}
                                value={additionalItemCode} 
                                onChange={handleAdditionalItemCodeChange}
                                className="input-select-container"
                            />
                        </div>
                        <div className="group">
                            <div className="vertical-align-div">
                                <span>Fee:</span>
                            </div>
                            <Input 
                                type='number'
                                placeholder=''
                                value={fee}
                                className='input input__form'
                                onChange={(e) => setFee(e.target.value)}
                            />
                        </div>
                        <button
                            form='add-additional-items'
                            className='link__form-add'
                        >
                            <span>Add</span>
                        </button>
                    </form>

                    <div className="table mini overflow">
                        <Table columns={itemColumns} data={additionalItemData} flexEnd={true}/>
                    </div>
                </div>
                <div className='modal-footer'>
                    {
                        parseFloat(pricingData.dealerPrice) !== pricingData.price[2] ?
                            <React.Fragment>
                                <span style={{color: 'red', marginRight: '10px'}}>
                                    Total price is not equal to dealer price.
                                </span>
                                <button
                                    className='link__form-add disabled'
                                    disabled={true}
                                >
                                    Submit
                                </button>
                            </React.Fragment>
                            :
                            <button
                                className='link__form-add'
                                onClick={onEditSubmit}
                            >
                                Submit
                            </button>
                    }
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default EditPricingPositionModal;