import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Button
} from 'reactstrap';
import moment from 'moment';

// components
import Notifications from '../Notifications';
import ApproveContractModal from './ApproveContractModal';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import ContractForm from './ContractForm';
import LoadingPage from '../LoadingPage';
import ContractHistoryPage from './ContractHistoryPage';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// services
import AuthService from '../../services/AuthService';
import ContractService from '../../services/ContractService';
import UserService from '../../services/UserService';

// actions
import { set_header } from '../../actions/header';

function getAbsoluteMonths(momentDate) {
    var months = Number(momentDate.format("MM"));
    var years = Number(momentDate.format("YYYY"));
    return months + (years * 12);
}

function ContractInfoPage({ set_header }) {
    const [allowCancel, setAllowCancel] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);
    const { id } = useParams();
    const [successful, setSuccessful] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab'))
    const [override, setOverride] = useState(false)

    const userRole = UserService.getUserRole(); 

    const [approveContractModalOpen, setApproveContractModalOpen] = useState(false)
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)

    const [selectedContract, setSelectedContract] = useState({});

    const navigate = useNavigate();



    useEffect(() => {

        if (tab === null) {
            setTab('info')
            navigate(`/contracts/${id}?tab=info`, { replace: true })
        }



        ContractService.getContract(id)
                .then(response => {

                    if (response.data === null) {
                        navigate('/contracts')
                        return
                    }
                    
                    setSelectedContract(response.data);

                    // If activationDate is NOT in current month OR last month, do not allow them to cancel
                    const currentDate = new Date()
                    const activationDate = moment(response.data.activationDate).utc()

                    const currentDateMonths = getAbsoluteMonths(moment(currentDate).utc())
                    const activationDateMonths = getAbsoluteMonths(activationDate)

                    const monthDiff = currentDateMonths - activationDateMonths

                    // NEEDS TO BE UPDATED FOR SPECIFY CONTRACT STATUSES
                    if (userRole !== 'DEALERSHIP_FI' && response.data.status !== 'CANCELLED' && (userRole === 'SUPERADMIN' || monthDiff < 2)) {
                        setAllowCancel(true)
                    }
                    if (userRole !== 'METROTECH_MANAGER' && userRole !== 'METROTECH_TERRITORY_MANAGER' && userRole !== 'DEALERSHIP_FI') {
                        setAllowEdit(true)
                    }

                    if (searchParams.get('override')) {

                        if (userRole === 'SUPERADMIN') {
                            if (response.data.status === 'PENDING') {
                                setOverride(true)
                            } else {
                                Notifications('info', `Contract is already ${response.data.status.split('_').join(' ')}`)
                            }
                        }

                        navigate(`/contracts/${id}?tab=info`)

                    }

                    setSuccessful(true)

                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    setSuccessful(false)
                })

    }, [id, navigate, userRole]);



    useEffect(() => {
        set_header('Contracts', selectedContract.id)
    }, [selectedContract, set_header])

    const formValidation = (owner) => {
        if (owner.ownerType === "") {
            return false
        }
        if (owner.firstName === "") {
            return false
        }
        if (owner.lastName === "") {
            return false
        }

        return true
    }


    const onSubmit = (updatedContract) => {
        if (formValidation(JSON.parse(updatedContract.owner))) {
            ContractService.updateContractOwner(
                {
                    id: id,
                    owner: updatedContract.owner
                }
            ).then(response => {
                Notifications('success', 'Contract owner info has been updated.')
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                console.log(e)
            })
        }
    };

    const handleTabSwitch = (nextTab) => {
        setTab(nextTab)
        navigate(`/contracts/${id}?tab=${nextTab}`)
    };

    const toggleDeleteConfirmationModal = () => {
        setDeleteConfirmationModalOpen(current => {
            return !current
        })
    };


    const handleDeleteContract = () => {
        setDeleteConfirmationModalOpen(true)
    };

    const onSubmitDeleteConfirmationModal = (action, comments) => {
        if (action === 'yes') {
            ContractService.updateContractStatus({
                id: id,
                action: 'cancel'
            })
            .then(response => {
                
                navigate('/contracts')
                toggleDeleteConfirmationModal()
                Notifications('success', 'Contract successfully cancelled.')

            }, e => {
                console.log(e)
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            })

        } else {
            toggleDeleteConfirmationModal()
        }
    }; 

    const toggleApproveContractModal = () => {
        setApproveContractModalOpen(current => {
            return !current
        })
    };


    const handleApproveContract = (message) => {
        if (message === 'success') {
            ContractService.updateContractStatus({
                id: id,
                action: 'approve'
            })
            .then(response => {
                Notifications("success", 'Contract has been approved.')
                navigate('/contracts')
            }, e => {
                if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", e['message'])
            })
        } else {
            toggleApproveContractModal()
        }
        
    }


    // api call unsuccessful
    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    }

    return (        
        <React.Fragment>
            <div className="content">
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/contracts'>
                                <Button>
                                    <img src={leftChevron} alt="Back"/>
                                </Button>
                            </Link>
                        </div>
                        {
                            override && (
                                <div className='contracts-override'>
                                    <button
                                        onClick={() => handleDeleteContract()}
                                        className='override-cancel__button'
                                    >
                                        Cancel Contract
                                    </button>
                                    <button
                                        onClick={() => handleApproveContract()}
                                        className='override-approve__button'
                                    >
                                        Approve Contract
                                    </button>
                                </div>
                            )
                        }
                        <div className='details-page__menu'>
                            <button 
                                className={tab === 'info' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('info')}
                            >
                                Info
                            </button>
                            {
                                userRole === 'SUPERADMIN' &&
                                <button 
                                    className={tab === 'history' ? 'filter-buttons active' : 'filter-buttons'}
                                    onClick={() => handleTabSwitch('history')}
                                >
                                    History
                                </button>
                            }
                        </div>
                    </div>
                </div>

                {
                    tab === 'info' &&
                        <React.Fragment>
                            <div className="content-row flex-grow">
                                <ContractForm
                                    contract={selectedContract}
                                    onSubmit={onSubmit}
                                />
                            </div>
                            <div className="content-row contracts-footer">
                                {
                                    allowCancel && !override ? 
                                        <div>
                                            <Button
                                                onClick={() => handleDeleteContract()}
                                                className='footer__cancel-contract'
                                            >
                                                <div className='vertical-align-div'>
                                                    <span>Cancel Contract</span>
                                                </div>
                                            </Button>
                                        </div>
                                    :
                                        <div>
                                        </div>
                                }
                                <div>
                                    <Link to='/contracts'>
                                        <Button
                                            className="footer__cancel-button"
                                        >
                                            <div className="vertical-align-div">
                                                <span>Back</span>
                                            </div>
                                        </Button>
                                    </Link>
                                    {
                                        allowEdit &&
                                            <Button
                                                className="footer__save-button"
                                                form='contract-form'
                                                type='submit'
                                            >
                                                <div className="vertical-align-div">
                                                    <span>Save Changes</span>
                                                </div>
                                                <div className="vertical-align-div">
                                                    <img src={save} alt="Save"/>
                                                </div>
                                            </Button>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                }
                {
                    tab === 'history' &&
                        <ContractHistoryPage />
                }
            </div>

            <ApproveContractModal
                isOpen={approveContractModalOpen}
                toggleApproveContractModal={toggleApproveContractModal}
                onSubmitApproveContractModal={handleApproveContract}
                contractId={id}
            />

            <DeleteConfirmationModal
                isOpen={deleteConfirmationModalOpen}
                toggleModal={toggleDeleteConfirmationModal}
                message={"Do you want to cancel this contract?"}
                onSubmitDeleteConfirmationModal={onSubmitDeleteConfirmationModal}
            />

        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(ContractInfoPage);