import React from 'react';
import Modal from 'react-modal';

// images
import greyX from '../images/grey-x.svg';

function DeleteConfirmationModal({ message, isOpen, toggleModal, onSubmitDeleteConfirmationModal }) {
 
    const onSubmit = (msg, text) => {
        onSubmitDeleteConfirmationModal(msg, text)
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-delete'
        >   
            <div className='modal-header'>
                <h2><b>{message}</b></h2>
                <button className='close' onClick={toggleModal}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <div className="modal-footer">
                <button
                    onClick={() => onSubmit('yes')}
                    className='link__form-add'
                >
                    Yes
                </button>
                <button
                    onClick={() => onSubmit('no')}
                    className='link__form-add'
                >
                    No
                </button>
            </div>
        </Modal>
    );
};

export default DeleteConfirmationModal;