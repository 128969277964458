import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
// actions
import { set_dealer_page } from '../../actions/dealersFilters'

const DealersPagination = ({ set_dealer_page, dealersFilters, handlePageChange, totalPages, filters, applyResetTable }) => {
    
    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    const [page, setPage] = useState(dealersFilters.page);

    // cant go next on last page, cant go back on first page
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);
    const [initialRender, setInitialRender] = useState(true)

       
    // initial render
    useEffect(() => {
        setInitialRender(false)
    }, [])

    //  set the starting index of the page
    useEffect(() => {
        if (!initialRender) {
            handlePageChange(page)
            set_dealer_page(page)
        }
    }, [page])

    // reset the page number when the vin search filter is applied
    useEffect(() => {
        if (!initialRender) {
            setPage(1)
        }
    }, [filters, applyResetTable])


    useEffect(() => {
        if (totalPages === page) {
          setCanGoNext(false);
        } else {
          setCanGoNext(true);
        }
        if (page === 1) {
          setCanGoBack(false);
        } else {
          setCanGoBack(true);
        }
      }, [totalPages, page]);

    const range = (start, end) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start)
    }

    // Onclick handlers for the buttons
    const onNextPage = () => {
        setPage(prev => prev + 1)
    }

    const onPrevPage = () => {
        setPage(prev => prev - 1)
    }

    const onPageSelect = (pageNo) => {
        setPage(pageNo)
    }

    const currentPageIndex = page
    const siblingCount = 1
    const totalPageNumbers = siblingCount + 5
    let customRange;

    /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPages) {
        customRange = range(1, totalPages)
    }

    /*
        Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPageIndex - siblingCount, 1);
    const rightSiblingIndex = Math.min(
        currentPageIndex + siblingCount,
        totalPages
    );

    /*
        We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    /*
        Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 3 + 2 * siblingCount;
        let leftRange = range(1, leftItemCount);

        customRange = [...leftRange, '...', totalPages];
    }

    /*
        Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
        let rightItemCount = 3 + 2 * siblingCount;
        let rightRange = range(
            totalPages - rightItemCount + 1,
            totalPages
        );
        customRange = [firstPageIndex, '...', ...rightRange];
    }

    /*
        Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        customRange = [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
    }

    return (
        <div className="pagination-div">
            { 
                <div className="pageButtons">
                    <div className="vertical-align-div">
                        <Button 
                            className='pageBtn'
                            disabled={!canGoBack}
                            onClick={onPrevPage}
                        >
                            &lt;
                        </Button>
                    </div>
                    {
                        customRange.map((i, idx) => {
                            if (i === '...') {
                                return (
                                    <div className="vertical-align-div" key={`dealers-page-index-${idx}`}>
                                        &#8230;
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="vertical-align-div" key={`dealers-page-index-${idx}`}>
                                        <Button 
                                            className={`pageBtn ${i === page && 'activeBtn'}`}
                                            onClick={() => onPageSelect(i)}
                                        >
                                            {i}
                                        </Button>
                                    </div>
                                )
                            }
                        })
                    }
                    <div className="vertical-align-div">
                        <Button 
                            className='pageBtn'
                            disabled={!canGoNext}
                            onClick={onNextPage}
                        >
                            &gt;
                        </Button>
                    </div>
                </div>
            }
        </div>  
    )
};

const mapStateToProps = (state) => {
    return {
        dealersFilters: state.dealersFilters
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_dealer_page: (page) => dispatch(set_dealer_page(page))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(DealersPagination);