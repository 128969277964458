import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

// images
import greyX from '../../../images/grey-x.svg';

// components
import Notifications from '../../Notifications';

// services
import AuthService from '../../../services/AuthService';
import ProductTypeService from '../../../services/ProductTypeService';


function AddProductTypeModal({ isOpen, toggleMenu }) {
    const navigate = useNavigate();

    const [productType, setProductType] = useState('')

    useEffect(() => {
        setProductType('')
    }, [isOpen])

    const handleSaveClick = () => {
        let validated = true;

        if (productType === "") {
            Notifications("error", "Product type is required.")
            validated = false;
        }

        if (validated) {

            const newProductType = {
                name: productType
            }

            ProductTypeService.createProductType(
                newProductType
            ).then(response => {
                Notifications('success', 'Product type has been created.')
                toggleMenu(response.data)
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => toggleMenu()}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-territory'
        >   
            <div className='modal-body'>
                <div className='modal-header'>
                    <h2><b>Add Product Type</b></h2>
                    <button className='close' onClick={() => toggleMenu()}>
                        <img src={greyX} alt="Close"/>
                    </button>
                </div>
                <div className="details">
                    <div>
                        <span>
                            Product Type
                        </span>
                        <input 
                            type='text'
                            placeholder='Product Type'
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            className='input input__form'
                        />
                    </div>

                </div>
            </div>
            <div className="modal-footer">
                <button
                    className='link__form-add'
                    onClick={handleSaveClick}
                >
                    Save
                </button>
            </div>
        </Modal>
    );
};

export default AddProductTypeModal;