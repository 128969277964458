import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// components
import Notifications from '../Notifications';
// calendar libraries
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// services
import DealerService from '../../services/DealerService';
import TerritoryService from '../../services/TerritoryService';
// other
import moment from 'moment';
import Select from 'react-select';
// icons
import greyX from '../../images/grey-x.svg';

function DealerFiltersModal({ 
    dealersFilters, filtersModalOpen, handleFiltersModalClose, handleSubmitFilters, applyResetTable 
}) {

    const initializeDealerLabel = () => {
        if (dealersFilters.dealer !== null) {
            return {
                value: dealersFilters.dealer.name,
                label: dealersFilters.dealer.name
            }
        } else {
            return null
        }
    };

    const [initialRender, setInitialRender] = useState(true)
    // dealer name
    const [dealer, setDealer] = useState(dealersFilters.dealer);
    const [dealerLabel, setDealerLabel] = useState(() => initializeDealerLabel())

    const [allDealers, setAllDealers] = useState([]);
    const [allDealersLabel, setAllDealersLabel] = useState([]);
    // dealer group
    const [dealerGroup, setDealerGroup] = useState(dealersFilters.dealerGroup);
    const [dealerGroupLabels, setDealerGroupLabels] = useState([]);
    // dealer territory
    const [territory, setTerritory] = useState(dealersFilters.dealerTerritory);
    const [territoryLabels, setTerritoryLabels] = useState([]); 


    useEffect(() => {

        setInitialRender(false)

        // get dealer names
        DealerService.getAllDealers().then(response => {
            setAllDealers(response.data)
            setAllDealersLabel(response.data.map((dealer) => {
                return {
                    value: dealer.name,
                    label: dealer.name
                }
            }).sort((a, b) => a['label'].localeCompare(b['label'])))
        }).catch(err => {
            Notifications("error", err['message'])
        })

        // get dealer groups
        DealerService.getAllDealerGroups()
            .then(response => {
                setDealerGroupLabels(response.data.map((group) => {
                    return {
                        value: group.id,
                        label: group.name
                    }
                }))
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch dealer groups list.')
            })


        // get dealer territories
        TerritoryService.getTerritory()
            .then(response => {
                setTerritoryLabels(response.data.map((territory) => {
                    return {
                        value: territory.id,
                        label: territory.code
                    }
                }).sort((a, b) => a['label'].localeCompare(b['label'])))
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch territories list.')
            })

    }, [])

    useEffect(() => {
        if (!initialRender) {
            setDealer(null)
            setDealerLabel(null)
            setDealerGroup(null) 
            setTerritory(null)
        }
    }, [applyResetTable])


    const handleDealerChange = (dealerLabel) => {
        setDealerLabel(dealerLabel)
        const selectedDealer = allDealers.find((dealer) => dealer.name === dealerLabel.value)
        setDealer(selectedDealer)
    }

    const handleDealerGroupChange = (dealerGroup) => {
        setDealerGroup(dealerGroup)
    }

    const handleTerritoryChange = (territory) => {
        setTerritory(territory)
    };

    const onSubmit = () => {
        const filters = {
            dealerFilter: dealer,
            dealerGroupFilter: dealerGroup,
            dealerTerritoryFilter: territory
        }
        handleSubmitFilters(filters)

    }; 

    return (
        <Modal
            isOpen={filtersModalOpen}
            onRequestClose={handleFiltersModalClose}
            closeTimeoutMS={200}
            ariaHideApp={false}
            className='modal-contract__action'
        >
            <div className="modal-header">
                <h2>Filters</h2>
                <button className="close" onClick={handleFiltersModalClose}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <div>
                <span>Dealer:</span>
                <Select 
                    options={allDealersLabel}
                    value={dealerLabel}
                    onChange={handleDealerChange}
                />
            </div>
            <div>
                Dealer Group:
                <Select 
                    options={dealerGroupLabels}
                    value={dealerGroup}
                    onChange={handleDealerGroupChange}
                />
            </div>
            <div>
                Territory: 
                <Select
                    options={territoryLabels}
                    value={territory}
                    onChange={handleTerritoryChange}
                />
            </div>
            <div className='modal-footer'>
                <button
                    onClick={() => handleFiltersModalClose()}
                    className='footer__cancel-button'
                >
                    <div className="vertical-align-div">
                        <span>Cancel</span>
                    </div>
                    
                </button>
                <button
                    onClick={() => onSubmit()}
                    className='footer__save-button'
                >
                    <div className='vertical-align-div'>
                        <span>Apply Filters</span>
                    </div>
                </button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        dealersFilters: state.dealersFilters
    };
};

export default connect(mapStateToProps)(DealerFiltersModal);