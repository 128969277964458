import api from './api';

const getPricingPosition = (id) => {
    return api
        .get('/pricing/' + id, { params: { includeProducts: true, includeAdditionalItems: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createPricingPosition = (pricingPayload) => {
    return api
        .post('/pricing/create', pricingPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updatePricingPosition = (pricingPositionPayload) => {
    return api
        .post('/pricing/update', pricingPositionPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const asyncUpdatePricingPosition = async (pricingPositionItemPayload) => {
    try {
        const res = await api.post('/pricing/update', pricingPositionItemPayload)
        return res
    } catch (e) {
        throw e.response.data
    }
};


const createPricingPositionItem = (pricingPositionItemPayload) => {
    return api
        .post('/pricing/items/create', pricingPositionItemPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const asyncCreatePricingPositionItem = async (pricingPositionItemPayload) => {
    try {
        const res = await api.post('/pricing/items/create', pricingPositionItemPayload)
        return res
    } catch (e) {
        throw e.response.data
    }
};

const deletePricingPositionItem = (pricingPositionItemPayload) => {
    return api
        .post('/pricing/items/delete', pricingPositionItemPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};


const PricingService = {
    getPricingPosition,
    createPricingPosition,
    updatePricingPosition,
    asyncUpdatePricingPosition,
    createPricingPositionItem,
    asyncCreatePricingPositionItem,
    deletePricingPositionItem
};



export default PricingService;