import React, { useState } from 'react';

// Components
import FooterMenuModal from './FooterMenuModal';
import FooterSettingsModal from './FooterSettingsModal';

// icons
import settings from '../images/settings.svg';
// import add from '../images/add.svg';
// import alert from '../images/alert.svg';
import menu from '../images/menu.svg';

function FooterMenu(props) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [settingsOpen, setSettingsOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(current => !current)
    }

    const toggleSettings = () => {
        setSettingsOpen(current => !current)
    }

    return (
        <React.Fragment>
            <div className='footer-menu'>
                <div className="vertical-align-div">
                    <img className="icon-small" onClick={toggleMenu} src={menu} alt="Menu"/>
                </div>
                <div className="vertical-align-div">
                    <img className="icon" onClick={toggleSettings} src={settings} alt="Settings"/>
                </div>
    {/*            <div className="vertical-align-div">
                    <img className="icon-small" src={alert} alt="Alert"/>
                </div>
                <div className="vertical-align-div">
                    <img className="icon-small" src={add} alt="Add"/>
                </div>*/}
                
            </div>
            <FooterMenuModal 
                isOpen={menuOpen}
                toggleOpen={toggleMenu}
            />
            <FooterSettingsModal
                isOpen={settingsOpen}
                toggleOpen={toggleSettings}
            />
        </React.Fragment>
    );
};

export default FooterMenu;