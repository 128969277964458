import React from 'react';
import { Navigate } from 'react-router-dom';
// services
import UserService from '../services/UserService';

const AdminRoute = ({ children }) => {
    function isAdmin() {
        const userRole = UserService.getUserRole();
        return userRole === 'SUPERADMIN' || userRole === 'METROTECH_ADMIN'
    };

    return isAdmin() ? (
        <React.Fragment>
            {children}
        </React.Fragment>
        
    ) : (
        <Navigate to='/contracts' />
    )
};

export default AdminRoute;