import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    ButtonGroup,
} from 'reactstrap';
import Select from 'react-select';

// services
import ProductTypeService from '../../services/ProductTypeService';

// components
import Notifications from '../Notifications';

function ProductForm(props) {

    const termOptions = React.useMemo(
        () => [
            { value: '1', label: '1 year' },
            { value: '2', label: '2 years' },
            { value: '3', label: '3 years' },
            { value: '4', label: '4 years' },
            { value: '5', label: '5 years' },
            { value: '6', label: '6 years' },
            { value: '7', label: '7 years' },
            { value: '8', label: '8 years' },
            { value: '9', label: '9 years' },
            { value: '10', label: '10 years' },
            { value: 'life', label: 'Life' },
        ], []
    )

    const readOnly = false;
    const disabledSelect = false;
    const [name, setName] = useState('');
    const [contractLetter, setContractLetter] = useState('');
    const [newUsed, setNewUsed] = useState([])
    // const [newChecked, setNewChecked] = useState(false);
    // const [usedChecked, setUsedChecked] = useState(false)
    const [minYear, setMinYear] = useState('-10');
    const [terms, setTerms] = useState([]);
    
    const [productType, setProductType] = useState(null);
    const [productTypeLabels, setProductTypeLabels] = useState([]);

    const [netSuiteId, setNetSuiteId] = useState('');

    const currentYear = new Date().getFullYear()
    const minYearInt = parseInt(minYear)

    // populate fields if editing existing product
    useEffect(() => {
        //  product type dropdown
        ProductTypeService.getProductTypes()
            .then(response => {
                let productTypeList = response.data.map((pt) => {
                    return {
                        value: pt.id,
                        label: pt.name
                    }
                })

                productTypeList.sort((a, b) => a['label'].localeCompare(b['label']))
                setProductTypeLabels(productTypeList)
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch product types list.')
            })

        if (props.product) {
            setName(props.product.name)
            setContractLetter(props.product.contractLetter)
            setNewUsed(props.product.condition)
            setMinYear(props.product.conditionCriteria)
            setTerms(props.product.terms.map((year) => termOptions.find((option) => option.value===year)))

            if (props.product.productType) {
                setProductType({
                    value: props.product.productType.id,
                    label: props.product.productType.name
                })
            } else {
                setProductType({})
            }

            setNetSuiteId(props.product.netSuiteId)
        }
    }, [props.product, termOptions])

    const handleProductTypeChange = (productTypeLabel) => {
        setProductType(productTypeLabel)
    }

    const onNewUsedClick = (val) => {
        const index = newUsed.indexOf(val);

        if (index < 0) {
            setNewUsed([
                ...newUsed,
                val
            ])
 
        } else {
            setNewUsed(newUsed.filter((i) => i !== val))
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

    
        if (name === "") {
            Notifications("error", "Name is required.")
        }
        if (contractLetter === "") {
            Notifications("error", "Contract letter is required.")
        }
        if (terms.length === 0) {
            Notifications("error", "Default available terms is required.")
        }
        if (newUsed.length === 0) {
            Notifications("error", "New/Used condition is required.")
        }
        if (isNaN(minYearInt)) {
            Notifications("error", "New/Used criteria is required.")
        }
        if (isNaN(Number(netSuiteId))) {
            Notifications('error', 'Please enter a numerical value for NetSuite ID.')
        }

        const product = {
            name,
            contractLetter,
            condition: newUsed,
            conditionCriteria: minYear,
            year: (currentYear+minYearInt).toString(),
            terms: terms.map(t => t.value),
            netSuiteId: netSuiteId ? parseInt(netSuiteId) : null
        };

        if (productType) product['productTypeId'] = productType.value

        props.onSubmit(product);
    };

    
    const handleTermsChange = (selected) => {
        selected.sort((a, b) => a['value'].localeCompare(b['value']))
        setTerms(selected)
    };

    return (
        <React.Fragment>
            <div className='details-page__form'>
                <div>
                    <h2>Product Data:</h2>
                </div>
                <form id='product-form' onSubmit={onSubmit}>
                    <Row>
                        <Col xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Name*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Name'
                                        autoFocus
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Contract Letter*
                                    </div>
                                    <input 
                                        type='text'
                                        placeholder='Enter Contract Letter'
                                        value={contractLetter}
                                        onChange={(e) => setContractLetter(e.target.value)}
                                        className='input input__form'
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>NetSuite ID</div>
                                    <input  
                                        type='text'
                                        placeholder='Enter NetSuite ID'
                                        value={netSuiteId}
                                        onChange={(e) => setNetSuiteId(e.target.value)}
                                        className='input input__form'
                                        disabled={readOnly}
                                    />
                                </label>
                            </div> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        New/Used*
                                    </div>
                                    <ButtonGroup>
                                        <Button 
                                            onClick={() => onNewUsedClick('0')}
                                            active={newUsed.includes('0')}
                                            className="form-button"
                                            id='newButton'
                                        >
                                            New
                                        </Button>
                                        <Button
                                            onClick={() => onNewUsedClick('1')}
                                            active={newUsed.includes('1')}
                                            className="form-button"
                                            id='usedButton'
                                        >
                                            Used
                                        </Button>
                                    </ButtonGroup>
                                </label>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        New/Used Criteria
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <span className='input__product-min-year'>
                                            <input 
                                                type='text'
                                                placeholder='-10'
                                                value={minYear}
                                                onChange={(e) => setMinYear(e.target.value)}
                                                className='input-transparent'
                                            />
                                        </span> 
                                        <div className='vertical-align-div'>
                                            <span>
                                                yrs. 
                                            </span>
                                        </div>
                                        <span className='input__product-calc-year'>
                                            {isNaN(currentYear+minYearInt) ? '-' : currentYear+minYearInt}  
                                        </span>
                                    </div>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>Product Type</div>
                                    <Select 
                                        options={productTypeLabels}
                                        value={productType}
                                        onChange={handleProductTypeChange}
                                        id='selectProductType'
                                        menuPlacement="top"
                                        isDisabled={readOnly==='readonly'}
                                        menuPortalTarget={document.body}
                                    />
                                </label>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className='details-page__form-elements'>
                                <label>
                                    <div>
                                        Default Available Terms*
                                    </div>
                                    <Select 
                                        isMulti
                                        options={termOptions}
                                        value={terms}
                                        onChange={handleTermsChange}
                                        id='selectTerms'
                                        isDisabled={disabledSelect}
                                        menuPlacement="top"
                                    />
                                </label>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>

        </React.Fragment>
    );
};

export default ProductForm;