import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// components
import Notifications from '../Notifications';
// calendar libraries
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// services
// other
import moment from 'moment';
import Select from 'react-select';
// icons
import greyX from '../../images/grey-x.svg';

function ProductFiltersModal({ 
    productsFilters, filtersModalOpen, handleFiltersModalClose, handleSubmitFilters, applyResetTable 
}) {

    const [initialRender, setInitialRender] = useState(true)
    const [nameFilter, setNameFilter] = useState(productsFilters.name)
    const [contractLetterFilter, setContractLetterFilter] = useState(productsFilters.contractLetter)


    useEffect(() => {
        setInitialRender(false)
    }, [])

    useEffect(() => {
        if (!initialRender) {
            setNameFilter('')
            setContractLetterFilter('')
        }
    }, [applyResetTable])

    const onSubmit = () => {
        const filters = {
            nameFilter: nameFilter,
            contractLetterFilter: contractLetterFilter
        }
        handleSubmitFilters(filters)
    }; 

    return (
        <Modal
            isOpen={filtersModalOpen}
            onRequestClose={handleFiltersModalClose}
            closeTimeoutMS={200}
            ariaHideApp={false}
            className='modal-contract__action'
        >
            <div className="modal-header">
                <h2>Filters</h2>
                <button className="close" onClick={handleFiltersModalClose}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <div>
                <span>Product Name:</span>
                <input 
                    type='text'
                    placeholder='Enter Product Name'
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className='input input__form'
                />
            </div>
            <div>
                Contract Letter: 
                <input 
                    type='text'
                    placeholder='Enter Contract Letter'
                    value={contractLetterFilter}
                    onChange={(e) => setContractLetterFilter(e.target.value)}
                    className='input input__form'
                />
            </div>
            <div className='modal-footer'>
                <button
                    onClick={() => handleFiltersModalClose()}
                    className='footer__cancel-button'
                >
                    <div className="vertical-align-div">
                        <span>Cancel</span>
                    </div>
                    
                </button>
                <button
                    onClick={() => onSubmit()}
                    className='footer__save-button'
                >
                    <div className='vertical-align-div'>
                        <span>Apply Filters</span>
                    </div>
                </button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        productsFilters: state.productsFilters
    };
};

export default connect(mapStateToProps)(ProductFiltersModal);