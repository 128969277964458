import api from './api';
import TokenService from './TokenService';

const getAdditionalItems = () => {
    return api
        .get('/additional-item-code')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createAdditionalItem = (additionalItemPayload) => {
    return api
        .post('/additional-item-code/create', additionalItemPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateAdditionalItem = (additionalItemPayload) => {
    return api
        .post('/additional-item-code/update', additionalItemPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const deleteAdditionalItem = (additionalItemPayload) => {
    return api
        .post('/additional-item-code/delete', additionalItemPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const AdditionalItemsService = {
    getAdditionalItems,
    createAdditionalItem,
    updateAdditionalItem,
    deleteAdditionalItem
};

export default AdditionalItemsService;
