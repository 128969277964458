import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    Button
} from 'reactstrap';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

// Components
import ProductForm from './ProductForm';
import Notifications from '../Notifications';

// services
import AuthService from '../../services/AuthService';
import ProductService from '../../services/ProductService';

// actions
import { set_header } from '../../actions/header';

function AddProductPage({ set_header }) {

    const navigate = useNavigate();

    const formValidation = (product) => {
        if (product['name'] === "") {
            return false;
        } else if (product['contractLetter'] === "") {
            return false;
        } else if (product['terms'].length === 0) {
            return false;
        } else if (product['condition'].length === 0) {
            return false;
        } else if (isNaN(product['year'])) {
            return false;
        } else if (isNaN(Number(product['netSuiteId']))) {
            return false
        }

        return true;
    }

    const onSubmit = (product) => {
        if (formValidation(product)) {
            ProductService.createProduct(
                product
            ).then(response => {
                Notifications("success", "Product has been created.")
                navigate('/products')
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
        }
    };

    useEffect(() => {
        set_header('Products', 'NEW')
    }, [set_header])

    return (
        <div className="content">
            <div className="content-row">
                <div className='details-page__header'>
                    <div className='vertical-align-div'>
                        <Link to='/products'>
                            <Button>
                                <img src={leftChevron} alt="Back"/>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="content-row flex-grow">
                <ProductForm 
                    onSubmit={onSubmit}
                />
            </div>
            <div className="content-row footer">
                <Link to='/products'>
                    <Button
                        className="footer__cancel-button"
                    >
                        <div className="vertical-align-div">
                            <span>Cancel</span>
                        </div>
                    </Button>
                </Link>
                <Button
                    className="footer__save-button"
                    form='product-form'
                    type='submit'
                >
                    <div className="vertical-align-div">
                        <span>Save Info</span>
                    </div>
                    <div className="vertical-align-div">
                        <img src={save} alt="Save"/>
                    </div>
                </Button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(AddProductPage);