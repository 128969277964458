import axios from 'axios';
import AuthService from './AuthService';
import TokenService from './TokenService';


const instance = axios.create({
    baseURL: process.env.REACT_APP_WMS_API,
    headers: {
        'Content-Type': 'application/json'
    }
})

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config;
    },
    (error) => {
        console.log('interceptor request error', error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        
        // console.log('interceptor error');
        console.log(err)
        // console.log(originalConfig)

        return Promise.reject(err);
    }
);

export default instance;