import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import moment from 'moment';

// Components
import Notifications from '../Notifications';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import LoadingPage from '../LoadingPage';

// services
import AuthService from '../../services/AuthService';
import DealerService from '../../services/DealerService';
import HistoryService from '../../services/HistoryService';

// images
import blueX from '../../images/blue-x.svg';
import plus from '../../images/plus.svg';
import history from '../../images/history-log.svg';

function ProductHistoryPage(props) {

    const { id } = useParams();
    const navigate = useNavigate();

    const [historyLog, setHistoryLog] = useState([])
    const [name, setName] = useState('')
    const [successful, setSuccessful] = useState(false)

    useEffect(() => {
        DealerService.getAllDealers()
            .then(response => {
                const dealers = response.data

                HistoryService.getHistory('product', id)
                    .then(response => {
                        let logEntries = []

                        response.data.reverse().forEach((entry) => {
                            let tempLogs = []

                            if (entry.action === 'productCreate') {
                                tempLogs.push('Product created')
                            } else if (entry.action === 'productUpdate') {
                                tempLogs.push('Product info updated')
                            } else if (entry.action === 'linkProductToDealers') {
                                const dealerId = JSON.parse(entry.description).dealerId

                                for (let i=0; i < dealers.length; i++) {
                                    if (dealers[i]['id'] === dealerId){
                                        tempLogs.push(`Assigned to dealer "${dealers[i]['name']}"`)
                                        break;
                                    }
                                }
                            } else if (entry.action === 'unlinkProductToDealers') {
                                const dealerId = JSON.parse(entry.description).dealerId

                                for (let i=0; i < dealers.length; i++) {
                                    if (dealers[i]['id'] === dealerId){
                                        tempLogs.push(`Removed from dealer "${dealers[i]['name']}"`)
                                        break;
                                    }
                                }
                            } 

                            tempLogs.forEach((log) => {
                                logEntries.push({
                                    title: log,
                                    author: entry.author.split(":")[0],
                                    date: moment(entry.createdAt).format('MM/DD/yyyy'),
                                    time: moment(entry.createdAt).format('LT')
                                })
                            })
                        })
                        
                        setHistoryLog(logEntries)
                        setSuccessful(true)

                    }, e => {
                        if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                            AuthService.logout()
                            navigate('/login')
                        }
                        setSuccessful(false)
                    })
            },
            error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                setSuccessful(false)
            });
    }, [])

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className="details-page__form" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    {
                        historyLog.map(log => (
                            <div className='history-record' key={`${log['title']}-${log['author']}-${log['date']}-${log['time']}`}>
                                <div className='history-details'>
                                    <button
                                        className='history-icon'
                                        disabled={true}
                                    >
                                        <img src={history} alt="history"/>
                                    </button>
                                    <div className='history-text'>
                                        <p className='history-text__title'>{log.title}</p>
                                        <p className='history-text__subtitles'>By: {log.author}</p>
                                        <div className='history-text__date'>
                                            <p>{log.date}</p>
                                            •
                                            <p>{log.time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="history-expand">
                                    {/*<button
                                        className='history-expand-button'
                                        disabled={true}
                                    >
                                        See Details >
                                    </button>*/}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductHistoryPage;