import api from './api';
import TokenService from './TokenService';

const getTerritory = () => {
    return api
        .get('/territory')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createTerritory = (territoryPayload) => {
    return api
        .post('/territory/create', territoryPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateTerritory = (territoryPayload) => {
    return api
        .post('/territory/update', territoryPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const deleteTerritory = (territoryPayload) => {
    return api
        .post('/territory/delete', territoryPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const TerritoryService = {
    getTerritory,
    createTerritory,
    updateTerritory,
    deleteTerritory
};

export default TerritoryService;
