import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import ForgotPasswordForm from './ForgotPasswordForm';

// assets
import wmsTitle from '../../images/wms-title.svg';

function ForgotPasswordPage() {
    const navigate = useNavigate();

    const onSubmit = () => {
        navigate('/login', { state: 'email' });
    };

    return (
        <React.Fragment>
            <div className='content-container'>
                <div className="login-content">
                    <div className="login__title">
                        <img src={wmsTitle} width='200rem' alt="Logo"/>
                    </div>
                    <h1>Forgot your password?</h1>
                    <p className='login__subtitle'>Enter your email address to change your password.</p>
                    <ForgotPasswordForm onSubmit={onSubmit}/>
                </div>
                <div className="login-background hide-for-mobile hide-for-tablet">
                    
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPasswordPage;