import api from './api';
import TokenService from './TokenService';

const updatePassword = (password) => {
    const passwordPayload = {
        password
    };
    return api
        .post('/users/update/password', passwordPayload)
        .then(response => {
            TokenService.removeLocalAccessToken();
            return response.data;
        })
        .catch(err => {
            throw err.response.data
        });
};

const getAllUsers = (email, role, page) => {
    return api
        .get('/users', { 
            params: { 
                email,
                role,
                page
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getUser = (id) => {
    return api
        .get('/users/' + id, { params: { includeDealers: true }})
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const getUserId = () => {
    return localStorage.getItem('userId')
}

const getUserRole = () => {
    return localStorage.getItem('role');
;}

const createUser = (userPayload) => {
    return api
        .post('/users/create', userPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateUser = (userPayload) => {
    return api
        .post('/users/update', userPayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const linkDealerToUser = (userPayload) => {
    return api
        .post('/users/linkDealers', userPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};

const unlinkDealerFromUser = (userPayload) => {
    return api
        .post('/users/unlinkDealers', userPayload)
        .then(response => {
            return response
        })
        .catch(err => {
            throw err.response.data
        });
};


const UserService = {
    updatePassword,
    getAllUsers,
    getUser,
    getUserId,
    getUserRole,
    createUser,
    updateUser,
    linkDealerToUser,
    unlinkDealerFromUser
};


export default UserService;
