import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import {
  Input
} from 'reactstrap';
import { 
  useTable, 
  useExpanded,
  usePagination,
  useRowSelect
} from 'react-table';
import Numeral from 'numeral';
import Select from 'react-select';

// images
import greyX from '../../images/grey-x.svg';
import gear from '../../images/gear.svg';
import trash from '../../images/trash.svg';

// services
import AuthService from '../../services/AuthService';
import ProductService from '../../services/ProductService';
import AdditionalItemsService from '../../services/AdditionalItemsService';

// components
import Notifications from '../Notifications';

function AddPricingPositionModal({ isOpen, toggleMenu, onAddSubmit, assignedProducts }) {

	const navigate = useNavigate();

    const [availableProducts, setAvailableProducts] = useState([]);
    const [availableTerms, setAvailableTerms] = useState({});
    const [term, setTerm] = useState('');
    const [product, setProduct] = useState('');
    const [productLabel, setProductLabel] = useState('');

    const [fee, setFee] = useState('');
    const [additionalItems, setAdditionalItems] = useState([]);

    const [additionalItemCode, setAdditionalItemCode] = useState(null);
    const [additionalItemCodeLabels, setAdditionalItemCodeLabels] = useState([])

    const [productCode, setProductCode] = useState('');
    const [price, setPrice] = useState('');
    const [dealerPrice, setDealerPrice] = useState('0');
    const [totalPrice, setTotalPrice] = useState('0')
;
    useEffect(() => {
        AdditionalItemsService.getAdditionalItems()
            .then(response => {
                setAdditionalItemCodeLabels(response.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.description} - ${item.code}`,
                        code: item.code,
                        description: item.description
                    }
                }).sort((a, b) => a['label'].localeCompare(b['label'])))
            }, e => {
                console.log(e)
                Notifications('error', 'Unable to fetch additional items.')
            })
    }, [])


    useEffect(() => {
        setAvailableProducts(assignedProducts.map((product) => {
            return {
                value: product.name, 
                label: product.name
            }
        }).sort((a, b) => a['label'].localeCompare(b['label'])))
    }, [assignedProducts])

    useEffect(() => {
        setAvailableTerms({})
        setTerm('')
        setProduct('')
        setProductLabel('')
        setAdditionalItemCode(null)
        setFee('')
        setAdditionalItems([])
        setDealerPrice('0')
        setTotalPrice('0')
    }, [isOpen])

    const handlePriceChange = (value) => {
        setPrice(value)
        const additionalItemsPrice = additionalItems.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue['fee'])
        }, 0)
        setTotalPrice(prev => {
            if (value === '') return parseFloat(additionalItemsPrice)
            
            const newTotalPrice = parseFloat(additionalItemsPrice) + parseFloat(value)
            return newTotalPrice.toString()
        })
    }

    const handleProductChange = (productLabel) => {
        setTerm('')
        setProductCode('')
        setPrice('')
        setAdditionalItems([])
        setDealerPrice('0')
        setTotalPrice('0')

        const id = assignedProducts.find(product => product.name === productLabel.value).id
        ProductService.getProduct(id)
            .then(response => {
                setProduct(response.data)
                setProductLabel({
                    value: response.data.name,
                    label: response.data.name
                })
                setAvailableTerms(response.data.terms.map((term) => ({
                    value: term,
                    label: term
                })))
            }, error => {
				if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
					AuthService.logout()
					navigate('/login')
				}
                console.log(error)
            })
    };

    const handleTermChange = (term) => {
        setTerm(term)
    };

    const handleAdditionalItemCodeChange = (e) => {
        setAdditionalItemCode(e)
    }

    const handleAddField = () => {
        if (additionalItemCode === {} || additionalItemCode === null) {
            Notifications("error", 'Additional Item code is required.')
        } else if (fee === '') {
            Notifications("error", 'Fee is required.')
        } else if (additionalItems.some(item => item['additionalItemCode'] === additionalItemCode['code'])) {
            Notifications("error", 'Cannot have duplicate item codes.')
        } else {
            setAdditionalItems(current => ([
                ...current,
                {
                    additionalItemCodeId: additionalItemCode['value'],
                    additionalItemCode: additionalItemCode['code'],
                    additionalItemDescription: additionalItemCode['description'],
                    fee: fee.replace('$',''),
                    edit: <button 
                        onClick={() => handleRemoveLink(additionalItemCode.value)}
                        className='button-no-background'
                    >
                        <img src={trash} alt="Delete"/>
                    </button>
                }
            ]))
            setTotalPrice(prev => {
                const newTotalPrice = parseFloat(prev) + parseFloat(fee)
                return newTotalPrice.toString()
            })
            setAdditionalItemCode(null)
            setFee('')
        }
    };

    const onSubmit = (e) => {
        e.preventDefault()

        if (Numeral(dealerPrice).format("$0,0.00") !== Numeral(totalPrice).format("$0,0.00")) {
            Notifications('error', 'Total price is not equal to dealer price.')
            return;
        }

        onAddSubmit({
            product: product,
            productCode: productCode,
            term: term,
            price: parseFloat(price.replace('$', '')),
            additionalItems: additionalItems
        })
    };

    const data = additionalItems.map(item => (
        {
            additionalItemCode: item.additionalItemCode,
            additionalItemDescription: item.additionalItemDescription,
            fee: item.fee,
            edit: <button 
                onClick={() => handleRemoveLink(item.additionalItemCode)}
                className='button-no-background'
              >
                <img src={trash} alt="Delete"/>
            </button>
        }
    ))

    const handleRemoveLink = (additionalItemCode) => {
        setAdditionalItems(current => current.filter(item => {
            return item['additionalItemCode'] !== additionalItemCode
        }))
        const selectedItem = additionalItems.find(item => item['additionalItemCode'] === additionalItemCode)
        setTotalPrice(prev => {
            const newTotalPrice = parseFloat(prev) - parseFloat(selectedItem['fee'])
            return newTotalPrice.toString()
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'additionalItemCode',
                id: 'additionalItemCode',
                name: 'additionalItemCode',
                Cell: ({ cell }) => {
                    const { value } = cell;
      
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Description',
                accessor: 'additionalItemDescription',
                id: 'additionalItemDescription',
                name: 'additionalItemDescription',
                Cell: ({ cell }) => {
                    const { value } = cell;
      
                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Fee',
                accessor: 'fee',
                id: 'fee',
                name: 'fee',
                Cell: ({ cell }) => {
                    const { value } = cell;
  
                    return (
                        <div className="table-cell__div">
                            {Numeral(value).format("$0,0.00")}
                        </div>
                    );
                }
            },
            {
                Header: <img src={gear} alt="Setting"/>,
                accessor: 'edit',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;
        
                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], []
    )
  
    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page
        } = useTable(
            {
                columns,
                data,
                initialState: { 
                    defaultPageSize: 1000,
                    pageSize: 1000,
                    sortBy: [
                        {
                            id: 'product',
                            desc: false
                        }
                    ]
                },
                sortTypes: {
                    alphanumeric: (row1, row2, columnName) => {
                        const rowOneColumn = row1.values[columnName];
                        const rowTwoColumn = row2.values[columnName];
                        if (isNaN(rowOneColumn)) {
                            return rowOneColumn.toUpperCase() >
                                rowTwoColumn.toUpperCase()
                                ? 1
                                : -1;
                        }
                        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                    },
                },
            },
            useExpanded,
            usePagination,
            useRowSelect,
        ) 

        // Render the UI for your table
        return (
            <React.Fragment>
                <div className="table-div">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <th className={`table-header ${column.canFilter && 'filterable'} flex-end`} {...column.getHeaderProps()}>
                                                    <div className="table-header__div">
                                                        {column.render('Header')}
                                                    </div>
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()} className="flex-end">{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleMenu}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className='modal-pricing-position'
        >   
            <div className='modal-body'>
                <div className='modal-header'>
                    <h2><b>Pricing Position</b></h2>
                    <button className='close' onClick={toggleMenu}>
                        <img src={greyX} alt="Close" />
                    </button>
                </div>
                <label style={{ width: '100%' }}>
                    <div>
                        Select Product
                    </div>
                    <Select
                        options={availableProducts}
                        value={productLabel}
                        onChange={handleProductChange}
                        id='selectProduct'
                    />
                </label>

                {
                    product && (
                        <div>
                            <hr />
                            <form id='add-form' className="pricing-position-form" onSubmit={onSubmit}>
                                <div className="form-section">
                                    <div>
                                        <div className="vertical-align-div">
                                            <span>
                                                Term:
                                            </span>
                                        </div>
                                    </div>
                                    <Select
                                        options={availableTerms}
                                        value={term}
                                        onChange={handleTermChange}
                                        className="input-select-container"
                                        id='selectTerm'
                                    />
                                </div>
                                <div className="form-section">
                                    <div>
                                        <div className="vertical-align-div">
                                            <span>
                                                Total Price:
                                            </span>
                                        </div>
                                    </div>
                                    <div className="vertical-align-div">
                                        <span>{Numeral(totalPrice).format("$0,0.00")}</span>
                                    </div>
                                </div>
                                <div className="form-section">
                                    <div>
                                        <div className="vertical-align-div">
                                            <span>
                                                Dealer Price:
                                            </span>
                                        </div>
                                    </div>
                                    <Input
                                        type='number'
                                        value={dealerPrice}
                                        className='input input__form'
                                        onChange={(e) => setDealerPrice(e.target.value)}
                                    />
                                    
                                </div>
                        </form>
                    </div>
                  )
                  
                }

                {
                    product && (
                        <div>
                            <hr />
                            <h2><b>Accounting Item</b></h2>
                            <div className="group">
                                <div className="label-group">
                                    <div className="vertical-align-div">
                                        <span>Item Code:</span>
                                    </div>
                                    <input
                                        type='text'
                                        placeholder='Item Code'
                                        value={productCode}
                                        className='input input__form'
                                        onChange={(e) => setProductCode(e.target.value)}
                                    />
                                </div>
                                <div className="label-group">
                                    <div className="vertical-align-div">
                                        <span>Price:</span>
                                    </div>
                                    <Input
                                        type='number'
                                        value={price}
                                        className='input input__form'
                                        onChange={(e) => handlePriceChange(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr/>
                        </div>
                    )
                }
                {
                    product  && (
                        <React.Fragment>
                            <h2><b>Additional Items</b></h2>
                            <form id='add-additional-items' className="additional-items-form" onSubmit={(e) => e.preventDefault()}>
                                <div className="group">
                                    <div className="vertical-align-div">
                                        <span>Item Code</span>
                                    </div>
                                    <Select 
                                        options={additionalItemCodeLabels}
                                        value={additionalItemCode} 
                                        onChange={handleAdditionalItemCodeChange}
                                        className="input-select-container"
                                    />
                                </div>
                                <div className="group">
                                    <div className="vertical-align-div">
                                        <span>Fee</span>
                                    </div>
                                    <Input 
                                        type='number'
                                        value={fee}
                                        className='input input__form'
                                        onChange={(e) => setFee(e.target.value)}
                                    />
                                </div>
                                <button
                                    form='add-additional-items'
                                    className='link__form-add'
                                    onClick={() => handleAddField()}
                                >
                                    <span>Add</span>
                                </button>
                            </form>
                            <div className="table mini overflow">
                                <Table columns={columns} data={data} />
                            </div>
                        
                        </React.Fragment>
                    )
                }
            </div>
            <div className="modal-footer">
                {
                    (dealerPrice !== 0 && dealerPrice !== '' && Numeral(dealerPrice).format("$0,0.00") !== Numeral(totalPrice).format("$0,0.00")) ? 
                        <React.Fragment>
                            <span style={{color: 'red'}}>
                                Total price is not equal to dealer price.
                            </span>
                            <div>
                                <button
                                    className='link__form-add disabled'
                                    disabled={true}
                                >
                                    Submit
                                </button>
                            </div>
                        </React.Fragment>
                        :
                        <div>
                            <button
                                form='add-form'
                                className='link__form-add'
                                readOnly={true}
                            >
                                Submit
                            </button>
                        </div>
                }
            </div>
        </Modal>
    );
};

export default AddPricingPositionModal;