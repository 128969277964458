import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button
} from 'reactstrap';

// services
import AuthService from '../../services/AuthService';
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';

// actions
import { set_header } from '../../actions/header';

// components
import Notifications from '../Notifications';
import ProductHistoryPage from './ProductHistoryPage';
import ProductForm from './ProductForm';
import LoadingPage from '../LoadingPage';

// images
import save from '../../images/save.svg';
import leftChevron from '../../images/left-chevron.svg';

function ProductInfoPage({ set_header }) {
    const navigate = useNavigate();

    const { id } = useParams();
    const [successful, setSuccessful] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const userRole = UserService.getUserRole();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab'))

    useEffect(() => {

        if (tab === null) {
            setTab('info')
            navigate(`/products/${id}?tab=info`, { replace: true })
        }


        ProductService.getProduct(id).then(response => {
            if (response.data === null) {
                navigate('/products')
                return
            }
            setSelectedProduct(response.data)
            setSuccessful(true)
        }, e => {
            if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                AuthService.logout()
                navigate('/login')
            }
            setSuccessful(false)
        })
    }, [id, navigate])

    useEffect(() => {
        set_header('Products', selectedProduct.name)
    }, [selectedProduct, set_header])

    const formValidation = (product) => {
        if (product['name'] === "") {
            return false;
        } else if (product['contractLetter'] === "") {
            return false;
        } else if (product['terms'].length === 0) {
            return false;
        } else if (product['condition'].length === 0) {
            return false;
        } else if (isNaN(product['year'])) {
            return false;
        } else if (isNaN(Number(product['netSuiteId']))) {
            return false;
        }

        return true;
    }

    const handleTabSwitch = (nextTab) => {
        setTab(nextTab)
        navigate(`/products/${id}?tab=${nextTab}`)
    };
    
    const onSubmit = (updatedProduct) => {
        if (formValidation(updatedProduct)) {
            ProductService.updateProduct({
                ...updatedProduct,
                id: selectedProduct.id
            }).then(response => {
                setSelectedProduct(response.data)
                Notifications("success", "Product has been updated.")
            }).catch(err => {
                if (err.statusCode === 401 && err.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", err['message'])
            })
        }
    };

    if (!successful) {
        return (
            <React.Fragment>
                <LoadingPage />
            </React.Fragment>
        );
    };


    return (
        <React.Fragment>
            <div className='content'>
                <div className="content-row">
                    <div className='details-page__header'>
                        <div className='vertical-align-div'>
                            <Link to='/products'>
                                <Button>
                                    <img src={leftChevron} alt="Back"/>
                                </Button>
                            </Link>
                        </div>
                        <div className='details-page__menu'>
                            <button 
                                className={tab === 'info' ? 'filter-buttons active' : 'filter-buttons'}
                                onClick={() => handleTabSwitch('info')}
                            >
                                Info
                            </button>
                            {
                                userRole === 'SUPERADMIN' &&
                                <button 
                                    className={tab === 'history' ? 'filter-buttons active' : 'filter-buttons'}
                                    onClick={() => handleTabSwitch('history')}
                                >
                                    History
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {
                    tab === 'info' &&
                        <React.Fragment>
                            <div className='content-row flex-grow'>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <ProductForm
                                            product={selectedProduct}
                                            onSubmit={onSubmit}
                                    />
                                    </Col>
                                </Row>
                            </div>
                            <div className="content-row footer">
                                <Link to='/products'>
                                    <Button
                                        className="footer__cancel-button"
                                    >
                                        <div className="vertical-align-div">
                                            <span>Cancel</span>
                                        </div>
                                    </Button>
                                </Link>

                                <Button
                                    className="footer__save-button"
                                    form='product-form'
                                    type='submit'
                                >
                                    <div className="vertical-align-div">
                                        <span>Save Info</span>
                                    </div>
                                    <div className="vertical-align-div">
                                        <img src={save} alt="Save"/>
                                    </div>
                                </Button>
                            </div>
                        </React.Fragment>
                }
                {
                    tab === 'history' &&
                        <ProductHistoryPage />
                }
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(ProductInfoPage);