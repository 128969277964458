import React, { useState, useEffect } from 'react';
import {
    Button
} from 'reactstrap';

// components
import Select from 'react-select';
import Notifications from '../../Notifications';
import ContractEditor from './ContractEditor';
import LoadingPage from '../../LoadingPage';

// services
import ContractService from '../../../services/ContractService';

function ContractTemplateManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const [contractTemplate, setContractTemplate] = useState(null)
    const [contractTemplateLabels, setContractTemplateLabels] = useState([]);
    const [selectedContractTemplate, setSelectedContractTemplate] = useState(null);
    const [html, setHtml] = useState(null)

    // fetch labels
    useEffect(() => {
        ContractService.getAllContractTemplates()
            .then(response => {
                setContractTemplateLabels(response.data.map(ct => {

                    return {
                        value: ct,
                        label: ct.state == null ? ct.name : ct.name + ' (' + ct.state + ')'
                    }

                }).sort((a, b) => a['label'].localeCompare(b['label'])))
            }, e => {
                Notifications('error', 'Unable to fetch contract templates.')
            })

    }, [])

    const handleContractTemplateChange = (ct) => {
        setContractTemplate(ct)
        setIsLoading(true)

        ContractService.getContractTemplate(ct.value.id)
            .then(response => {
                let latestVersion = response.data.version[0]

                setSelectedContractTemplate({
                    id: response.data.id,
                    name: response.data.name,
                    state: response.data.state,
                    htmlContent: latestVersion.htmlContent
                })

                setHtml(latestVersion.htmlContent)
                setIsLoading(false)

            }, e => {
                Notifications('error', 'Unable to fetch contract template for this product.')
            })

    };


    const onRevertClick = () => {
        if (contractTemplate !== null) {
            setHtml(selectedContractTemplate.htmlContent)
            Notifications('success', 'Contract template changes have been reverted.')
        }
        
    }

    const handleUpdateContractTemplate = () => {

        if (contractTemplate !== null) {
            const updatedContractTemplate = {
                id: selectedContractTemplate.id,
                name: selectedContractTemplate.name,
                state: selectedContractTemplate.state,
                htmlContent: html
            };

            ContractService.updateContractTemplate(updatedContractTemplate)
                .then(response => {
                    setSelectedContractTemplate({
                        id: response.data.id,
                        name: response.data.name,
                        state: response.data.state,
                        htmlContent: html
                    })
                    Notifications('success', 'Contract template has been updated.')
                }, e => {
                    Notifications('error', 'Unable to update contract template details.')
                }) 
        };
    };


    return (
        <React.Fragment>
            <div className="content-row flex-grow">
                <div className='contract-templates'>
                    <Select
                        options={contractTemplateLabels}
                        value={contractTemplate}
                        onChange={handleContractTemplateChange}
                        placeholder={'Select Contract Template'}
                    />
                    {
                        isLoading ?
                            <LoadingPage />
                            :
                            <ContractEditor
                                html={html}
                                setHtml={setHtml}
                            />
                    }
                </div>
            </div>
            <div className="content-row footer">
                <Button
                    className="footer__cancel-button"
                    onClick={onRevertClick}
                >
                    <div className="vertical-align-div">
                        <span>Revert</span>
                    </div>
                </Button>
                <Button
                    className="footer__save-button"
                    onClick={() => handleUpdateContractTemplate()}
                >
                    <div className="vertical-align-div">
                        <span>Save Changes</span>
                    </div>
                </Button>
            </div>  
        </React.Fragment>
    );
};

export default ContractTemplateManagement;


