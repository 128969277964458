import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Button
} from 'reactstrap';

// logos
import dealersLight from '../images/dealers-light.svg';
import dealersDark from '../images/dealers.svg';
import usersLight from '../images/users-light.svg';
import usersDark from '../images/users.svg';
import contractsLight from '../images/contracts-light.svg';
import contractsDark from '../images/contracts.svg';
import productsLight from '../images/products-light.svg';
import productsDark from '../images/products.svg';
import settingsLight from '../images/settings-light.svg';
import settingsDark from '../images/settings.svg';

Modal.defaultStyles.overlay.backgroundColor = '#6c757dc4';

function FooterMenuModal({ isOpen, toggleOpen }) {

    const pageTitleMapping = {
        'dashboard': 'Dashboard',
        'dealers': 'Dealers',
        'adddealer': 'Dealers',
        'users': 'Users',
        'adduser': 'Users',
        'contracts': 'Contracts',
        'addcontract': 'Contracts',
        'products': 'Products',
        'addproduct': 'Products',
        'settings': 'Settings'
    }

    const currentPage = pageTitleMapping[useLocation().pathname.split('/')[1]]

    return (
        <Modal
            isOpen={isOpen}
            className='footer-menu-modal'
            onRequestClose={toggleOpen}
            ariaHideApp={false}
        >   
            <div className="footer-menu-modal__section">
                <div className='footer-menu-modal__buttons'>
                    <Link to='/dealers' style={{ textDecoration: 'none' }} onClick={toggleOpen}>
                        <Button
                            className={currentPage === 'Dealers' ? 'active' : ''}
                        >
                            <div className="vertical-align-div">
                                <img src={currentPage === 'Dealers' ? dealersDark : dealersLight} alt="Dealers"/>
                            </div>
                            <div className="vertical-align-div">
                                <span>Dealers</span>
                            </div>
                        </Button>
                    </Link>
                </div>
                <div className='footer-menu-modal__buttons'>
                    <Link to='/users' style={{ textDecoration: 'none' }} onClick={toggleOpen}>
                        <Button
                            className={currentPage === 'Users' ? 'active' : ''}
                        >
                            <div className="vertical-align-div">
                                <img src={currentPage === 'Users' ? usersDark : usersLight} alt="Users"/>
                            </div>
                            <div className="vertical-align-div">
                                <span>Users</span>
                            </div>
                        </Button>
                    </Link>
                </div>
                <div className='footer-menu-modal__buttons'>
                    <Link to='/products' style={{ textDecoration: 'none' }} onClick={toggleOpen}>
                        <Button
                            className={currentPage === 'Products' ? 'active' : ''}
                        >
                            <div className="vertical-align-div">
                                <img src={currentPage === 'Products' ? productsDark : productsLight} alt="Products"/>
                            </div>
                            <div className="vertical-align-div">
                                <span>Products</span>
                            </div>
                        </Button>
                    </Link>
                </div>
                <div className='footer-menu-modal__buttons'>
                    <Link to='/contracts' style={{ textDecoration: 'none' }} onClick={toggleOpen}>
                        <Button
                            className={currentPage === 'Contracts' ? 'active' : ''}
                        >
                            <div className="vertical-align-div">
                                <img src={currentPage === 'Contracts' ? contractsDark : contractsLight} alt="Contracts"/>
                            </div>
                            <div className="vertical-align-div">
                                <span>Contracts</span>
                            </div>
                        </Button>
                    </Link>
                </div>
            </div>
            <hr />
            <div className="footer-menu-modal__section">
                <div className='footer-menu-modal__buttons'>
                    <Link to='/settings?tab=info' style={{ textDecoration: 'none' }} onClick={toggleOpen}>
                        <Button
                            className={currentPage === 'Settings' ? 'active' : ''}
                        >
                            <div className="vertical-align-div">
                                <img src={currentPage === 'Settings' ? settingsDark : settingsLight} alt="Settings"/>
                            </div>
                            <div className="vertical-align-div">
                                <span>Settings</span>
                            </div>
                        </Button>
                    </Link>
                </div>
            </div>
        </Modal>
    );
};

export default FooterMenuModal;