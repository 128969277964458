
const getLocalRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

const getLocalAccessToken = () => {
    return localStorage.getItem('accessToken');
};

const updateLocalAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
};

const removeLocalAccessToken = () => {
    localStorage.removeItem('accessToken');
};

const removeLocalRefreshToken = () => {
    localStorage.removeItem('refreshToken');
};

const setLocalAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
};
const setLocalRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    removeLocalAccessToken,
    removeLocalRefreshToken,
    setLocalAccessToken,
    setLocalRefreshToken
};

export default TokenService;