import api from './api';

const getProductTypes = () => {
    return api
        .get('/product-type')
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const createProductType = (productTypePayload) => {
    return api
        .post('/product-type/create', productTypePayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const updateProductType = (productTypePayload) => {
    return api
        .post('/product-type/update', productTypePayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const deleteProductType = (productTypePayload) => {
    return api
        .post('/product-type/delete', productTypePayload)
        .then(response => {
            return response.data
        })
        .catch(err => {
            throw err.response.data
        });
};

const ProductTypeService = {
    getProductTypes,
    createProductType,
    updateProductType,
    deleteProductType
};

export default ProductTypeService;
