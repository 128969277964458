import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// components
import LoadingPage from '../LoadingPage';
import Notifications from '../Notifications';
// table
import { useTable, useSortBy } from "react-table";
// services
import ProductService from '../../services/ProductService';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
// icons
import sort from '../../images/sort.svg';
import ascending from '../../images/ascending.svg';
import descending from '../../images/descending.svg';
import gear from '../../images/gear.svg';
import edit from '../../images/edit.svg';



function ProductsTable({ 
    manualPagination = false, currentPage, handlePageData, handleCSVData, filters, applyResetTable}) {

    const [initialRender, setInitialRender] = useState(true)

    const navigate = useNavigate();
    const [pageData, setPageData] = useState({
        rowData: [],
        loading: false, 
        totalPages: 0,
        totalProducts: 0
    })

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false)
        }
        setPageData((prev) => ({
            ...prev,
            rowData: [],
            loading: true
        }))
        ProductService.getAllProducts(
            currentPage,
            filters.nameFilter === '' ? null : filters.nameFilter,
            filters.contractLetterFilter === '' ? null : filters.contractLetterFilter
            )
            .then(response => {
                filterProducts(response)

                ProductService.getAllProducts(
                    null,
                    filters.nameFilter === '' ? null : filters.nameFilter,
                    filters.contractLetterFilter === '' ? null : filters.contractLetterFilter
                    )
                    .then(r => {
                        handleCSVData(r.data)
                    }, e => {
                        Notifications("error", e['message'])
                    })

            }, error => {
                if (error.statusCode === 401 && error.message === 'Token is expired, please update your token.') {
                    AuthService.logout()
                    navigate('/login')
                }
                Notifications("error", error['message'])
            });
    }, [currentPage, filters])

    // filter table on reset button
    useEffect(() => {

        if (!initialRender) {
            setPageData(prev => ({
                ...prev,
                loading: true
            }));
            
            ProductService.getAllProducts(1)
                .then(response => {
                    filterProducts(response)
                }, e => {
                    if (e.statusCode === 401 && e.message === 'Token is expired, please update your token.') {
                        AuthService.logout()
                        navigate('/login')
                    }
                    Notifications("error", e['message'])
                })
        }
        
    }, [applyResetTable])


    const filterProducts = (response) => {
        const { data, meta } = response
        const tableData = data.map(product => ({
            id: product.id,
            product: product.name,
            contract: product.contractLetter,
            used: product.condition.toString(),
            usedCriteria: product.year,
            productType: product.productType ? product.productType.name : '',
            terms: product.terms.toString(),
            edit: <Link to={`/products/${product.id}?tab=info`}><img src={edit} alt="Edit"/></Link>,
            selected: false
        }))

        const productsData = {
            rowData: tableData,
            loading: false,
            totalPages: meta.totalPages,
            totalProducts: meta.totalItems
        }
        setPageData(productsData)
        handlePageData(productsData)
    };


    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'product',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Contract Letter(s)',
                accessor: 'contract',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'New/Used',
                accessor: 'used',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let tempValue = value.split(',')

                    return (
                        <div className="table-cell__div">
                            {
                                tempValue.includes('0') && 
                                    <div className="badge badge__new">
                                        New
                                    </div>
                            }
                            {
                                tempValue.includes('1') && 
                                    <div className="badge badge__used">
                                        Used
                                    </div>
                            }
                        </div>
                    );
                }
            },
            {
                Header: 'New/Used Criteria',
                accessor: 'usedCriteria',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Default Available Terms',
                accessor: 'terms',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    if (value !== '') {
                        let tempValue = value.split(',')
                        tempValue = tempValue.map(v => isNaN(v) ? v.charAt(0).toUpperCase() + v.slice(1) : `${v} year${parseInt(v) > 1 ? 's' : ''}`)
                        tempValue.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

                        return (
                            <div className="table-cell__div">
                                {
                                    tempValue.map(v => {
                                        return (
                                            <div key={v} className="badge badge__terms">
                                                {v}
                                            </div>
                                        )
                                    })
                                }
                          </div>
                        );
                    }
                }
            },
            {
                Header: <img src={gear} alt="Settings"/>,
                accessor: 'edit',
                disableSortBy: true,
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="table-cell__div flex-center">
                            {value}
                        </div>
                    );
                }
            }
        ], []
    )

    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
                manualPagination,
                initialState: {
                    sortBy: [
                        {
                            id: 'activationDate',
                            desc: true
                        }
                    ]
                }
            },
            useSortBy
        );

        const generateSortingIndicator = (column) => {
            if (column.canSort) {
                return column.isSorted ? 
                    (
                        column.isSortedDesc ? 
                            <img {...column.getSortByToggleProps()} src={descending} className="table-header__icon-img" alt="Descending"/> 
                            : 
                            <img {...column.getSortByToggleProps()} src={ascending} className="table-header__icon-img" alt="Ascending"/>
                    ) 
                    : 
                    <img {...column.getSortByToggleProps()} src={sort} className="table-header__icon-img" alt="Sort"/>;
            } else {
                return null
            }
        };

        const generateVerticalLine = (column) => {
            if (column.canSort) {
                return <div className="table-header__vertical-line"></div>
            } else {
                return null
            }
        }

        

        return (
            <React.Fragment>
                <div className='table-div'>
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column) => (
                                                <th className='table-header'{...column.getHeaderProps()}>
                                                    <div className='table-header__div'>
                                                        {column.render("Header")}
                                                        <div className='table-header__icons'>
                                                            <div>
                                                                {generateVerticalLine(column)}
                                                                {generateSortingIndicator(column)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </th>

                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="table-row">
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className="flex-end">{cell.render("Cell")}</td>;
                                        })}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>  
            </React.Fragment>
        )
    }





    return (
        <React.Fragment>
            {
                pageData.loading ? (
                    <LoadingPage />
                ) : (
                    <div className='table'>
                        <Table columns={columns} data={pageData.rowData}/>
                        {
                            manualPagination && (
                                <div>
                                    {}
                                </div>
                            )
                        }
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default ProductsTable;