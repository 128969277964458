const headerReducerDefaultState = {
    main: null,
    sub: null
}

export default (state=headerReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_HEADER':
            return {
                main: action.main,
                sub: action.sub
            }
        default:
            return state;
    };
};