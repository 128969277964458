
export const set_dealer = (dealer = {}) => ({
    type: 'SET_DEALER',
    dealer
});

export const set_dealer_group = (dealerGroup = null) => ({
    type: 'SET_DEALER_GROUP',
    dealerGroup
});

export const set_dealer_territory = (dealerTerritory = null) => ({
    type: 'SET_DEALER_TERRITORY',
    dealerTerritory
});

export const set_dealer_page = (page = 1) => ({
    type: 'SET_DEALER_PAGE',
    page
});