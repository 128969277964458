import React from 'react';
import { Navigate } from 'react-router-dom';

// Components
import AuthService from '../services/AuthService';

const PublicRoute = ({ children, logout }) => {
    if (logout === true) {
        AuthService.logout();
    }

    function hasJWT() {
        let isAuthenticated = false
        localStorage.getItem('accessToken') ? isAuthenticated=true : isAuthenticated=false
        return isAuthenticated
    };

    return hasJWT() ? (
        <Navigate to='/dealers' />
    ) : (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
};

export default PublicRoute;