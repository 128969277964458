import { configureStore } from '@reduxjs/toolkit';
// Reducers
import contractsFiltersReducer from '../reducers/contractsFilters';
import dealersFiltersReducer from '../reducers/dealersFilters';
import productsFiltersReducer from '../reducers/productsFilters';
import usersFiltersReducer from '../reducers/usersFilters';
import headerReducer from '../reducers/header';

const store = configureStore({
    reducer: {
        header: headerReducer,
        contractsFilters: contractsFiltersReducer,
        dealersFilters: dealersFiltersReducer,
        productsFilters: productsFiltersReducer,
        usersFilters: usersFiltersReducer
    }
});


export default store