import api from './api';
import TokenService from './TokenService';
import UserService from './UserService';
import jwt_decode from 'jwt-decode';


const login = (email, password) => {
    const loginPayload = {
        email,
        password
    };
    return api
        .post('/auth/login', loginPayload)
        .then(response => {
            if (response.data) {
                TokenService.setLocalAccessToken(response.data.data.accessToken);
                TokenService.setLocalRefreshToken(response.data.data.refreshToken);
            }
            return response.data
        });
};

const logout = () => {
    TokenService.removeLocalAccessToken();
    TokenService.removeLocalRefreshToken();
    localStorage.removeItem('role');
};

const reset = (email) => {
    const params = {
        email
    };
    return api
        .get('/auth/reset', { params })
        .then(response => {
            return response.data
        });
};

const AuthService = {
    login,
    logout,
    reset
};

export default AuthService;

