import React from 'react';
import Select from 'react-select';

export const Filter = ({ column, hidden }) => {
  	return (
		<div style={{ display: hidden ? 'none' : 'block', flexGrow: 1 }}>
	  		{column.canFilter && column.render('Filter')}
		</div>
 	);
};

export const DefaultColumnFilter = ({
  	column: {
		filterValue,
		setFilter,
		preFilteredRows: { length },
  	},
}) => {
  	return null;
};

export const InputColumnFilter = ({
	column: { filterValue, setFilter, preFilteredRows, id, Header, ref },
}) => {
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
	  		options.add(row.values[id])
		});

		return [...options.values()].map(o => ({ value: o, label: o })).sort((a, b) => {
			if (!a['value']) {
				return -1
			}

			if (!b['value']) {
				return 1
			}

			return a['value'].localeCompare(b['value'])
		});
  	}, [id, preFilteredRows]);

	return (
		<Select
			ref={ref}
	        classNamePrefix="select"
	        onChange={(e) => {
				setFilter(e ? e.value : undefined);
		  	}}
		  	placeholder={`Select ${Header}...`}
	        defaultValue={""}
	        isDisabled={false}
	        isLoading={false}
	        isClearable={true}
	        isSearchable={true}
	        options={options}
	    />
	)
}