import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// components
import DealerGroupManagement from './DealerGroups/DealerGroupManagement';
import TerritoryManagement from './Territories/TerritoryManagement';
import ContractTemplateManagement from './ContractTemplate/ContractTemplateManagement';
import AdditionalItemsManagement from './AdditionalItems/AdditionalItemsManagement';
import ProductTypesManagement from './ProductTypes/ProductTypesManagement';

// actions
import { set_header } from '../../actions/header';

function SettingsPage({ set_header }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab'))
    const navigate = useNavigate();

    useEffect(() => {
        set_header('Settings', null);
    }, [set_header])

    const handleTabSwitch = (nextTab) => {
        setTab(nextTab)
        navigate(`/settings?tab=${nextTab}`)
    };

    return (
        <div className='content'>
            <div className="content-row">
                <div className='settings-page__header'>
                    <div className='settings-page__menu'>
                        <button 
                            className={tab==='dealer-groups' ? 'menu-buttons active' : 'menu-buttons'}
                            onClick={() => handleTabSwitch('dealer-groups')}
                        >
                            Dealer Groups
                        </button>
                        <button 
                            className={tab=='territories' ? 'menu-buttons active' : 'menu-buttons'}
                            onClick={() => handleTabSwitch('territories')}
                        >
                            Territories
                        </button>
                        <button 
                            className={tab==='product-types' ? 'menu-buttons active' : 'menu-buttons'}
                            onClick={() => handleTabSwitch('product-types')}
                        >
                            Product Types
                        </button>
                        <button 
                            className={tab==='additional-items' ? 'menu-buttons active' : 'menu-buttons'}
                            onClick={() => handleTabSwitch('additional-items')}
                        >
                            Additional Items
                        </button>
                         <button 
                            className={tab==='contract-templates' ? 'menu-buttons active' : 'menu-buttons'}
                            onClick={() => handleTabSwitch('contract-templates')}
                        >
                            Contract Templates
                        </button>
                    </div>
                </div>
            </div>
            {
                tab === 'dealer-groups' &&
                    <DealerGroupManagement />
            }
            {
                tab === 'territories' &&
                    <TerritoryManagement />
            }
            {
                tab === 'product-types' &&
                    <ProductTypesManagement />
            }
            {
                tab === 'additional-items' &&
                    <AdditionalItemsManagement />
            }
            {
                tab === 'contract-templates' &&
                    <ContractTemplateManagement />
            }
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_header: (main, sub) => dispatch(set_header(main, sub))
   };
};

export default connect(null, mapDispatchToProps)(SettingsPage);